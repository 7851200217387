import http from './httpService';

export const getProductsQuery = () => {
  return http.get('/pages/products');
};

export const addProductMutation = (values) => {
  const formData = new FormData();

  Object.entries(values).forEach(([key, value]) => {
    if (value) {
      formData.append(key, value);
    }
  });

  return http.post('/pages/products', formData);
};

export const editProductMutation = (values, id) => {
  const formData = new FormData();

  Object.entries(values).forEach(([key, value]) => {
    if (value) {
      formData.append(key, value);
    }
  });

  return http.put(`/pages/product/${id}`, formData);
};

export const deleteProductMutation = (id) => {
  return http.delete(`/pages/product/${id}`);
};

export const fetchProduct = (id) => {
  return http.get(`/pages/product/${id}`);
};

export const updateProductRankMutation = (values) => {
  return http.put('/pages/product/update/rank', values);
};

import {
  GET_CLIENT_CARDS,
  GET_CLIENT_CARD,
  DELETE_CLIENT_CARD,
  CLEAR_CLIENT_CARDS,
  CLEAR_CLIENT_CARD,
  RESET_CLIENT_FORM,
} from '../actions/clientCardActions';

const initialState = {
  clientCardsData: [],
  fetched: {},
  reset: false,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_CLIENT_CARDS:
      return {
        ...state,
        clientCardsData: payload,
      };
    case GET_CLIENT_CARD:
      return {
        ...state,
        fetched: payload,
      };
    case DELETE_CLIENT_CARD:
      return {
        ...state,
        clientCardsData: state.clientCardsData.filter((item) => item._id !== payload.id),
      };
    case CLEAR_CLIENT_CARDS:
      return {
        ...state,
        clientCardsData: [],
      };
    case CLEAR_CLIENT_CARD:
      return {
        ...state,
        fetched: {},
      };
    case RESET_CLIENT_FORM:
      return {
        ...state,
        reset: !state.reset,
      };
    default:
      return state;
  }
}

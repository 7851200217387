import React from 'react';
import { Col, Container, Row, Button } from 'reactstrap';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import FormsContent from './FormsContent';
import FormDetail from './FormDetail';

const Contact = ({ history }) => (
  <Container className="dashboard">
    <Row>
      <Col xs={10}>
        <h3 className="page-title">Contact Forms</h3>
      </Col>
      <Col xs={2} className="d-flex justify-content-end">
        <div>
          <Button onClick={history.goBack} color="primary" className="btn btn-xs">
            Back
          </Button>
        </div>
      </Col>
    </Row>
    <Row>
      <Switch>
        <Route path="/pages/contactForms/:id/detail" component={FormDetail} />
        <Route path="/pages/contactForms" component={FormsContent} />
      </Switch>
    </Row>
  </Container>
);

Contact.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Contact;

import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { Field } from 'formik';

const MAX_FILE_SIZE = 210000;

class DropZoneField extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    setFieldValue: PropTypes.func,
    customHeight: PropTypes.bool,
    defaultImage: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        })
      ),
    ]).isRequired,
  };

  static defaultProps = {
    customHeight: false,
    onChange: null,
    setFieldValue: null,
    defaultImage: '',
  };

  constructor() {
    super();
    this.state = { defaultImage: '' };
    this.onDrop = this.onDrop.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { defaultImage } = this.props;

    if (prevProps.defaultImage !== defaultImage && defaultImage) {
      this.setState({ defaultImage });
    }
  }

  onDrop(file) {
    const { onChange, setFieldValue, name } = this.props;

    if (setFieldValue) {
      setFieldValue(
        name,
        file.map((fl) =>
          Object.assign(fl, {
            preview: URL.createObjectURL(fl),
          })
        )
      );
    } else {
      onChange(
        file.map((fl) =>
          Object.assign(fl, {
            preview: URL.createObjectURL(fl),
          })
        )
      );
    }
  }

  removeFile(index, e) {
    const { onChange, setFieldValue, name, value } = this.props;
    e.preventDefault();
    if (setFieldValue) {
      setFieldValue(
        name,
        value.filter((_, i) => i !== index)
      );
    } else {
      onChange(value.filter((_, i) => i !== index));
    }
  }

  render() {
    const { value, customHeight, name } = this.props;
    const { defaultImage } = this.state;

    const files = value;

    return (
      <div
        className={`mb-2 dropzone dropzone--single${
          customHeight ? ' dropzone--custom-height' : ''
        }`}
      >
        <Dropzone
          accept="image/jpeg, image/png, image/jpg"
          name={name}
          multiple={false}
          onDrop={(fileToUpload) => {
            this.onDrop(fileToUpload);
          }}
          maxSize={MAX_FILE_SIZE}
        >
          {({ getRootProps, getInputProps, rejectedFiles }) => (
            <>
              <div {...getRootProps()} className="dropzone__input">
                {(!files || files.length === 0) && (
                  <div className="dropzone__drop-here">
                    <span className="lnr lnr-upload" /> Drop image here to
                    upload
                  </div>
                )}
                <input {...getInputProps()} />
              </div>
              <div className="dropzone__error">
                {rejectedFiles.length > 0 &&
                  rejectedFiles.map((file) => (
                    <span key={file.name} className="form__form-group-error">
                      {file.size > MAX_FILE_SIZE &&
                        `${file.name} is too large. It must be less than or equal to 200 KB`}
                    </span>
                  ))}
              </div>
            </>
          )}
        </Dropzone>
        {files && Array.isArray(files) && files.length > 0 ? (
          <aside className="dropzone__img">
            <img src={files[0].preview} alt="drop-img" />
            <p className="dropzone__img-name">{files[0].name}</p>
            <button
              className="dropzone__img-delete"
              type="button"
              onClick={(e) => this.removeFile(0, e)}
            >
              Remove
            </button>
          </aside>
        ) : (
          defaultImage && (
            <aside className="dropzone__img">
              <img src={defaultImage} alt="drop-img" />
              <button
                className="dropzone__img-delete"
                type="button"
                onClick={() => this.setState({ defaultImage: '' })}
              >
                Remove
              </button>
            </aside>
          )
        )}
      </div>
    );
  }
}

export const FormikDropZoneField = ({
  name,
  value,
  setFieldValue,
  customHeight,
  defaultImage,
}) => (
  <Field name={name}>
    {(props) => (
      <DropZoneField
        name={name}
        value={value}
        setFieldValue={setFieldValue}
        customHeight={customHeight}
        defaultImage={defaultImage}
        {...props}
      />
    )}
  </Field>
);

FormikDropZoneField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
  ]).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  customHeight: PropTypes.bool,
  defaultImage: PropTypes.string,
};

FormikDropZoneField.defaultProps = {
  customHeight: false,
  defaultImage: '',
};

const renderDropZoneField = (props) => {
  const { input, customHeight } = props;
  return <DropZoneField {...input} customHeight={customHeight} />;
};

renderDropZoneField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  customHeight: PropTypes.bool,
};

renderDropZoneField.defaultProps = {
  customHeight: false,
};

export default renderDropZoneField;

import {
  GET_CASE_STUDY_META_DATA,
  GET_CASE_STUDY_BANNER_DATA,
  GET_CASE_STUDIES,
  GET_CASE_STUDY,
  CLEAR_CASE_STUDY_DATA,
  DELETE_CASE_STUDY,
} from '../actions/caseStudyActions';

const initialState = {
  bannerSectionData: {},
  caseStudies: [],
  selectedCaseStudy: {},
  caseStudyMetaData: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CLEAR_CASE_STUDY_DATA:
      return {
        ...state,
        bannerSectionData: {},
        caseStudies: {},
        selectedCaseStudy: {},
        caseStudyMetaData: {},
      };
    case GET_CASE_STUDY_META_DATA:
      return {
        ...state,
        caseStudyMetaData: action.payload,
      };
    case GET_CASE_STUDY_BANNER_DATA:
      return {
        ...state,
        bannerSectionData: action.payload,
      };
    case GET_CASE_STUDIES:
      return {
        ...state,
        caseStudies: action.payload,
      };
    case GET_CASE_STUDY:
      return {
        ...state,
        selectedCaseStudy: action.payload,
      };
    case DELETE_CASE_STUDY:
      return {
        ...state,
        caseStudies: state.caseStudies.filter(
          (casestudy) => casestudy._id !== action.payload.id
        ),
      };
    default:
      return state;
  }
}

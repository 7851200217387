import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { Col, Container, Row, Button } from 'reactstrap';
import SectionCard from './components/SectionCard';
import CaseStudyForm from './components/CaseStudyForm';

const CaseStudy = ({ history }) => (
  <Container className="dashboard">
    <Row>
      <Col xs={10}>
        <h3 className="page-title">Case Studies Section</h3>
      </Col>

      <Col xs={2} className="d-flex justify-content-end">
        <div>
          <Button
            onClick={history.goBack}
            color="primary"
            className="btn btn-xs"
          >
            Back
          </Button>
        </div>
      </Col>
    </Row>

    <Row>
      <Switch>
        <Route
          path="/pages/case-study/all-case-studies/:id/edit"
          component={CaseStudyForm}
        />
        <Route
          path="/pages/case-study/all-case-studies/add"
          component={CaseStudyForm}
        />
        <Route
          path="/pages/case-study/all-case-studies"
          component={SectionCard}
        />
      </Switch>
    </Row>
  </Container>
);

CaseStudy.propTypes = {
  history: PropTypes.object.isRequired,
};

export default CaseStudy;

import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Table, Badge, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../../../../shared/components/Modal';
import {
  getHomeHappyClientsData,
  deleteHappyClient,
  clearHomeData,
} from '../../../../../../redux/actions/homeActions';
import { ROOT_URL } from '../../../../../../shared/helpers/constants';

const SectionCard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { happyClientsSectionData } = useSelector((state) => state.homePage);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(false);

  useEffect(() => {
    dispatch(getHomeHappyClientsData());
    return () => {
      dispatch(clearHomeData());
    }
  }, []);

  const toggleDeleteModal = () => setShowDeleteModal((prevState) => !prevState);

  const handleDelete = () => {
    dispatch(deleteHappyClient(selectedId));
  };

  return (
    <Col md={12}>
      <Modal
        showModal={showDeleteModal}
        toggle={toggleDeleteModal}
        color="warning"
        title="Attention!"
        message="Are you sure, you want to delete this card?"
        onSuccess={handleDelete}
      />
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between">
            <div className="card__title">
              <h5 className="bold-text">Happy Clients Content</h5>
              <h5 className="subhead">Edit Content</h5>
            </div>
            <div>
              <Button
                color="primary"
                className="btn btn-sm"
                onClick={() =>
                  history.push('/pages/home/happy-clients/add')
                }
              >
                + Add New
              </Button>
            </div>
          </div>
          <div>
            <Table responsive hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Image</th>
                  <th className="w-25">Name</th>
                  <th className="w-50">Comment</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {happyClientsSectionData.length > 0  && happyClientsSectionData.map((item,index) => (
                  <tr key={item._id}>
                    <td>{index+1}</td>
                    <td>
                      <img
                        src={ROOT_URL + item.clientImage.url}
                        alt="icon"
                        style={{ height: 70, width: 70 }}
                      />
                    </td>
                    <td className="w-25">{item.name}</td>
                    <td className="w-50">{item.comment}</td>
                    <td className="w-25 text-center">
                      <Badge
                        role="button"
                        color="secondary"
                        type="button"
                        className="mr-1"
                        onClick={() => {
                          history.push(`/pages/home/happy-clients/${item._id}/edit`)
                        }}
                      >
                        Edit
                      </Badge>
                      <Badge
                        role="button"
                        color="danger"
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedId(item._id);
                          toggleDeleteModal();
                        }}
                      >
                        Delete
                      </Badge>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SectionCard;

import React, { useEffect } from 'react';
import { Table } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  getContactForms,
  clearContactForms,
} from '../../../redux/actions/contactActions';

function ContactFormTable() {
  const dispatch = useDispatch();
  const { forms } = useSelector((state) => state.contactPage);

  useEffect(() => {
    dispatch(getContactForms());
    return () => {
      dispatch(clearContactForms());
    };
  }, []);

  return (
    <div>
      <Table responsive hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Company</th>
            <th className="w-25">Message</th>
          </tr>
        </thead>
        <tbody>
          {forms.map((form, index) => (
            <tr
              key={form._id}
            >
              <td>{index + 1}</td>
              <td>{form.name}</td>
              <td>{form.email}</td>
              <td>{form.phone}</td>
              <td>{form.company}</td>
              <td className="w-25">{`${form.message.slice(0, 70)}...`}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default ContactFormTable;

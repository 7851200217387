import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormikField } from '../../shared/components/form/Field';

function MetaDataForm({ page, data, handleSubmit }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.uiState);

  const initValues = {
    title: (data && data.title) || '',
    description: (data && data.description) || '',
    keywords: (data && data.keywords) || '',
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      validationSchema={Yup.object({
        title: Yup.string().required('Required!'),
        description: Yup.string().required('Required!'),
        keywords: Yup.string().required('Required!'),
      })}
      onSubmit={(values) => {
        const item = { ...values };
        item.slug = `/${page}`; // Page slug will be like '/home'
        dispatch(handleSubmit({ page, item }));
      }}
    >
      {({ values, touched, errors }) => (
        <Form className="form form--horizontal text-left">
          <div className="form__form-group">
            <span className="form__form-group-label col-lg-3 text-nowrap required">
              Title
            </span>
            <div className="form__form-group-field col-lg-5">
              <FormikField
                name="title"
                value={values.title}
                touched={touched.title}
                error={errors.title}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label col-lg-3 text-nowrap required">
              Description
            </span>
            <div className="form__form-group-field col-lg-5">
              <FormikField
                textArea
                name="description"
                value={values.description}
                touched={touched.description}
                error={errors.description}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label col-lg-3 text-nowrap required">
              Keywords
            </span>
            <div className="form__form-group-field col-lg-5">
              <FormikField
                name="keywords"
                value={values.keywords}
                touched={touched.keywords}
                error={errors.keywords}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label col-lg-3">
              <button
                className="btn btn-primary btn-sm"
                type="submit"
                disabled={isLoading}
              >
                Save
              </button>
            </span>
          </div>
        </Form>
      )}
    </Formik>
  );
}

MetaDataForm.propTypes = {
  page: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default MetaDataForm;

import React, { useEffect } from 'react';
import { Col, Container, Row, Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SectionCard from './components/SectionCard';
import { getInfoCardsAction } from '../../../../../redux/actions/homeActions';

function InfoCards() {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInfoCardsAction());
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <Col xs={10}>
          <h3 className="page-title">Info Cards Section</h3>
        </Col>
        <Col xs={2} className="d-flex justify-content-end">
          <div>
            <Button
              onClick={history.goBack}
              color="primary"
              className="btn btn-xs"
            >
              Back
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <SectionCard />
      </Row>
    </Container>
  );
}

export default InfoCards;

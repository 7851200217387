import React, { useEffect } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { FormikField } from '../../../../../../shared/components/form/Field';
import { FormikDropZoneField } from '../../../../../../shared/components/form/DropZone';
import {
  getSingleClientData,
  addHappyClient,
  updateHappyClient,
  clearHomeData,
} from '../../../../../../redux/actions/homeActions';
import { ROOT_URL } from '../../../../../../shared/helpers/constants';

const ClientForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { selectedClientData } = useSelector((state) => state.homePage);
  const { isLoading } = useSelector((state) => state.uiState);

  useEffect(() => {
    if (id) dispatch(getSingleClientData(id));
    return () => {
      dispatch(clearHomeData());
    };
  }, []);

  const initValues = {
    name: selectedClientData.name || '',
    comment: selectedClientData.comment || '',
    clientImage: '',
  };

  const defaultImages = {
    clientImage:
      selectedClientData.clientImage &&
      ROOT_URL + selectedClientData.clientImage.url,
  };

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Happy Clients Section Content</h5>
            <h5 className="subhead">Edit Content</h5>
          </div>
          <div>
            <Formik
              enableReinitialize
              initialValues={initValues}
              validationSchema={Yup.object({
                name: Yup.string().required('Required!'),
                comment: Yup.string()
                  .max(150, 'Comment cannot exceed the limit of 150 characters')
                  .required('Required!'),
              })}
              onSubmit={(values) => {
                if (id) dispatch(updateHappyClient({ id, values }));
                else dispatch(addHappyClient(values));
              }}
            >
              {({ setFieldValue, values, touched, errors }) => (
                <Form className="form form--horizontal text-left">
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Name
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        name="name"
                        value={values.name}
                        touched={touched.name}
                        error={errors.name}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Comment
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        textArea
                        name="comment"
                        value={values.comment}
                        touched={touched.comment}
                        error={errors.comment}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Client Image
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikDropZoneField
                        name="clientImage"
                        value={values.clientImage}
                        setFieldValue={setFieldValue}
                        defaultImage={defaultImages.clientImage}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3">
                      <button
                        className="btn btn-primary btn-sm"
                        type="submit"
                        disabled={isLoading}
                      >
                        Save Client
                      </button>
                    </span>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ClientForm;

import toastify from '../../shared/helpers/toastNotification';
import createAsyncAction from '../../shared/helpers/createAsyncAction';
import {
  getBannerData,
  saveBannerData,
  getCaseStudies,
  getCaseStudy,
  addCaseStudy,
  updateCaseStudy,
  deleteCaseStudyData,
  updateCaseStudyRankMutation,
} from '../../services/caseStudyPageServices';
import { getMetaData, saveMetaData } from '../../services/metaDataService';

export const CLEAR_CASE_STUDY_DATA = 'CLEAR_CASE_STUDY_DATA';

export const GET_CASE_STUDY_BANNER_DATA = 'GET_CASE_STUDY_BANNER_DATA';
export const GET_CASE_STUDIES = 'GET_CASE_STUDIES';
export const GET_CASE_STUDY = 'GET_CASE_STUDY';
export const GET_CASE_STUDY_META_DATA = 'GET_CASE_STUDY_META_DATA';

export const DELETE_CASE_STUDY = 'DELETE_CASE_STUDY';

export const clearCaseStudyData = () => ({ type: CLEAR_CASE_STUDY_DATA });

export const getCaseStudyMetaData = createAsyncAction(
  getMetaData,
  (res, dispatch) => {
    dispatch({ type: GET_CASE_STUDY_META_DATA, payload: res.data });
  }
);

export const saveCaseStudyMetaData = createAsyncAction(saveMetaData, (res) => {
  toastify(res.data.message, 'success');
});

export const getCaseStudyBannerData = createAsyncAction(
  getBannerData,
  (res, dispatch) => {
    dispatch({ type: GET_CASE_STUDY_BANNER_DATA, payload: res.data });
  }
);

export const saveBannerSectionData = createAsyncAction(
  saveBannerData,
  (res) => {
    toastify(res.data.message, 'success');
  }
);

export const getAllCaseStudies = createAsyncAction(
  getCaseStudies,
  (res, dispatch) => {
    dispatch({ type: GET_CASE_STUDIES, payload: res.data });
  }
);

export const getSingleCaseStudy = createAsyncAction(
  getCaseStudy,
  (res, dispatch) => {
    dispatch({ type: GET_CASE_STUDY, payload: res.data });
  }
);

export const addNewCaseStudy = createAsyncAction(addCaseStudy, (res) => {
  toastify(res.data.message, 'success');
});

export const updateCaseStudyData = createAsyncAction(updateCaseStudy, (res) => {
  toastify(res.data.message, 'success');
});

export const deleteCaseStudy = createAsyncAction(
  deleteCaseStudyData,
  (res, dispatch) => {
    dispatch({ type: DELETE_CASE_STUDY, payload: res.data });
    toastify('Case Study Deleted Successfully!', 'success');
  },
  false
);

export const updateCaseStudyRank = createAsyncAction(
  updateCaseStudyRankMutation,
  (res, dispatch) => {
    dispatch({ type: GET_CASE_STUDIES, payload: res.data });
    toastify('Case Study Rank Updated Successfully!', 'success');
  }
);

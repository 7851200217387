import React, { useEffect } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { FormikField } from '../../../../../../shared/components/form/Field';
import { FormikDropZoneField } from '../../../../../../shared/components/form/DropZone';
import {
  getAboutDetailsSectionData,
  saveAboutDetailsSectionData,
  clearAboutData
} from '../../../../../../redux/actions/aboutActions';
import { ROOT_URL } from '../../../../../../shared/helpers/constants';

const AboutDetailsForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { selectedSectionData } = useSelector((state) => state.aboutPage);
  const { isLoading } = useSelector((state) => state.uiState);

  useEffect(() => {
    dispatch(getAboutDetailsSectionData(id));
    return () => {
      dispatch(clearAboutData());
    };
  }, []);

  const initValues = {
    heading: selectedSectionData.heading || '',
    description: selectedSectionData.description || '',
    sectionImageImage: '',
  };

  const defaultImages = {
    sectionImage: selectedSectionData.sectionImage && ROOT_URL + selectedSectionData.sectionImage.url,
  };

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Service Section Content</h5>
            <h5 className="subhead">Edit Content</h5>
          </div>
          <div>
            <Formik
              enableReinitialize
              initialValues={initValues}
              validationSchema={Yup.object({
                heading: Yup.string().required('Required!'),
                description: Yup.string().required('Required!'),
              })}
              onSubmit={(values) => {
                dispatch(saveAboutDetailsSectionData({ id, values }));
              }}
            >
              {({ setFieldValue, values, touched, errors }) => (
                <Form className="form form--horizontal text-left">
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Heading
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        name="heading"
                        value={values.heading}
                        touched={touched.heading}
                        error={errors.heading}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Description
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        textArea
                        name="description"
                        value={values.description}
                        touched={touched.description}
                        error={errors.description}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Section Image
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikDropZoneField
                        name="sectionImage"
                        value={values.sectionImage}
                        setFieldValue={setFieldValue}
                        defaultImage={defaultImages.sectionImage}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3">
                      <button
                        className="btn btn-primary btn-sm"
                        type="submit"
                        disabled={isLoading}
                      >
                        Save
                      </button>
                    </span>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default AboutDetailsForm;

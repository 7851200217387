import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const { changeToDark, changeToLight } = this.props;
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink
            title="Dashboard"
            icon="home"
            route="/pages/dashboard"
            onClick={this.hideSidebar}
          />
          <SidebarCategory title="Layout" icon="layers">
            <button
              type="button"
              className="sidebar__link"
              onClick={changeToLight}
            >
              <p className="sidebar__link-title">Light Theme</p>
            </button>
            <button
              type="button"
              className="sidebar__link"
              onClick={changeToDark}
            >
              <p className="sidebar__link-title">Dark Theme</p>
            </button>
          </SidebarCategory>
        </ul>
        <ul className="sidebar__block">
          <SidebarCategory title="Website" icon="diamond">
            <SidebarCategory title="Widgets">
              <SidebarLink
                title="Header"
                route="/widgets/header"
                onClick={this.hideSidebar}
              />

              <SidebarLink
                title="Footer"
                route="/widgets/footer"
                onClick={this.hideSidebar}
              />
            </SidebarCategory>

            <SidebarCategory title="Pages">
              <SidebarCategory title="Home Page">
                <SidebarLink
                  title="Banner Section"
                  route="/pages/home/banner"
                  onClick={this.hideSidebar}
                />

                <SidebarLink
                  title="Who We Are Section"
                  route="/pages/home/who-we-are"
                  onClick={this.hideSidebar}
                />

                <SidebarLink
                  title="Product Services Section"
                  route="/pages/home/product-services"
                  onClick={this.hideSidebar}
                />

                <SidebarLink
                  title="Why Choose Us Section"
                  route="/pages/home/why-choose-us"
                  onClick={this.hideSidebar}
                />

                <SidebarLink
                  title="Portfolio Section"
                  route="/pages/home/portfolio"
                  onClick={this.hideSidebar}
                />

                <SidebarLink
                  title="Case Study Section"
                  route="/pages/home/case-study"
                  onClick={this.hideSidebar}
                />

                <SidebarLink
                  title="Info Cards Section"
                  route="/pages/home/info-cards"
                  onClick={this.hideSidebar}
                />

                <SidebarLink
                  title="Happy Clients"
                  route="/pages/home/happy-clients"
                  onClick={this.hideSidebar}
                />

                <SidebarLink
                  title="Faqs"
                  route="/pages/home/faqs"
                  onClick={this.hideSidebar}
                />

                <SidebarLink
                  title="Meta Data"
                  route="/pages/home/metaData"
                  onClick={this.hideSidebar}
                />
              </SidebarCategory>

              <SidebarCategory title="Case Study Page">
                <SidebarLink
                  title="Banner Section"
                  route="/pages/case-study/banner"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="Case Studies"
                  route="/pages/case-study/all-case-studies"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="Meta Data"
                  route="/pages/case-study/metaData"
                  onClick={this.hideSidebar}
                />
              </SidebarCategory>

              <SidebarCategory title="Services Page">
                <SidebarLink
                  title="Banner Section"
                  route="/pages/services/banner"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="Services"
                  route="/pages/services/all-services"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="Meta Data"
                  route="/pages/services/metaData"
                  onClick={this.hideSidebar}
                />
              </SidebarCategory>

              <SidebarCategory title="Blog Page">
                <SidebarLink
                  title="Banner Section"
                  route="/pages/blog/banner"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="Trending Section"
                  route="/pages/blog/trending"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="Blogs"
                  route="/pages/blog/all-blogs"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="Meta Data"
                  route="/pages/blog/metaData"
                  onClick={this.hideSidebar}
                />
              </SidebarCategory>

              <SidebarCategory title="About Page">
                <SidebarLink
                  title="Banner Section"
                  route="/pages/about/banner"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="About Details Section"
                  route="/pages/about/about-details"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="Performance Section"
                  route="/pages/about/about-performance"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="Meta Data"
                  route="/pages/about/metaData"
                  onClick={this.hideSidebar}
                />
              </SidebarCategory>

              <SidebarCategory title="Contact Page">
                <SidebarLink
                  title="Banner Section"
                  route="/pages/contact/banner"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="Contact Card Section"
                  route="/pages/contact/contact-card"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="Meta Data"
                  route="/pages/contact/metaData"
                  onClick={this.hideSidebar}
                />
              </SidebarCategory>

              <SidebarLink
                title="Products"
                route="/pages/products"
                onClick={this.hideSidebar}
              />
            </SidebarCategory>

            <SidebarLink
              title="Contact Forms"
              route="/pages/contactForms"
              onClick={this.hideSidebar}
            />
          </SidebarCategory>
        </ul>
      </div>
    );
  }
}

export default SidebarContent;

import {
  GET_CONTACT_META_DATA,
  CLEAR_CONTACT_PAGE_DATA,
  GET_CONTACT_FORMS_DATA,
  CLEAR_CONTACT_FORMS_DATA,
  GET_CONTACT_FORM,
  CLEAR_CONTACT_FORM,
  GET_CONTACT_BANNER_DATA,
  GET_CONTACT_CARD_DATA,
} from '../actions/contactActions';

const initialState = {
  forms: [],
  fetchedForm: {},
  bannerSectionData: {},
  contactCardData: {},
  contactMetaData: {},
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case CLEAR_CONTACT_PAGE_DATA:
      return {
        ...state,
        content: {},
        bannerSectionData: {},
        contactCardData: {},
        contactMetaData: {},
        forms: [],
      };
    case GET_CONTACT_META_DATA:
      return {
        ...state,
        contactMetaData: payload,
      };
    case GET_CONTACT_FORMS_DATA:
      return {
        ...state,
        forms: payload,
      };
    case CLEAR_CONTACT_FORMS_DATA:
      return {
        ...state,
        forms: [],
      };
    case GET_CONTACT_FORM:
      return {
        ...state,
        fetchedForm: payload,
      };
    case CLEAR_CONTACT_FORM:
      return {
        ...state,
        fetchedForm: {},
      };
    case GET_CONTACT_BANNER_DATA:
      return {
        ...state,
        bannerSectionData: payload,
      };
    case GET_CONTACT_CARD_DATA:
      return {
        ...state,
        contactCardData: payload,
      };
    default:
      return state;
  }
}

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Table, Badge, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIcon from 'mdi-react/DragIcon';
import Modal from '../../../../../../shared/components/Modal';
import {
  getAllServices,
  deleteService,
  updateServiceOrder,
} from '../../../../../../redux/actions/serviceActions';
import { ROOT_URL } from '../../../../../../shared/helpers/constants';

const SingleServiceRecord = ({
  index,
  item,
  setSelectedId,
  toggleDeleteModal,
}) => {
  const history = useHistory();

  return (
    <Draggable draggableId={item._id} index={index}>
      {(provided) => (
        <tr ref={provided.innerRef} {...provided.draggableProps}>
          <td className="vertical-align-middle">
            <span {...provided.dragHandleProps}>
              <DragIcon size={24} />
            </span>
          </td>
          <td>
            <img
              src={ROOT_URL + item.serviceImage.url}
              alt="img"
              style={{ height: 70, width: 90 }}
            />
          </td>
          <td className="w-50">{item.heading}</td>
          <td className="w-25">
            <div className="d-flex justify-content-end">
              <Badge
                role="button"
                color="success"
                type="button"
                className="mr-1"
                onClick={() => {
                  history.push(
                    `/pages/services/all-services/${item._id}/sections/`
                  );
                }}
              >
                View Details
              </Badge>
              <Badge
                role="button"
                color="secondary"
                type="button"
                className="mr-1"
                onClick={() => {
                  history.push(`/pages/services/all-services/${item._id}/edit`);
                }}
              >
                Edit
              </Badge>
              <Badge
                role="button"
                color="danger"
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedId(item._id);
                  toggleDeleteModal();
                }}
              >
                Delete
              </Badge>
            </div>
          </td>
        </tr>
      )}
    </Draggable>
  );
};

const SectionCard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { services } = useSelector((state) => state.servicesPage);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(false);

  useEffect(() => {
    dispatch(getAllServices());
  }, []);

  const toggleDeleteModal = () => setShowDeleteModal((prevState) => !prevState);

  const handleDelete = () => {
    dispatch(deleteService(selectedId));
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    // Set new dragged service records
    dispatch(
      updateServiceOrder({
        source: {
          item: services[result.source.index],
          index: result.source.index,
        },
        destination: {
          item: services[result.destination.index],
          index: result.destination.index,
        },
      })
    );
  };

  return (
    <Col md={12}>
      <Modal
        showModal={showDeleteModal}
        toggle={toggleDeleteModal}
        color="warning"
        title="Attention!"
        message="Are you sure, you want to delete this card?"
        onSuccess={handleDelete}
      />
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between">
            <div className="card__title">
              <h5 className="bold-text">Services Content</h5>
              <h5 className="subhead">Edit Content</h5>
            </div>
            <div>
              <Button
                color="primary"
                className="btn btn-sm"
                onClick={() => history.push('/pages/services/all-services/add')}
              >
                + Add New Service
              </Button>
            </div>
          </div>
          <div>
            <Table responsive hover>
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Image</th>
                  <th className="w-50">Heading</th>
                  <th className="w-25 text-center">Actions</th>
                </tr>
              </thead>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="service-records" type="ITEM">
                  {(provided) => (
                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                      {services.length > 0 ? (
                        services.map((service, index) => (
                          <SingleServiceRecord
                            key={service._id}
                            index={index}
                            item={service}
                            setSelectedId={setSelectedId}
                            toggleDeleteModal={toggleDeleteModal}
                          />
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4">
                            <div className="text-center">No Service Exist</div>
                          </td>
                        </tr>
                      )}
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

SingleServiceRecord.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  setSelectedId: PropTypes.func.isRequired,
  toggleDeleteModal: PropTypes.func.isRequired,
};

export default SectionCard;

import {
  GET_PORTFOLIO_DATA,
  CLEAR_PORTFOLIO_DATA,
  GET_PORTFOLIO_IMAGES,
  DELETE_PORTFOLIO_IMAGE,
  CLEAR_PORTFOLIO_IMAGES,
  ADD_PORTFOLIO_IMAGES,
} from '../actions/portfolioActions';

const initialState = {
  content: {},
  images: [],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_PORTFOLIO_DATA:
      return {
        ...state,
        content: payload,
      };
    case CLEAR_PORTFOLIO_DATA:
      return {
        ...state,
        content: {},
      };
    case GET_PORTFOLIO_IMAGES:
      return {
        ...state,
        images: payload,
      };
    case CLEAR_PORTFOLIO_IMAGES:
      return {
        ...state,
        images: [],
      };
    case DELETE_PORTFOLIO_IMAGE:
      return {
        ...state,
        images: state.images.filter((image) => image._id !== payload.id),
      };
    case ADD_PORTFOLIO_IMAGES:
      return {
        ...state,
        images: state.images.concat(payload),
      };
    default:
      return state;
  }
}

import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import HeadingForm from './HeadingForm';
import CategoriesTable from './CategoriesTable';

function SectionCard() {
  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Product Service content</h5>
            <h5 className="subhead">Edit Content</h5>
          </div>

          <HeadingForm />

          <CategoriesTable />
        </CardBody>
      </Card>
    </Col>
  );
}

export default SectionCard;

import {
  login,
  logout,
  setUser,
  removeUser,
  changeUserPassword,
} from '../../services/authServices';
import createAsyncAction from '../../shared/helpers/createAsyncAction';
import toastify from '../../shared/helpers/toastNotification';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';

// Set logged in User
export const setCurrentUser = (user) => ({
  type: SET_CURRENT_USER,
  payload: user,
});

// Login User
export const loginUser = createAsyncAction(login, (res) => {
  setUser(res.data);
  window.location = '/pages/dashboard';
});

// Logout User
export const logoutUser = createAsyncAction(
  logout,
  () => {
    removeUser();
    window.location = '/';
  },
  false
);

export const changePassword = createAsyncAction(changeUserPassword, (res) => {
  toastify(res.data.message, 'success');
});

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { getContactForm, clearContactForm } from '../../../redux/actions/contactActions';

const FormsContent = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { fetchedForm } = useSelector((state) => state.contactPage);

  useEffect(() => {
    dispatch(getContactForm(id));
    return () => {
      dispatch(clearContactForm());
    };
  }, [id]);

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between">
            <div className="card__title">
              <h5 className="bold-text">Form Detail</h5>
              <h5 className="subhead">Form</h5>
            </div>
          </div>
          <div style={{ width: '70%', marginLeft: 15 }}>
            <Row>
              <Col md={6}>
                <Row>
                  <p className="font-weight-bold custom-font-size">Name:</p>
                  <p className="ml-2 mt-0 custom-font-size">{fetchedForm.name}</p>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <p className="font-weight-bold custom-font-size">Email: </p>
                  <p className="ml-2 mt-0 custom-font-size">{fetchedForm.email}</p>
                </Row>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={6}>
                <Row>
                  <p className="font-weight-bold custom-font-size">Phone: </p>
                  <p className="ml-2 mt-0 custom-font-size">{fetchedForm.phone}</p>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <p className="font-weight-bold custom-font-size">Company: </p>
                  <p className="ml-2 mt-0 custom-font-size">{fetchedForm.company}</p>
                </Row>
              </Col>
            </Row>
            <Row className="mt-2">
              <p className="font-weight-bold custom-font-size">Message: </p>
            </Row>
            <Row>
              <p className="mt-2 custom-font-size">{fetchedForm.message}</p>
            </Row>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default FormsContent;

import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Table, Badge, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Modal from '../../../../../../shared/components/Modal';
import {
  getBlogReplies,
  deleteComment,
} from '../../../../../../redux/actions/blogsActions';

const SectionCard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { blogID, commentID } = useParams();
  const { blogReplies } = useSelector((state) => state.blogsPage);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');

  useEffect(() => {
    dispatch(getBlogReplies({ blogID, commentID }));
  }, []);

  const toggleDeleteModal = () => setShowDeleteModal((prevState) => !prevState);

  const handleDelete = () => {
    dispatch(deleteComment(selectedId));
  };

  return (
    <Col md={12}>
      <Modal
        showModal={showDeleteModal}
        toggle={toggleDeleteModal}
        color="warning"
        title="Attention!"
        message="Are you sure, you want to delete this reply?"
        onSuccess={handleDelete}
      />
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between">
            <div className="card__title">
              <h5 className="bold-text">Blog Replies</h5>
              <h5 className="subhead">Edit Content</h5>
            </div>
            <div>
              <Button
                color="primary"
                className="btn btn-sm"
                onClick={() =>
                  history.push(
                    `/pages/blog/all-blogs/${blogID}/comments/${commentID}/add`
                  )
                }
              >
                + Add New Reply
              </Button>
            </div>
          </div>
          <div>
            <Table responsive hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Username</th>
                  <th className="w-25">Email</th>
                  <th className="w-25">Reply</th>
                  <th className="w-25">Created At</th>
                  <th className="w-25 text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {blogReplies.length > 0 &&
                  blogReplies.map((item, index) => (
                    <tr key={item._id}>
                      <td>{index + 1}</td>
                      <td className="w-25">{item.username}</td>
                      <td className="w-25">{item.email}</td>
                      <td className="w-25">{item.comment}</td>
                      <td className="w-25">{item.createdAt}</td>
                      <td className="w-25 text-center d-inline-flex">
                        <Badge
                          role="button"
                          color="secondary"
                          type="button"
                          className="mr-1"
                          onClick={() => {
                            history.push(
                              `/pages/blog/all-blogs/comments/${item._id}/edit`
                            );
                          }}
                        >
                          Edit
                        </Badge>
                        <Badge
                          role="button"
                          color="danger"
                          type="button"
                          className="mr-1"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedId(item._id);
                            toggleDeleteModal();
                          }}
                        >
                          Delete
                        </Badge>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SectionCard;

import React from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { FormikField } from '../../../shared/components/form/Field';
import { changePassword } from '../../../redux/actions/authActions';

const initValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

function ChangePasswordFrom() {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.uiState);

  return (
    <Col md={12} lg={12} xl={7}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Change Password</h5>
          </div>
          <div>
            <Formik
              initialValues={initValues}
              validationSchema={Yup.object({
                oldPassword: Yup.string().required('Required!'),
                newPassword: Yup.string()
                  .min(8, 'Password must contain atleast 8 characters.')
                  .required('Required!'),
                confirmPassword: Yup.string().oneOf(
                  [Yup.ref('newPassword')],
                  'Passwords did not match'
                ),
              })}
              onSubmit={(values) => {
                dispatch(changePassword(values));
              }}
            >
              {({ values, touched, errors }) => (
                <Form className="form">
                  <div className="form__form-group">
                    <span className="form__form-group-label text-nowrap required">
                      Old Password
                    </span>
                    <div className="form__form-group-field">
                      <FormikField
                        name="oldPassword"
                        value={values.oldPassword}
                        touched={touched.oldPassword}
                        error={errors.oldPassword}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label text-nowrap required">
                      New Password
                    </span>
                    <div className="form__form-group-field">
                      <FormikField
                        name="newPassword"
                        value={values.newPassword}
                        touched={touched.newPassword}
                        error={errors.newPassword}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label text-nowrap required">
                      Re-Enter New Password
                    </span>
                    <div className="form__form-group-field">
                      <FormikField
                        name="confirmPassword"
                        value={values.confirmPassword}
                        touched={touched.confirmPassword}
                        error={errors.confirmPassword}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      <button
                        className="btn btn-primary btn-sm"
                        type="submit"
                        disabled={isLoading}
                      >
                        Update Password
                      </button>
                    </span>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}

export default ChangePasswordFrom;

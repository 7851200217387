import http, { multipartConfig } from './httpService';

export const getBannerData = () => {
  return http.get('/blog/banner');
};

export const saveBannerData = (values) => {
  const data = { ...values };

  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'string') {
      if (value) {
        formData.append(key, value);
      }
    }
  });

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item);
      });
    }
  });

  return http.post('/blog/banner', formData, multipartConfig);
};

export const getTrendingData = () => {
  return http.get('/blog/trending');
};

export const saveTrendingData = (values) => {
  const data = { ...values };

  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'string') {
      if (value) {
        formData.append(key, value);
      }
    }
  });

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item);
      });
    }
  });

  return http.post('/blog/trending', formData, multipartConfig);
};

export const getBlogs = () => {
  return http.get('/blog/all-blogs');
};

export const getBlog = (id) => {
  return http.get(`/blog/all-blogs/${id}`);
};

export const getComment = (id) => {
  return http.get(`/blog/all-blogs/comments/${id}`);
};

export const getSingleBlogComments = (id) => {
  return http.get(`/blog/all-blogs/${id}/comments`);
};

export const getSingleBlogReplies = (data) => {
  const { blogID, commentID } = data;
  return http.get(`/blog/all-blogs/${blogID}/comments/${commentID}/replies`);
};

export const addNewReply = ({ blogID, commentID, data }) => {
  return http.post(
    `/blog/all-blogs/${blogID}/comments/${commentID}/reply`,
    data
  );
};

export const updateSingleComment = ({ commentID, data }) => {
  return http.put(`/blog/all-blogs/comments/${commentID}/edit`, data);
};

export const approveSingleComment = (commentID) => {
  return http.put(`/blog/all-blogs/comments/${commentID}/approve`);
};

export const deleteSingleComment = (commentID) => {
  return http.delete(`/blog/all-blogs/comments/${commentID}`);
};

export const addBlog = (values) => {
  const data = { ...values };

  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'string') {
      if (value) {
        formData.append(key, value);
      }
    }
  });

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item);
      });
    }
  });

  return http.post('/blog/all-blogs/add', formData, multipartConfig);
};

export const deleteBlogData = (id) => {
  return http.delete(`/blog/all-blogs/${id}`);
};

export const updateBlogData = ({ id, values }) => {
  const data = { ...values };

  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'string') {
      if (value) {
        formData.append(key, value);
      }
    }
  });

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item);
      });
    }
  });

  return http.put(`/blog/all-blogs/${id}`, formData, multipartConfig);
};

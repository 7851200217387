import http, { multipartConfig } from './httpService';

export const getBannerData = () => {
  return http.get('/services/banner');
};

export const saveBannerData = (values) => {
  const data = { ...values };

  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'string') {
      if (value) {
        formData.append(key, value);
      }
    }
  });

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item);
      });
    }
  });

  return http.post('/services/banner', formData, multipartConfig);
};

export const getServices = () => {
  return http.get('/services/all-services');
};

export const getService = (id) => {
  return http.get(`/services/all-services/${id}`);
};

export const addService = (values) => {
  const data = { ...values };

  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'string') {
      if (value) {
        formData.append(key, value);
      }
    }
  });

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item);
      });
    }
  });

  return http.post('/services/all-services/add', formData, multipartConfig);
};

export const updateServiceRank = (payload) => {
  return http.post('/services/all-services/update/rank/', payload);
};

export const updateSingleService = ({ id, values }) => {
  const data = { ...values };

  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'string') {
      if (value) {
        formData.append(key, value);
      }
    }
  });

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item);
      });
    }
  });

  return http.put(`/services/all-services/${id}`, formData, multipartConfig);
};

export const deleteServiceData = (id) => {
  return http.delete(`/services/all-services/${id}`);
};

export const getServiceDetailsData = (id) => {
  return http.get(`/services/all-services/${id}/details`);
};

export const getServiceDetailsSectionData = ({ serviceId, sectionId }) => {
  return http.get(`/services/all-services/${serviceId}/details/${sectionId}`);
};

export const addServiceDetailsSectionData = ({ id, values }) => {
  const data = { ...values };

  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'string') {
      if (value) {
        formData.append(key, value);
      }
    }
  });

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item);
      });
    }
  });

  return http.post(
    `/services/all-services/${id}/details/add`,
    formData,
    multipartConfig
  );
};

export const updateServiceDetailsSectionData = ({
  serviceId,
  sectionId,
  values,
}) => {
  const data = { ...values };

  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'string') {
      if (value) {
        formData.append(key, value);
      }
    }
  });

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item);
      });
    }
  });

  return http.put(
    `/services/all-services/${serviceId}/details/${sectionId}`,
    formData,
    multipartConfig
  );
};

export const deleteServiceDetailsSectionData = ({ id, selectedId }) => {
  return http.delete(`/services/all-services/${id}/details/${selectedId}`);
};

import toastify from '../../shared/helpers/toastNotification';
import createAsyncAction from '../../shared/helpers/createAsyncAction';
import {
  getBannerData,
  saveBannerData,
  getWhatWeDoData,
  saveWhatWeDoData,
  getWhoWeAreData,
  saveWhoWeAreData,
  getPortfolioData,
  savePortfolioData,
  deletePortfolioImage,
  getCaseStudyData,
  saveCaseStudyData,
  getHappyClientsData,
  getClientData,
  addClient,
  updateClient,
  deleteClient,
  addProductServicesSectionHeading,
  getProductServicesSectionData,
  postProductService,
  updateProductService,
  getSingleProductServicesQuery,
  deleteProductService,
  getWhyChooseSectionDataQuery,
  saveWhyChooseSectionDataMutation,
  addWhyChooseFaqMutation,
  getSingleWhyChooseFaqQuery,
  updateWhyChooseFaqMutation,
  deleteWhyChooseFaqMutation,
  getInfoCardsQuery,
  deleteInfoCardMutation,
  addInfoCardMutation,
  getSingleInfoCardQuery,
  updateInfoCardMutation,
  saveFaqSectionDataMutation,
  getFaqSectionDataQuery,
  deleteFaqMutation,
  addFaqMutation,
  updateFaqMutation,
  getFaqByIdQuery,
} from '../../services/homePageServices';
import { getMetaData, saveMetaData } from '../../services/metaDataService';

export const CLEAR_HOME_DATA = 'CLEAR_HOME_DATA';
export const GET_HOME_BANNER_DATA = 'GET_HOME_BANNER_DATA';
export const GET_HOME_WHAT_WE_DO_DATA = 'GET_HOME_WHAT_WE_DO_DATA';
export const GET_HOME_WHO_WE_ARE_DATA = 'GET_HOME_WHO_WE_ARE_DATA';
export const GET_HOME_PORTFOLIO_DATA = 'GET_HOME_PORTFOLIO_DATA';
export const GET_HOME_CASE_STUDY_DATA = 'GET_HOME_CASE_STUDY_DATA';
export const GET_HOME_HAPPY_CLIENTS_DATA = 'GET_HOME_HAPPY_CLIENTS_DATA';
export const GET_SINGLE_CLIENT_DATA = 'GET_SINGLE_CLIENT_DATA';
export const GET_HOME_META_DATA = 'GET_HOME_META_DATA';
export const DELETE_HOME_PORTFOLIO_IMAGE = 'DELETE_HOME_PORTFOLIO_IMAGE';
export const DELETE_HAPPY_CLIENT = 'DELETE_HAPPY_CLIENT';
export const SAVE_HOME_SECTION_HEADING = 'SAVE_HOME_SECTION_HEADING';
export const GET_HOME_SECTION_DATA = 'GET_HOME_SECTION_DATA';
export const ADD_PRODUCT_SERVICE = 'ADD_PRODUCT_SERVICE';
export const GET_SINGLE_PRODUCT_SERVICE = 'GET_SINGLE_PRODUCT_SERVICE';
export const DELETE_PRODUCT_SERVICE = 'DELETE_PRODUCT_SERVICE';
export const GET_WHY_CHOOSE_DATA = 'GET_WHY_CHOOSE_DATA';
export const SAVE_WHY_CHOOSE_DATA = 'SAVE_WHY_CHOOSE_DATA';
export const DELETE_WHY_CHOOSE_FAQ = 'DELETE_WHY_CHOOSE_FAQ';
export const ADD_WHY_CHOOSE_FAQ = 'ADD_WHY_CHOOSE_FAQ';
export const GET_WHY_CHOOSE_FAQ = 'GET_WHY_CHOOSE_FAQ';
export const GET_INFO_CARDS = 'GET_INFO_CARDS';
export const DELETE_INFO_CARD = 'DELETE_INFO_CARD';
export const ADD_INFO_CARD = 'ADD_INFO_CARD';
export const GET_SINGLE_INFO_CARD = 'GET_SINGLE_INFO_CARD';
export const GET_FAQ_SECTION_DATA = 'GET_FAQ_SECTION_DATA';
export const SAVE_FAQ_SECTION_DATA = 'SAVE_FAQ_SECTION_DATA';
export const DELETE_FAQ = 'DELETE_FAQ';
export const ADD_FAQ = 'ADD_FAQ';
export const GET_FAQ = 'GET_FAQ';

export const clearHomeData = () => ({ type: CLEAR_HOME_DATA });

export const getHomeMetaData = createAsyncAction(
  getMetaData,
  (res, dispatch) => {
    dispatch({ type: GET_HOME_META_DATA, payload: res.data });
  }
);

export const saveHomeMetaData = createAsyncAction(saveMetaData, (res) => {
  toastify(res.data.message, 'success');
});

export const getHomeBannerData = createAsyncAction(
  getBannerData,
  (res, dispatch) => {
    dispatch({ type: GET_HOME_BANNER_DATA, payload: res.data });
  }
);

export const saveHomeBannerData = createAsyncAction(saveBannerData, (res) => {
  toastify(res.data.message, 'success');
});

export const getHomeWhatWeDoData = createAsyncAction(
  getWhatWeDoData,
  (res, dispatch) => {
    dispatch({ type: GET_HOME_WHAT_WE_DO_DATA, payload: res.data });
  }
);

export const saveWhatWeDoSectionData = createAsyncAction(
  saveWhatWeDoData,
  (res) => {
    toastify(res.data.message, 'success');
  }
);

export const getHomeWhoWeAreData = createAsyncAction(
  getWhoWeAreData,
  (res, dispatch) => {
    dispatch({ type: GET_HOME_WHO_WE_ARE_DATA, payload: res.data });
  }
);

export const saveWhoWeAreSectionData = createAsyncAction(
  saveWhoWeAreData,
  (res) => {
    toastify(res.data.message, 'success');
  }
);

export const getHomePortfolioData = createAsyncAction(
  getPortfolioData,
  (res, dispatch) => {
    dispatch({ type: GET_HOME_PORTFOLIO_DATA, payload: res.data });
  }
);

export const savePortfolioSectionData = createAsyncAction(
  savePortfolioData,
  (res) => {
    toastify(res.data.message, 'success');
  }
);

export const deleteHomePortfolioSectionImage = createAsyncAction(
  deletePortfolioImage,
  (res, dispatch) => {
    dispatch({ type: DELETE_HOME_PORTFOLIO_IMAGE, payload: res.data });
    toastify('Image Deleted Successfully!', 'success');
  },
  false
);

export const getHomeCaseStudyData = createAsyncAction(
  getCaseStudyData,
  (res, dispatch) => {
    dispatch({ type: GET_HOME_CASE_STUDY_DATA, payload: res.data });
  }
);

export const saveCaseStudySectionData = createAsyncAction(
  saveCaseStudyData,
  (res) => {
    toastify(res.data.message, 'success');
  }
);

export const getHomeHappyClientsData = createAsyncAction(
  getHappyClientsData,
  (res, dispatch) => {
    dispatch({ type: GET_HOME_HAPPY_CLIENTS_DATA, payload: res.data });
  }
);

export const getSingleClientData = createAsyncAction(
  getClientData,
  (res, dispatch) => {
    dispatch({ type: GET_SINGLE_CLIENT_DATA, payload: res.data });
  }
);

export const addHappyClient = createAsyncAction(addClient, (res) => {
  toastify(res.data.message, 'success');
});

export const updateHappyClient = createAsyncAction(updateClient, (res) => {
  toastify(res.data.message, 'success');
});

export const deleteHappyClient = createAsyncAction(
  deleteClient,
  (res, dispatch) => {
    dispatch({ type: DELETE_HAPPY_CLIENT, payload: res.data });
    toastify('Client Deleted Successfully!', 'success');
  },
  false
);

export const saveProductServiceSectionHeading = createAsyncAction(
  addProductServicesSectionHeading,
  (res, dispatch) => {
    dispatch({ type: SAVE_HOME_SECTION_HEADING, payload: res.data });
    toastify('Heading added successfully', 'success');
  }
);

export const getProductServiceSectionContent = createAsyncAction(
  getProductServicesSectionData,
  (res, dispatch) => {
    dispatch({ type: GET_HOME_SECTION_DATA, payload: res.data });
  }
);

export const getSingleProductService = createAsyncAction(
  getSingleProductServicesQuery,
  (res, dispatch) => {
    dispatch({ type: GET_SINGLE_PRODUCT_SERVICE, payload: res.data });
  }
);

export const addProductServiceAction = createAsyncAction(
  postProductService,
  (res, dispatch) => {
    dispatch({ type: ADD_PRODUCT_SERVICE, payload: res.data });
  }
);

export const updateProductServiceAction = createAsyncAction(
  updateProductService,
  () => {
    toastify('Updated Successfully!', 'success');
  }
);

export const deleteProductServiceAction = createAsyncAction(
  deleteProductService,
  (res, dispatch) => {
    dispatch({ type: DELETE_PRODUCT_SERVICE, payload: res.data });
    toastify('Item Deleted Successfully!', 'success');
  }
);

export const getWhyChooseSectionData = createAsyncAction(
  getWhyChooseSectionDataQuery,
  (res, dispatch) => {
    dispatch({ type: GET_WHY_CHOOSE_DATA, payload: res.data });
  }
);

export const saveWhyChooseSectionData = createAsyncAction(
  saveWhyChooseSectionDataMutation,
  (res, dispatch) => {
    dispatch({ type: SAVE_WHY_CHOOSE_DATA, payload: res.data });
    toastify('Updated Successfully!', 'success');
  }
);

export const addNewWhyChooseFaq = createAsyncAction(
  addWhyChooseFaqMutation,
  (res, dispatch) => {
    dispatch({ type: ADD_WHY_CHOOSE_FAQ, payload: res.data });
    toastify('Added Successfully!', 'success');
  }
);

export const deleteWhyChooseFaq = createAsyncAction(
  deleteWhyChooseFaqMutation,
  (res, dispatch) => {
    dispatch({ type: DELETE_WHY_CHOOSE_FAQ, payload: res.data });
    toastify('Deleted Successfully!', 'success');
  }
);

export const getSingleWhyChooseFaq = createAsyncAction(
  getSingleWhyChooseFaqQuery,
  (res, dispatch) => {
    dispatch({ type: GET_WHY_CHOOSE_FAQ, payload: res.data });
  }
);

export const updateWhyChooseFaq = createAsyncAction(
  updateWhyChooseFaqMutation,
  () => {
    toastify('Updated Successfully!', 'success');
  }
);

export const getInfoCardsAction = createAsyncAction(
  getInfoCardsQuery,
  (res, dispatch) => {
    dispatch({ type: GET_INFO_CARDS, payload: res.data });
  }
);

export const deleteInfoCardAction = createAsyncAction(
  deleteInfoCardMutation,
  (res, dispatch) => {
    dispatch({ type: DELETE_INFO_CARD, payload: res.data });
    toastify('Deleted Successfully!', 'success');
  }
);

export const addInfoCardAction = createAsyncAction(
  addInfoCardMutation,
  (res, dispatch) => {
    dispatch({ type: ADD_INFO_CARD, payload: res.data });
    toastify('Added Successfully!', 'success');
  }
);

export const getInfoCardAction = createAsyncAction(
  getSingleInfoCardQuery,
  (res, dispatch) => {
    dispatch({ type: GET_SINGLE_INFO_CARD, payload: res.data });
  }
);

export const updateInfoCardAction = createAsyncAction(
  updateInfoCardMutation,
  () => {
    toastify('Updated Successfully!', 'success');
  }
);

export const saveFaqSectionData = createAsyncAction(
  saveFaqSectionDataMutation,
  (res, dispatch) => {
    dispatch({ type: SAVE_FAQ_SECTION_DATA, payload: res.data });
    toastify('Updated Successfully!', 'success');
  }
);

export const getFaqSectionData = createAsyncAction(
  getFaqSectionDataQuery,
  (res, dispatch) => {
    dispatch({ type: GET_FAQ_SECTION_DATA, payload: res.data });
  }
);

export const deleteFaq = createAsyncAction(
  deleteFaqMutation,
  (res, dispatch) => {
    dispatch({ type: DELETE_FAQ, payload: res.data });
    toastify('Deleted Successfully!', 'success');
  }
);

export const addNewFaq = createAsyncAction(addFaqMutation, () => {
  toastify('Added Successfully!', 'success');
});

export const updateFaq = createAsyncAction(updateFaqMutation, () => {
  toastify('Updated Successfully!', 'success');
});

export const getSingleFaq = createAsyncAction(
  getFaqByIdQuery,
  (res, dispatch) => {
    dispatch({ type: GET_FAQ, payload: res.data });
  }
);

// export const

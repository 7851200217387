import { setLoading, unSetLoading } from '../../redux/actions/uiActions';
import toastify from './toastNotification';

export default function (asyncFunction, handleSuccessResponse, load = true) {
  return (data, ...args) => (dispatch) => {
    if (load) {
      dispatch(setLoading());
    }

    asyncFunction(data, ...args).then(
      (res) => {
        if (load) {
          dispatch(unSetLoading());
        }

        handleSuccessResponse(res, dispatch);
      },
      (err) => {
        if (err.response && (err.response.status >= 400 || err.response.status < 500)) {
          return toastify(err.response.data.message, 'error');
        }

        if (load) {
          dispatch(unSetLoading());
        }

        return null;
      }
    );
  };
}

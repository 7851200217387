import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import DragIcon from 'mdi-react/DragIcon';
import { Draggable } from 'react-beautiful-dnd';
import { TableRow as MuiTableRow, TableCell } from '@material-ui/core';
import { ROOT_URL } from '../../../../../../shared/helpers/constants';

function TableRow({ item, index, handleDelete }) {
  const history = useHistory();

  return (
    <Draggable draggableId={item._id} index={index}>
      {(provided) => (
        <MuiTableRow ref={provided.innerRef} {...provided.draggableProps}>
          <TableCell>
            <span {...provided.dragHandleProps}>
              <DragIcon size={24} />
            </span>
          </TableCell>
          <TableCell>
            <img
              src={ROOT_URL + item.caseStudyImage.url}
              alt="img"
              style={{ height: 70, width: 90 }}
            />
          </TableCell>
          <TableCell className="w-25">{item.company.name}</TableCell>
          <TableCell className="w-50">{item.company.description}</TableCell>
          <TableCell className="w-25 text-center">
            <Badge
              role="button"
              color="secondary"
              type="button"
              className="mr-1"
              onClick={() => {
                history.push(
                  `/pages/case-study/all-case-studies/${item._id}/edit`
                );
              }}
            >
              Edit
            </Badge>

            <Badge
              role="button"
              color="danger"
              type="button"
              onClick={(e) => handleDelete(e, item._id)}
            >
              Delete
            </Badge>
          </TableCell>
        </MuiTableRow>
      )}
    </Draggable>
  );
}

TableRow.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleDelete: PropTypes.func,
};

TableRow.defaultProps = {
  handleDelete: () => {},
};

export default TableRow;

import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button, Card, CardBody } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS
import FormikModernField from '../../../../../../shared/components/form/FormikModernField';
import SubmitBtn from '../../../../../../shared/components/form/SubmitBtn';
import {
  addEditFormInitVals,
  addEditFormValSchema,
} from '../utilities/formUtils';
import {
  addNewFaq,
  getSingleFaq,
  updateFaq,
} from '../../../../../../redux/actions/homeActions';

function AddEditFaqForm() {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { fetched } = useSelector((state) => state.homePage.faqsSectionData);
  const [initValues, setInitValues] = useState(addEditFormInitVals);

  useEffect(() => {
    if (id) {
      dispatch(getSingleFaq(id));
    }
  }, []);

  useEffect(() => {
    if (id && Object.values(fetched).length > 0) {
      setInitValues({ title: fetched.title, description: fetched.description });
    }
  }, [fetched]);

  return (
    <Container>
      <Row>
        <Col xs={10}>
          <h3 className="page-title">Faqs Section</h3>
        </Col>
        <Col xs={2} className="d-flex justify-content-end">
          <div>
            <Button
              onClick={history.goBack}
              color="primary"
              className="btn btn-xs"
            >
              Back
            </Button>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="subhead">Manage Content</h5>
              </div>

              <div>
                <Formik
                  enableReinitialize
                  initialValues={initValues}
                  validationSchema={addEditFormValSchema}
                  onSubmit={(values) => {
                    if (id) {
                      dispatch(updateFaq(values, id));
                    } else {
                      dispatch(addNewFaq(values));
                    }
                  }}
                >
                  {() => (
                    <Form className="form form--horizontal">
                      <FormikModernField name="title" label="Title" />

                      <FormikModernField
                        name="description"
                        label="Description"
                        textArea
                      />

                      <SubmitBtn />
                    </Form>
                  )}
                </Formik>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default AddEditFaqForm;

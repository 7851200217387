import React, { useEffect } from 'react';
import { Col, Container, Row, Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// COMPONENTS & ACTIONS
import SectionBody from './components/SectionBody';
import { getFaqSectionData } from '../../../../../redux/actions/homeActions';

function GetStartedSection() {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFaqSectionData());
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <Col xs={10}>
          <h3 className="page-title">Faqs Section</h3>
        </Col>
        <Col xs={2} className="d-flex justify-content-end">
          <div>
            <Button
              onClick={history.goBack}
              color="primary"
              className="btn btn-xs"
            >
              Back
            </Button>
          </div>
        </Col>
      </Row>

      <Row>
        <SectionBody />
      </Row>
    </Container>
  );
}

export default GetStartedSection;

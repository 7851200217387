import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Table, Badge, Button } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../../../../shared/components/Modal';
import {
  getServiceDetails,
  deleteServiceDetailsSection,
  clearServicePageData
} from '../../../../../../redux/actions/serviceActions';
import { ROOT_URL } from '../../../../../../shared/helpers/constants';

const ServiceDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { selectedServiceDetails } = useSelector((state) => state.servicesPage);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(false);

  useEffect(() => {
    dispatch(getServiceDetails(id));
    return () => {
      dispatch(clearServicePageData());
    };
  }, []);

  const toggleDeleteModal = () => setShowDeleteModal((prevState) => !prevState);

  const handleDelete = () => {
    dispatch(deleteServiceDetailsSection({id, selectedId}));
  };

  return (
    <Col md={12}>
      <Modal
        showModal={showDeleteModal}
        toggle={toggleDeleteModal}
        color="warning"
        title="Attention!"
        message="Are you sure, you want to delete this card?"
        onSuccess={handleDelete}
      />
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between">
            <div className="card__title">
              <h5 className="bold-text">Service Details Content</h5>
              <h5 className="subhead">Edit Content</h5>
            </div>
            <div>
              <Button
                color="primary"
                className="btn btn-sm"
                onClick={() =>
                  history.push(`/pages/services/all-services/${id}/sections/add`)
                }
              >
                + Add New
              </Button>
            </div>
          </div>
          <div>
            <Table responsive hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Image</th>
                  <th className="w-25">Heading</th>
                  <th className="w-25">Sub Heading</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {selectedServiceDetails.map((card, index) => (
                  <tr
                    key={card._id}
                  >
                    <td>{ index + 1 }</td>
                    <td>
                      <img
                        src={ROOT_URL + card.sectionImage.url}
                        alt="thumbnail"
                        style={{ height: 70, width: 100 }}
                      />
                    </td>
                    <td className="w-25">{ card.heading }</td>
                    <td className="w-25">{ card.subheading }</td>
                    <td className="w-25 text-center">
                      <Badge
                        role="button"
                        color="secondary"
                        type="button"
                        className="mr-1"
                        onClick={() => {
                          history.push(`/pages/services/all-services/${id}/sections/${card._id}/edit`)
                        }}
                      >
                        Edit
                      </Badge>
                      <Badge
                        role="button"
                        color="danger"
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedId(card._id);
                          toggleDeleteModal();
                        }}
                      >
                        Delete
                      </Badge>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ServiceDetails;

import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Table, Badge } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Modal from '../../../../../../shared/components/Modal';
import {
  getBlogComments,
  approveComment,
  deleteComment,
} from '../../../../../../redux/actions/blogsActions';

const SectionCard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { blogID } = useParams();
  const { blogComments } = useSelector((state) => state.blogsPage);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(false);

  useEffect(() => {
    dispatch(getBlogComments(blogID));
  }, []);

  const toggleDeleteModal = () => setShowDeleteModal((prevState) => !prevState);

  const handleDelete = () => {
    dispatch(deleteComment(selectedId));
  };

  const isPending = (item) => item.status === 'pending';
  const isApproved = (item) => item.status === 'approved';

  return (
    <Col md={12}>
      <Modal
        showModal={showDeleteModal}
        toggle={toggleDeleteModal}
        color="warning"
        title="Attention!"
        message="Are you sure, you want to delete this comment?"
        onSuccess={handleDelete}
      />
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between">
            <div className="card__title">
              <h5 className="bold-text">Blog Comments</h5>
              <h5 className="subhead">Edit Content</h5>
            </div>
          </div>
          <div>
            <Table responsive hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Username</th>
                  <th className="w-25">Email</th>
                  <th className="w-25">Comment</th>
                  <th className="w-25">Created At</th>
                  <th className="w-25 text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {blogComments.length > 0 &&
                  blogComments.map((item, index) => (
                    <tr key={item._id}>
                      <td>{index + 1}</td>
                      <td className="w-25">{item.username}</td>
                      <td className="w-25">{item.email}</td>
                      <td className="w-25">{item.comment}</td>
                      <td className="w-25">{item.createdAt}</td>
                      <td className="w-25 text-center d-inline-flex">
                        {isPending(item) && (
                          <Badge
                            role="button"
                            color="success"
                            type="button"
                            className="mr-1"
                            onClick={(e) => {
                              e.stopPropagation();
                              dispatch(approveComment(item._id));
                            }}
                          >
                            Approve
                          </Badge>
                        )}
                        <Badge
                          role="button"
                          color="danger"
                          type="button"
                          className="mr-1"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedId(item._id);
                            toggleDeleteModal();
                          }}
                        >
                          {isApproved(item) ? 'Delete' : 'Reject'}
                        </Badge>
                        <Badge
                          role="button"
                          color="secondary"
                          type="button"
                          onClick={() => {
                            history.push(
                              `/pages/blog/all-blogs/${item.blogID}/comments/${item._id}/replies`
                            );
                          }}
                        >
                          Replies
                        </Badge>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SectionCard;

import http, { multipartConfig } from './httpService';

export const getBannerData = () => {
  return http.get('/case-study/banner');
};

export const saveBannerData = (values) => {
  const data = { ...values };

  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'string') {
      if (value) {
        formData.append(key, value);
      }
    }
  });

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item);
      });
    }
  });

  return http.post('/case-study/banner', formData, multipartConfig);
};

export const getCaseStudies = () => {
  return http.get('/case-study/all-case-studies');
};

export const getCaseStudy = (id) => {
  return http.get(`/case-study/all-case-studies/${id}`);
};

export const addCaseStudy = (values) => {
  const data = { ...values };

  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'string') {
      if (value) {
        formData.append(key, value);
      }
    }
  });

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item);
      });
    }
  });

  return http.post(
    '/case-study/all-case-studies/add',
    formData,
    multipartConfig
  );
};

export const updateCaseStudy = ({ id, values }) => {
  const data = { ...values };

  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'string') {
      if (value) {
        formData.append(key, value);
      }
    }
  });

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item);
      });
    }
  });

  return http.put(
    `/case-study/all-case-studies/${id}`,
    formData,
    multipartConfig
  );
};

export const deleteCaseStudyData = (id) => {
  return http.delete(`/case-study/all-case-studies/${id}`);
};

export const updateCaseStudyRankMutation = (values) => {
  return http.put('/case-study/update/rank', values);
};

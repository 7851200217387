import toastify from '../../shared/helpers/toastNotification';
import createAsyncAction from '../../shared/helpers/createAsyncAction';
import {
  deleteClientCard,
  getClientSectionData,
  getClientSectionDataById,
  saveClientCard,
  updateClientCard,
} from '../../services/clientSectionService';

// CLient Card Constants
export const GET_CLIENT_CARDS = 'GET_CLIENT_CARDS';
export const GET_CLIENT_CARD = 'GET_CLIENT_CARD';
export const CLEAR_CLIENT_CARDS = 'CLEAR_CLIENT_CARDS';
export const CLEAR_CLIENT_CARD = 'CLEAR_CLIENT_CARD';
export const DELETE_CLIENT_CARD = 'DELETE_CLIENT_CARD';
export const RESET_CLIENT_FORM = 'RESET_CLIENT_FORM';

export const clearClientCards = () => ({ type: CLEAR_CLIENT_CARDS });

export const clearClientCard = () => ({ type: CLEAR_CLIENT_CARD });

export const resetClientForm = () => ({ type: RESET_CLIENT_FORM });

export const getClientCards = createAsyncAction(getClientSectionData, (res, dispatch) => {
  dispatch({ type: GET_CLIENT_CARDS, payload: res.data });
});

export const getClientCardById = createAsyncAction(
  getClientSectionDataById,
  (res, dispatch) => {
    dispatch({ type: GET_CLIENT_CARD, payload: res.data });
  }
);

export const addClientCard = createAsyncAction(saveClientCard, (res, dispatch) => {
  toastify(res.data.message, 'success');
  dispatch(resetClientForm());
});

export const editClientCard = createAsyncAction(updateClientCard, (res) => {
  toastify(res.data.message, 'success');
});

export const removeClientCard = createAsyncAction(
  deleteClientCard,
  (res, dispatch) => {
    dispatch({ type: DELETE_CLIENT_CARD, payload: res.data });
    toastify('Deleted Successfully!');
  },
  false
);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row, Button, Card, CardBody } from 'reactstrap';
import MetaDataForm from '../../../../Common/MetaDataForm';
import {
  getCaseStudyMetaData,
  saveCaseStudyMetaData,
  clearCaseStudyData,
} from '../../../../../redux/actions/caseStudyActions';

const CaseStudyMetaData = ({ history }) => {
  const dispatch = useDispatch();
  const { caseStudyMetaData } = useSelector((state) => state.caseStudy);

  useEffect(() => {
    dispatch(getCaseStudyMetaData('case-study'));
    return () => {
      dispatch(clearCaseStudyData());
    };
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <Col xs={10}>
          <h3 className="page-title">Case Study Meta Data Section</h3>
        </Col>
        <Col xs={2} className="d-flex justify-content-end">
          <div>
            <Button
              onClick={history.goBack}
              color="primary"
              className="btn btn-xs"
            >
              Back
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">MetaData Content</h5>
                <h5 className="subhead">Edit Content</h5>
              </div>
              <div>
                <MetaDataForm
                  page="case-study"
                  data={caseStudyMetaData}
                  handleSubmit={saveCaseStudyMetaData}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

CaseStudyMetaData.propTypes = {
  history: PropTypes.object.isRequired,
};

export default CaseStudyMetaData;

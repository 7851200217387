import React, { useEffect } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { FormikField } from '../../../../../../shared/components/form/Field';
import { FormikDropZoneField } from '../../../../../../shared/components/form/DropZone';
import FormikTextEditor from '../../../../../../shared/components/form/FormikTextEditor';
import {
  getServiceDetailsSection,
  addNewServiceDetailsSection,
  updateServiceDetailsSection,
  clearServicePageData,
} from '../../../../../../redux/actions/serviceActions';
import { ROOT_URL } from '../../../../../../shared/helpers/constants';

const SectionForm = () => {
  const dispatch = useDispatch();
  const { serviceId, sectionId } = useParams(); // For Update Params
  const { id } = useParams(); // For Add params
  const { selectedServiceDetailsSection } = useSelector(
    (state) => state.servicesPage
  );
  const { isLoading } = useSelector((state) => state.uiState);

  useEffect(() => {
    if (serviceId && sectionId)
      dispatch(getServiceDetailsSection({ serviceId, sectionId }));
    return () => {
      dispatch(clearServicePageData());
    };
  }, []);

  const initValues = {
    heading: selectedServiceDetailsSection.heading || '',
    subheading: selectedServiceDetailsSection.subheading || '',
    description: selectedServiceDetailsSection.description || '',
    sectionImageImage: '',
  };

  const defaultImages = {
    sectionImage:
      selectedServiceDetailsSection.sectionImage &&
      ROOT_URL + selectedServiceDetailsSection.sectionImage.url,
  };

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Case Study Section Content</h5>
            <h5 className="subhead">Edit Content</h5>
          </div>
          <div>
            <Formik
              enableReinitialize
              initialValues={initValues}
              validationSchema={Yup.object({
                description: Yup.string().required('Required!'),
              })}
              onSubmit={(values) => {
                if (sectionId)
                  dispatch(
                    updateServiceDetailsSection({
                      serviceId,
                      sectionId,
                      values,
                    })
                  );
                else dispatch(addNewServiceDetailsSection({ id, values }));
              }}
            >
              {({ setFieldValue, values, touched, errors }) => (
                <Form className="form form--horizontal text-left">
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Heading
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        name="heading"
                        value={values.heading}
                        touched={touched.heading}
                        error={errors.heading}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Sub Heading
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        name="subheading"
                        value={values.subheading}
                        touched={touched.subheading}
                        error={errors.subheading}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Description
                    </span>
                    <div className="form__form-group-field col-lg-8">
                      <FormikTextEditor
                        name="description"
                        onChange={setFieldValue}
                        defaultValue={selectedServiceDetailsSection.description}
                        error={errors.description}
                        touched={touched.description}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Section Image
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikDropZoneField
                        name="sectionImage"
                        value={values.sectionImage}
                        setFieldValue={setFieldValue}
                        defaultImage={defaultImages.sectionImage}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3">
                      <button
                        className="btn btn-primary btn-sm"
                        type="submit"
                        disabled={isLoading}
                      >
                        {serviceId
                          ? 'Update Service Section'
                          : 'Add Service Section'}
                      </button>
                    </span>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SectionForm;

import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Card, CardBody, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

// COMPONENTS & UTILITIES
import FormikModernField from '../../../../../../shared/components/form/FormikModernField';
import FormikModernDropZone from '../../../../../../shared/components/form/FormikModernDropZone';
import SubmitBtn from '../../../../../../shared/components/form/SubmitBtn';
import {
  infoCardFormInitValues,
  infoCardFormValSchema,
} from '../utilities/formUtils';
import {
  addInfoCardAction,
  getInfoCardAction,
  updateInfoCardAction,
} from '../../../../../../redux/actions/homeActions';
import { getImageUrl } from '../../../../../../utilities/helpers';

function AddEditInfoCard() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const [initValues, setInitValues] = useState(infoCardFormInitValues);
  const fetchedData = useSelector((state) => state.homePage.fetchedInfoCard);

  useEffect(() => {
    if (id) {
      dispatch(getInfoCardAction(id));
    }
  }, []);

  useEffect(() => {
    if (Object.values(fetchedData).length > 0) {
      setInitValues({
        ...initValues,
        title: fetchedData.title,
        subTitle: fetchedData.subTitle,
      });
    }
  }, [fetchedData]);

  const defaultImg =
    fetchedData && fetchedData.icon ? getImageUrl(fetchedData.icon.url) : '';

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Manage Content</h5>
          </div>

          <Formik
            enableReinitialize
            initialValues={initValues}
            validationSchema={infoCardFormValSchema}
            onSubmit={(values) => {
              if (id && fetchedData) {
                dispatch(updateInfoCardAction(values, id));
              } else {
                dispatch(addInfoCardAction(values));
              }
            }}
          >
            {() => (
              <Form className="form form--horizontal">
                <FormikModernField name="title" label="Title" />

                <FormikModernField name="subTitle" label="Sub-Title" />

                <FormikModernDropZone
                  name="icon"
                  label="Image"
                  defaultImage={defaultImg}
                />

                <div className="row mx-0 mt-3">
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={() => history.goBack()}
                  >
                    Back
                  </button>

                  <div>
                    <SubmitBtn isSingle />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </Col>
  );
}

export default AddEditInfoCard;

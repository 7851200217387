import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { Card, CardBody, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS & UTILITIES
import FormikModernField from '../../../../../../shared/components/form/FormikModernField';
import FormikModernDropZone from '../../../../../../shared/components/form/FormikModernDropZone';
import SubmitBtn from '../../../../../../shared/components/form/SubmitBtn';
import {
  faqSectionFormInitialValues,
  faqSectionFormValidationSchema,
} from '../utilities/formUtils';
import FaqsTable from './FaqsTable';
import { getImageUrl } from '../../../../../../utilities/helpers';
import { saveWhyChooseSectionData } from '../../../../../../redux/actions/homeActions';

function SectionCard() {
  const dispatch = useDispatch();

  const [initValues, setInitValues] = useState(faqSectionFormInitialValues);
  const { content } = useSelector((state) => state.homePage.whyChooseSectionData);

  useEffect(() => {
    if (content) {
      setInitValues({
        ...initValues,
        heading: content.heading || '',
      });
    }
  }, [content]);

  const defaultImage =
    content && content.sectionImage
      ? getImageUrl(content.sectionImage.url)
      : '';

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Why Choose Us Section Content</h5>
            <h5 className="subhead">Manage Content</h5>
          </div>

          <div>
            <Formik
              enableReinitialize
              initialValues={initValues}
              validationSchema={faqSectionFormValidationSchema}
              onSubmit={(values) => {
                dispatch(saveWhyChooseSectionData(values));
              }}
            >
              {() => (
                <Form className="form form--horizontal">
                  <FormikModernField
                    label="Heading"
                    name="heading"
                    placeholder="Why choose us"
                  />

                  <FormikModernDropZone
                    name="sectionImage"
                    label="Section Image"
                    defaultImage={defaultImage}
                  />

                  <SubmitBtn />
                </Form>
              )}
            </Formik>

            <FaqsTable />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}

export default SectionCard;

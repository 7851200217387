import React, { useEffect } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { FormikField } from '../../../../../../shared/components/form/Field';
import { FormikDropZoneField } from '../../../../../../shared/components/form/DropZone';
import FormikTextEditor from '../../../../../../shared/components/form/FormikTextEditor';
import FormikDatePicker from '../../../../../../shared/components/form/FormikDatePicker';
import {
  clearCaseStudyData,
  getSingleCaseStudy,
  addNewCaseStudy,
  updateCaseStudyData,
} from '../../../../../../redux/actions/caseStudyActions';
import DetailPageMetaDataForm from '../../../../../Common/DetailPageMetaDataForm';
import { ROOT_URL } from '../../../../../../shared/helpers/constants';

const CaseStudyForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { selectedCaseStudy } = useSelector((state) => state.caseStudy);
  const { isLoading } = useSelector((state) => state.uiState);

  useEffect(() => {
    if (id) dispatch(getSingleCaseStudy(id));
    return () => {
      dispatch(clearCaseStudyData());
    };
  }, []);

  const initValues = {
    companyName:
      (selectedCaseStudy.company && selectedCaseStudy.company.name) || '',
    companyDescription:
      (selectedCaseStudy.company && selectedCaseStudy.company.description) ||
      '',
    slug: selectedCaseStudy.slug || '',
    objective: selectedCaseStudy.objective || '',
    createdAt: selectedCaseStudy.createdAt || '',
    wireframeDescription:
      (selectedCaseStudy.wireframe &&
        selectedCaseStudy.wireframe.description) ||
      '',
    utilizingDescription:
      (selectedCaseStudy.utilizing &&
        selectedCaseStudy.utilizing.description) ||
      '',
    bringingItAllDescription:
      (selectedCaseStudy.bringingitall &&
        selectedCaseStudy.bringingitall.description) ||
      '',
    companyLogo: '',
    caseStudyImage: '',
    wireframeImage: '',
    utilizingImage: '',
    bringingItAllImage: '',
    metaTitle: selectedCaseStudy.metaTitle || '',
    metaDescription: selectedCaseStudy.metaDescription || '',
    metaKeywords: selectedCaseStudy.metaKeywords || '',
  };

  const defaultImages = {
    companyLogo:
      selectedCaseStudy.company &&
      selectedCaseStudy.company.logo &&
      ROOT_URL + selectedCaseStudy.company.logo.url,
    caseStudyImage:
      selectedCaseStudy.caseStudyImage &&
      ROOT_URL + selectedCaseStudy.caseStudyImage.url,
    wireframeImage:
      selectedCaseStudy.wireframe &&
      selectedCaseStudy.wireframe.image &&
      ROOT_URL + selectedCaseStudy.wireframe.image.url,
    utilizingImage:
      selectedCaseStudy.utilizing &&
      selectedCaseStudy.utilizing.image &&
      ROOT_URL + selectedCaseStudy.utilizing.image.url,
    bringingItAllImage:
      selectedCaseStudy.bringingitall &&
      selectedCaseStudy.bringingitall.image &&
      ROOT_URL + selectedCaseStudy.bringingitall.image.url,
  };

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Case Study Section Content</h5>
            <h5 className="subhead">Edit Content</h5>
          </div>
          <div>
            <Formik
              enableReinitialize
              initialValues={initValues}
              validationSchema={Yup.object({
                companyName: Yup.string().required('Required!'),
                slug: Yup.string()
                  .required('Required!')
                  .matches(
                    /^[A-Za-z0-9-]+$/,
                    'Only Alphabets and (-) are allowed'
                  ),
                companyDescription: Yup.string().required('Required!'),
                objective: Yup.string().required('Required!'),
                wireframeDescription: Yup.string().required('Required!'),
                utilizingDescription: Yup.string().required('Required!'),
                bringingItAllDescription: Yup.string().required('Required!'),
              })}
              onSubmit={(values) => {
                if (id) dispatch(updateCaseStudyData({ id, values }));
                else dispatch(addNewCaseStudy(values));
              }}
            >
              {({ setFieldValue, values, touched, errors }) => (
                <Form className="form form--horizontal text-left">
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Company Name
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        name="companyName"
                        value={values.companyName}
                        touched={touched.companyName}
                        error={errors.companyName}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Slug
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <div className="form__form-group-icon w-25">
                        /case-study/
                      </div>
                      <FormikField
                        name="slug"
                        value={values && values.slug}
                        touched={touched && touched.slug}
                        error={errors && errors.slug}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-md-3 text-nowrap required">
                      Created At
                    </span>
                    <div className="form__form-group-field col-md-5">
                      <FormikDatePicker
                        name="createdAt"
                        value={values.createdAt}
                        onChange={setFieldValue}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Company Description
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        textArea
                        name="companyDescription"
                        value={values.companyDescription}
                        touched={touched.companyDescription}
                        error={errors.companyDescription}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Company Logo
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikDropZoneField
                        name="companyLogo"
                        value={values.companyLogo}
                        setFieldValue={setFieldValue}
                        defaultImage={defaultImages.companyLogo}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Case Study Image
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikDropZoneField
                        name="caseStudyImage"
                        value={values.caseStudyImage}
                        setFieldValue={setFieldValue}
                        defaultImage={defaultImages.caseStudyImage}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Objective
                    </span>
                    <div className="form__form-group-field col-lg-8">
                      <FormikTextEditor
                        name="objective"
                        onChange={setFieldValue}
                        defaultValue={selectedCaseStudy.objective}
                        error={errors.objective}
                        touched={touched.objective}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Wireframe Description
                    </span>
                    <div className="form__form-group-field col-lg-8">
                      <FormikTextEditor
                        name="wireframeDescription"
                        onChange={setFieldValue}
                        defaultValue={
                          selectedCaseStudy.wireframe &&
                          selectedCaseStudy.wireframe.description
                        }
                        error={errors.wireframeDescription}
                        touched={touched.wireframeDescription}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      WireFrame Image
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikDropZoneField
                        name="wireframeImage"
                        value={values.wireframeImage}
                        setFieldValue={setFieldValue}
                        defaultImage={defaultImages.wireframeImage}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Utlizing Description
                    </span>
                    <div className="form__form-group-field col-lg-8">
                      <FormikTextEditor
                        name="utilizingDescription"
                        onChange={setFieldValue}
                        defaultValue={
                          selectedCaseStudy.utilizing &&
                          selectedCaseStudy.utilizing.description
                        }
                        error={errors.utilizingDescription}
                        touched={touched.utilizingDescription}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Utilizing Image
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikDropZoneField
                        name="utilizingImage"
                        value={values.utilizingImage}
                        setFieldValue={setFieldValue}
                        defaultImage={defaultImages.utilizingImage}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Bringing It All Description
                    </span>
                    <div className="form__form-group-field col-lg-8">
                      <FormikTextEditor
                        name="bringingItAllDescription"
                        onChange={setFieldValue}
                        defaultValue={
                          selectedCaseStudy.bringingitall &&
                          selectedCaseStudy.bringingitall.description
                        }
                        error={errors.bringingItAllDescription}
                        touched={touched.bringingItAllDescription}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Bringing It All Image
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikDropZoneField
                        name="bringingItAllImage"
                        value={values.bringingItAllImage}
                        setFieldValue={setFieldValue}
                        defaultImage={defaultImages.bringingItAllImage}
                      />
                    </div>
                  </div>
                  {/* For Detail Page Meta Data */}
                  <DetailPageMetaDataForm />
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3">
                      <button
                        className="btn btn-primary btn-sm"
                        type="submit"
                        disabled={isLoading}
                      >
                        Save Case Study
                      </button>
                    </span>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CaseStudyForm;

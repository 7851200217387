import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { useSelector } from 'react-redux';

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

const ProfileMain = () => {
  const { user } = useSelector((state) => state.auth);

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody className="profile__card">
          <div className="profile__information">
            <div className="profile__avatar">
              <img src={Ava} alt="avatar" />
            </div>
            <div className="profile__data">
              <p className="profile__name">{user && user.name}</p>
              <p className="profile__contact">{user && user.email}</p>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ProfileMain;

import toastify from '../../shared/helpers/toastNotification';
import createAsyncAction from '../../shared/helpers/createAsyncAction';
import { getHeaderData,saveHeaderData } from '../../services/headerSectionServices';

export const GET_HEADER_DATA = 'GET_HEADER_DATA';
export const CLEAR_HEADER_DATA = 'CLEAR_HEADER_DATA';

export const clearHeaderSectionData = () => ({ type: CLEAR_HEADER_DATA });

export const getHeaderSectionData = createAsyncAction(getHeaderData, (res, dispatch) => {
    dispatch({ type: GET_HEADER_DATA, payload: res.data });
});

export const saveHeaderSectionData = createAsyncAction(saveHeaderData, (res) => {
    toastify(res.data.message, 'success');
});
import {
  CLEAR_HOME_DATA,
  DELETE_HOME_PORTFOLIO_IMAGE,
  GET_HOME_BANNER_DATA,
  GET_HOME_META_DATA,
  GET_HOME_WHAT_WE_DO_DATA,
  GET_HOME_WHO_WE_ARE_DATA,
  GET_HOME_PORTFOLIO_DATA,
  GET_HOME_CASE_STUDY_DATA,
  GET_HOME_HAPPY_CLIENTS_DATA,
  GET_SINGLE_CLIENT_DATA,
  DELETE_HAPPY_CLIENT,
  SAVE_HOME_SECTION_HEADING,
  GET_HOME_SECTION_DATA,
  ADD_PRODUCT_SERVICE,
  GET_SINGLE_PRODUCT_SERVICE,
  DELETE_PRODUCT_SERVICE,
  GET_WHY_CHOOSE_DATA,
  SAVE_WHY_CHOOSE_DATA,
  DELETE_WHY_CHOOSE_FAQ,
  ADD_WHY_CHOOSE_FAQ,
  GET_WHY_CHOOSE_FAQ,
  GET_INFO_CARDS,
  DELETE_INFO_CARD,
  ADD_INFO_CARD,
  GET_SINGLE_INFO_CARD,
  SAVE_FAQ_SECTION_DATA,
  GET_FAQ_SECTION_DATA,
  DELETE_FAQ,
  GET_FAQ,
} from '../actions/homeActions';

const initialState = {
  homeMetData: {},
  bannerSectionData: {},
  whatWeDoSectionData: {},
  whoWeAreSectionData: {},
  portfolioSectionData: {},
  caseStudySectionData: {},
  happyClientsSectionData: [],
  selectedClientData: {},
  productServicesSectionData: {
    heading: '',
    productsServices: [],
    fetched: {},
  },
  whyChooseSectionData: {
    content: {
      heading: '',
      sectionImage: null,
    },
    faqsData: [],
    fetched: {},
  },
  infoCardSectionData: [],
  fetchedInfoCard: {},
  faqsSectionData: {
    heading: '',
    faqs: [],
    fetched: {},
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CLEAR_HOME_DATA:
      return {
        ...state,
        homeMetaData: {},
        bannerSectionData: {},
        whatWeDoSectionData: {},
        whoWeAreSectionData: {},
        portfolioSectionData: {},
        caseStudySectionData: {},
        happyClientsSectionData: [],
        selectedClientData: {},
      };
    case GET_HOME_META_DATA:
      return {
        ...state,
        homeMetaData: action.payload,
      };
    case GET_HOME_BANNER_DATA:
      return {
        ...state,
        bannerSectionData: action.payload,
      };
    case GET_HOME_WHAT_WE_DO_DATA:
      return {
        ...state,
        whatWeDoSectionData: action.payload,
      };
    case GET_HOME_WHO_WE_ARE_DATA:
      return {
        ...state,
        whoWeAreSectionData: action.payload,
      };
    case GET_HOME_PORTFOLIO_DATA:
      return {
        ...state,
        portfolioSectionData: action.payload,
      };
    case DELETE_HOME_PORTFOLIO_IMAGE:
      return {
        ...state,
        portfolioSectionData: {
          ...state.portfolioSectionData,
          images: state.portfolioSectionData.images.filter(
            (image) => image._id !== action.payload.id
          ),
        },
      };
    case GET_HOME_CASE_STUDY_DATA:
      return {
        ...state,
        caseStudySectionData: action.payload,
      };
    case GET_HOME_HAPPY_CLIENTS_DATA:
      return {
        ...state,
        happyClientsSectionData: action.payload,
      };
    case GET_SINGLE_CLIENT_DATA:
      return {
        ...state,
        selectedClientData: action.payload,
      };
    case DELETE_HAPPY_CLIENT:
      return {
        ...state,
        happyClientsSectionData: state.happyClientsSectionData.filter(
          (client) => client._id !== action.payload.id
        ),
      };

    case SAVE_HOME_SECTION_HEADING:
      return {
        ...state,
        productServicesSectionData: {
          ...state.productServicesSectionData,
          heading: action.payload.heading,
        },
      };

    case GET_HOME_SECTION_DATA:
      return {
        ...state,
        productServicesSectionData: {
          heading:
            action.payload.sectionData && action.payload.sectionData.heading,
          productsServices: action.payload.productsServices,
        },
      };

    case ADD_PRODUCT_SERVICE:
      return {
        ...state,
        productServicesSectionData: {
          heading: state.productServicesSectionData.heading || '',
          productsServices: [
            ...(state.productServicesSectionData.productsServices || []),
            action.payload,
          ],
        },
      };

    case GET_SINGLE_PRODUCT_SERVICE:
      return {
        ...state,
        productServicesSectionData: {
          ...state.productServicesSectionData,
          fetched: action.payload,
        },
      };

    case DELETE_PRODUCT_SERVICE:
      return {
        ...state,
        productServicesSectionData: {
          ...state.productServicesSectionData,
          productsServices:
            state.productServicesSectionData.productsServices.filter(
              (item) => item._id !== action.payload.id
            ),
        },
      };

    case GET_WHY_CHOOSE_DATA:
      return {
        ...state,
        whyChooseSectionData: {
          content: action.payload.sectionData,
          faqsData: action.payload.faqs,
        },
      };

    case SAVE_WHY_CHOOSE_DATA:
      return {
        ...state,
        whyChooseSectionData: {
          ...state.whyChooseSectionData,
          content: action.payload,
        },
      };

    case DELETE_WHY_CHOOSE_FAQ:
      return {
        ...state,
        whyChooseSectionData: {
          ...state.whyChooseSectionData,
          faqsData: state.whyChooseSectionData.faqsData.filter(
            (item) => item._id !== action.payload.id
          ),
        },
      };

    case ADD_WHY_CHOOSE_FAQ:
      return {
        ...state,
        whyChooseSectionData: {
          ...state.whyChooseSectionData,
          faqsData: [...state.whyChooseSectionData.faqsData, action.payload],
        },
      };

    case GET_WHY_CHOOSE_FAQ:
      return {
        ...state,
        whyChooseSectionData: {
          ...state.whyChooseSectionData,
          fetched: action.payload,
        },
      };

    case GET_INFO_CARDS:
      return {
        ...state,
        infoCardSectionData: action.payload,
      };

    case DELETE_INFO_CARD:
      return {
        ...state,
        infoCardSectionData: state.infoCardSectionData.filter(
          (item) => item._id !== action.payload.id
        ),
      };

    case ADD_INFO_CARD:
      return {
        ...state,
        infoCardSectionData: [...state.infoCardSectionData, action.payload],
      };

    case GET_SINGLE_INFO_CARD:
      return {
        ...state,
        fetchedInfoCard: action.payload,
      };

    case SAVE_FAQ_SECTION_DATA:
      return {
        ...state,
        faqsSectionData: {
          ...state.faqsSectionData,
          heading: action.payload.heading,
        },
      };

    case GET_FAQ_SECTION_DATA:
      return {
        ...state,
        faqsSectionData: {
          ...state.faqsSectionData,
          heading: action.payload.sectionData.heading,
          faqs: action.payload.faqs,
        },
      };

    case DELETE_FAQ:
      return {
        ...state,
        faqsSectionData: {
          ...state.faqsSectionData,
          faqs: state.faqsSectionData.faqs.filter(
            (item) => item._id !== action.payload.id
          ),
        },
      };

    case GET_FAQ:
      return {
        ...state,
        faqsSectionData: {
          ...state.faqsSectionData,
          fetched: action.payload,
        },
      };

    default:
      return state;
  }
}

import { combineReducers, createStore, applyMiddleware } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxThunk from 'redux-thunk';
import {
  sidebarReducer,
  themeReducer,
  rtlReducer,
  uiReducer,
  homeReducer,
  serviceReducer,
  clientCardReducer,
  aboutReducer,
  portfolioReducer,
  contactReducer,
  blogsReducer,
  caseStudyReducer,
  authReducer,
  headerReducer,
  footerReducer,
  productsReducer,
} from '../../redux/reducers/index';
import { getDispatch } from '../../shared/helpers/toastNotification';

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  sidebar: sidebarReducer,
  rtl: rtlReducer,
  uiState: uiReducer,
  auth: authReducer,
  homePage: homeReducer,
  clientCard: clientCardReducer,
  servicesPage: serviceReducer,
  productsData: productsReducer,
  aboutPage: aboutReducer,
  portfolioPage: portfolioReducer,
  blogsPage: blogsReducer,
  caseStudy: caseStudyReducer,
  contactPage: contactReducer,
  header: headerReducer,
  footer: footerReducer,
});

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(reduxThunk))
);

getDispatch(store.dispatch);

export default store;

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import ProtectedRoute from './ProtectedRoute';

import LogIn from '../LogIn/index';
import Dashboard from '../Dashboard';
import ContactForms from '../WebsiteContactForms';
import WebsiteHeader from '../WebsiteWidgets/Header';
import WebsiteFooter from '../WebsiteWidgets/Footer';
import Profile from '../Profile';
import NotFound404 from '../NotFound';

import HomeBannerSection from '../WebsitePages/HomePage/Sections/Banner';
import HomeWhatWeDoSection from '../WebsitePages/HomePage/Sections/WhatWeDo';
import HomeWhoWeAreSection from '../WebsitePages/HomePage/Sections/WhoWeAre';
import HomePortfolioSection from '../WebsitePages/HomePage/Sections/Portfolio';
import HomeCaseStudySection from '../WebsitePages/HomePage/Sections/CaseStudy';
import HomeHappyClientsSection from '../WebsitePages/HomePage/Sections/HappyClients';
import HomeMetaDataSection from '../WebsitePages/HomePage/Sections/MetData';
import ProductServicesSection from '../WebsitePages/HomePage/Sections/ExploreOurOfferings';
// eslint-disable-next-line max-len
import AddEditProductService from '../WebsitePages/HomePage/Sections/ExploreOurOfferings/components/AddEditFormProductServiceForm';

import CaseStudyBannerSection from '../WebsitePages/CaseStudyPage/Sections/Banner';
import CaseStudies from '../WebsitePages/CaseStudyPage/Sections/CaseStudies';
import CaseStudiesMetaDataSection from '../WebsitePages/CaseStudyPage/Sections/MetData';

import ServiceBannerSection from '../WebsitePages/ServicesPage/Sections/Banner';
import Services from '../WebsitePages/ServicesPage/Sections/Services';
import ServicesMetaDataSection from '../WebsitePages/ServicesPage/Sections/MetData';

import BlogBannerSection from '../WebsitePages/BlogPage/Sections/Banner';
import BlogTrendingSection from '../WebsitePages/BlogPage/Sections/Trending';
import Blogs from '../WebsitePages/BlogPage/Sections/Blogs';
import BlogsMetaDataSection from '../WebsitePages/BlogPage/Sections/MetData';

import AboutBannerSection from '../WebsitePages/AboutPage/Sections/Banner';
import AboutDetailsSection from '../WebsitePages/AboutPage/Sections/AboutDetails';
import AboutPerformanceSection from '../WebsitePages/AboutPage/Sections/Performance';
import AboutMetaDataSection from '../WebsitePages/AboutPage/Sections/MetData';

import ContactBannerSection from '../WebsitePages/ContactPage/Sections/Banner';
import ContactCardSection from '../WebsitePages/ContactPage/Sections/ContactCard';
import ContactMetaDataSection from '../WebsitePages/ContactPage/Sections/MetData';
import HomeFaqSection from '../WebsitePages/HomePage/Sections/Faqs';
import AddEditFaq from '../WebsitePages/HomePage/Sections/Faqs/components/AddEditFaq';
import InfoCards from '../WebsitePages/HomePage/Sections/InfoCards';
import AddEditInfoCard from '../WebsitePages/HomePage/Sections/InfoCards/components/AddEditInfoCard';
import GetStartedSection from '../WebsitePages/HomePage/Sections/GetStarted';
import AddEditFaqForm from '../WebsitePages/HomePage/Sections/GetStarted/components/AddEditFaqForm';
import Products from '../WebsiteWidgets/Products';
import AddEditProductsForm from '../WebsiteWidgets/Products/components/AddEditProductsForm';

const Pages = () => (
  <Switch>
    <Route path="/widgets/header" component={WebsiteHeader} />
    <Route path="/widgets/footer" component={WebsiteFooter} />

    <Route path="/pages/home/banner" component={HomeBannerSection} />
    <Route path="/pages/home/what-we-do" component={HomeWhatWeDoSection} />
    <Route path="/pages/home/who-we-are" component={HomeWhoWeAreSection} />
    <Route
      path="/pages/home/product-services/edit/:id"
      component={AddEditProductService}
    />
    <Route
      path="/pages/home/product-services/add"
      component={AddEditProductService}
    />
    <Route
      path="/pages/home/product-services"
      component={ProductServicesSection}
    />
    <Route path="/pages/home/why-choose-us/add" component={AddEditFaq} />
    <Route path="/pages/home/why-choose-us/edit/:id" component={AddEditFaq} />
    <Route path="/pages/home/why-choose-us" component={HomeFaqSection} />
    <Route path="/pages/home/faqs/add" component={AddEditFaqForm} />
    <Route path="/pages/home/faqs/edit/:id" component={AddEditFaqForm} />
    <Route path="/pages/home/faqs" component={GetStartedSection} />

    <Route path="/pages/home/portfolio" component={HomePortfolioSection} />
    <Route path="/pages/home/case-study" component={HomeCaseStudySection} />
    <Route path="/pages/home/info-cards/edit/:id" component={AddEditInfoCard} />
    <Route path="/pages/home/info-cards/add" component={AddEditInfoCard} />
    <Route path="/pages/home/info-cards" component={InfoCards} />
    <Route
      path="/pages/home/happy-clients"
      component={HomeHappyClientsSection}
    />
    <Route path="/pages/home/metaData" component={HomeMetaDataSection} />

    <Route path="/pages/case-study/banner" component={CaseStudyBannerSection} />
    <Route path="/pages/case-study/all-case-studies" component={CaseStudies} />
    <Route
      path="/pages/case-study/metaData"
      component={CaseStudiesMetaDataSection}
    />

    <Route path="/pages/services/banner" component={ServiceBannerSection} />
    <Route path="/pages/services/all-services" component={Services} />
    <Route
      path="/pages/services/metaData"
      component={ServicesMetaDataSection}
    />

    <Route path="/pages/products/edit/:id" component={AddEditProductsForm} />
    <Route path="/pages/products/add" component={AddEditProductsForm} />
    <Route path="/pages/products" component={Products} />

    <Route path="/pages/blog/banner" component={BlogBannerSection} />
    <Route path="/pages/blog/trending" component={BlogTrendingSection} />
    <Route path="/pages/blog/all-blogs" component={Blogs} />
    <Route path="/pages/blog/metaData" component={BlogsMetaDataSection} />

    <Route path="/pages/about/banner" component={AboutBannerSection} />
    <Route path="/pages/about/about-details" component={AboutDetailsSection} />
    <Route
      path="/pages/about/about-performance"
      component={AboutPerformanceSection}
    />
    <Route path="/pages/about/metaData" component={AboutMetaDataSection} />

    <Route path="/pages/contact/banner" component={ContactBannerSection} />
    <Route path="/pages/contact/contact-card" component={ContactCardSection} />
    <Route path="/pages/contact/metaData" component={ContactMetaDataSection} />

    <Route path="/pages/contactForms" component={ContactForms} />
    <Route path="/pages/profile" component={Profile} />
    <Route path="/pages/dashboard" component={Dashboard} />
    <Redirect from="/pages" to="/pages/dashboard" />
    <Redirect to="/not-found" />
  </Switch>
);

const wrappedRoutes = () => {
  const { isLoading } = useSelector((state) => state.uiState);
  return (
    <div>
      <Layout />
      {isLoading && (
        <div className={`load${isLoading ? '' : ' loaded'}`}>
          <div className="load__icon-wrap">
            <svg className="load__icon">
              <path
                fill="#3EA3FC"
                d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
              />
            </svg>
          </div>
        </div>
      )}
      <div className="container__wrap">
        <Pages />
      </div>
    </div>
  );
};

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route path="/not-found" component={NotFound404} />
        <Route exact path="/" component={LogIn} />
        <ProtectedRoute path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;

import http, { multipartConfig } from './httpService';

export const getPortfolioPageData = () => http.get('/portfolio');

export const savePortfolioPageData = (values) => http.post('/portfolio', values);

export const getPortfolioImages = () => http.get('/portfolio/images');

export const savePortfolioImages = (values) => {
  const formData = new FormData();
  values.image.forEach((image) => {
    formData.append('image', image);
  });

  return http.post('/portfolio/images', formData, multipartConfig);
};

export const deletePortfolioImage = (id) => http.delete(`/portfolio/images/${id}`);

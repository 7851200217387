import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Table, Badge, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Modal from '../../../../../../shared/components/Modal';
import {
  getAllBlogs,
  deleteBlog,
} from '../../../../../../redux/actions/blogsActions';
import { ROOT_URL } from '../../../../../../shared/helpers/constants';

const SectionCard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { blogs } = useSelector((state) => state.blogsPage);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(false);

  useEffect(() => {
    dispatch(getAllBlogs());
  }, []);

  const toggleDeleteModal = () => setShowDeleteModal((prevState) => !prevState);

  const handleDelete = () => {
    dispatch(deleteBlog(selectedId));
  };

  return (
    <Col md={12}>
      <Modal
        showModal={showDeleteModal}
        toggle={toggleDeleteModal}
        color="warning"
        title="Attention!"
        message="Are you sure, you want to delete this card?"
        onSuccess={handleDelete}
      />
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between">
            <div className="card__title">
              <h5 className="bold-text">Blogs Content</h5>
              <h5 className="subhead">Edit Content</h5>
            </div>
            <div>
              <Button
                color="primary"
                className="btn btn-sm"
                onClick={() => history.push('/pages/blog/all-blogs/add')}
              >
                + Add New Blog
              </Button>
            </div>
          </div>
          <div>
            <Table responsive hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th> Image</th>
                  <th className="w-25">Title</th>
                  <th className="w-25">Author</th>
                  <th className="w-25">Created At</th>
                  <th className="w-25 text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {blogs.length > 0 &&
                  blogs.map((item, index) => (
                    <tr key={item._id}>
                      <td>{index + 1}</td>
                      <td>
                        <img
                          src={ROOT_URL + item.blogImage.url}
                          alt="img"
                          style={{ height: 70, width: 90 }}
                        />
                      </td>
                      <td className="w-25">{item.title}</td>
                      <td className="w-25">{item.author}</td>
                      <td className="w-25">
                        {new Date(item.createdAt).toDateString()}
                      </td>
                      <td className="w-25 text-center d-inline-flex">
                        <Badge
                          role="button"
                          color="secondary"
                          type="button"
                          className="mr-1"
                          onClick={() => {
                            history.push(
                              `/pages/blog/all-blogs/${item._id}/edit`
                            );
                          }}
                        >
                          Edit
                        </Badge>
                        <Badge
                          role="button"
                          color="danger"
                          type="button"
                          className="mr-1"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedId(item._id);
                            toggleDeleteModal();
                          }}
                        >
                          Delete
                        </Badge>
                        <Badge
                          role="button"
                          color="success"
                          type="button"
                          onClick={() => {
                            history.push(
                              `/pages/blog/all-blogs/${item._id}/comments`
                            );
                          }}
                        >
                          Comments
                        </Badge>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SectionCard;

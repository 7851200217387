import React, { useEffect } from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { FormikDropZoneField } from '../../../../shared/components/form/DropZone';
import {
  getHeaderSectionData,
  saveHeaderSectionData,
  clearHeaderSectionData
} from '../../../../redux/actions/headerActions';
import { ROOT_URL } from '../../../../shared/helpers/constants';
import '../../../../scss/containers/header.scss';

const HeaderCard = () => {
  const dispatch = useDispatch();
  const { headerData } = useSelector((state) => state.header);
  const { isLoading } = useSelector((state) => state.uiState);

  useEffect(() => {
    dispatch(getHeaderSectionData());
    return () => {
      dispatch(clearHeaderSectionData());
    };
  }, []);

  const initValues = {
    headerWhiteLogo: '',
    headerDarkLogo: '',
  };

  const defaultImages = {
    headerWhiteLogo:
      headerData.headerWhiteLogo && ROOT_URL + headerData.headerWhiteLogo.url,
    headerDarkLogo:
      headerData.headerDarkLogo && ROOT_URL + headerData.headerDarkLogo.url,
  };

  return (
    <Col md={12}>
      <Card className="header-section-card">
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Website Header</h5>
            <h5 className="subhead">Edit Content</h5>
          </div>
          <div className="w-75">
            <Formik
              initialValues={initValues}
              onSubmit={(values) => {
                dispatch(saveHeaderSectionData(values));
              }}
            >
              {({ values, setFieldValue }) => (
                <Form className="form form--horizontal text-left">
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Header White Logo
                    </span>
                    <div className="form__form-group-field col-lg-7">
                      <FormikDropZoneField
                        name="headerWhiteLogo"
                        value={values.headerWhiteLogo}
                        setFieldValue={setFieldValue}
                        defaultImage={defaultImages.headerWhiteLogo}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Header Dark Logo
                    </span>
                    <div className="form__form-group-field col-lg-7">
                      <FormikDropZoneField
                        name="headerDarkLogo"
                        value={values.headerDarkLogo}
                        setFieldValue={setFieldValue}
                        defaultImage={defaultImages.headerDarkLogo}
                      />
                    </div>
                  </div>
                  <button className="btn btn-primary btn-sm" type="submit" disabled={isLoading}>
                    Save
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}; 

export default HeaderCard;

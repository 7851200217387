import http, { multipartConfig } from './httpService';

export const getFooterData = () => {
    return http.get('/footer');
}

export const saveFooterData = (values) => {
    const data = { ...values };

    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
        if (typeof value === 'string') {
            if (value) {
                formData.append(key, value);
            }
        }
    });

    Object.entries(data).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((item) => {
                formData.append(key, item);
            });
        }
    });

    return http.post('/footer', formData, multipartConfig);
};
import React, { useEffect } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { FormikField } from '../../../../../../shared/components/form/Field';
import {
  getSingleComment,
  postNewReply,
  updateComment,
  clearBlogData,
} from '../../../../../../redux/actions/blogsActions';

const DEFAULT_USERNAME = 'admin';
const DEFAULT_EMAIL = 'admin@beyonderissolutions.com';

const BlogForm = () => {
  const dispatch = useDispatch();
  // Get current path
  const isEditForm = window.location.pathname.split('/').pop() === 'edit';
  const { blogID, commentID } = useParams();
  const { selectedCommentData } = useSelector((state) => state.blogsPage);
  const { isLoading } = useSelector((state) => state.uiState);

  useEffect(() => {
    if (isEditForm) dispatch(getSingleComment(commentID));
    return () => {
      dispatch(clearBlogData());
    };
  }, []);

  const initValues = {
    username: selectedCommentData.username || DEFAULT_USERNAME,
    email: selectedCommentData.email || DEFAULT_EMAIL,
    comment: selectedCommentData.comment || '',
  };

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Comment Form</h5>
            <h5 className="subhead">Edit Content</h5>
          </div>
          <div>
            <Formik
              enableReinitialize
              initialValues={initValues}
              validationSchema={Yup.object({
                username: Yup.string().required('Required!'),
                email: Yup.string().email().required('Required!'),
                comment: Yup.string().required('Required!'),
              })}
              onSubmit={(data) => {
                if (isEditForm) dispatch(updateComment({ commentID, data }));
                else dispatch(postNewReply({ blogID, commentID, data }));
              }}
            >
              {({ values, touched, errors }) => (
                <Form className="form form--horizontal text-left">
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Username
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        name="username"
                        value={values.username}
                        touched={touched.username}
                        error={errors.username}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Email
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        name="email"
                        value={values.email}
                        touched={touched.email}
                        error={errors.email}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Comment
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        textArea
                        name="comment"
                        value={values.comment}
                        touched={touched.comment}
                        error={errors.comment}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3">
                      <button
                        className="btn btn-primary btn-sm"
                        type="submit"
                        disabled={isLoading}
                      >
                        {isEditForm ? 'Update Reply' : 'Reply'}
                      </button>
                    </span>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default BlogForm;

/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { Field } from 'formik';

const MAX_FILE_SIZE = 210000;

class DropZoneMultipleField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    setFieldValue: PropTypes.func,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        })
      ),
    ]).isRequired,
    defaultImages: PropTypes.arrayOf(
      PropTypes.shape({
        link: PropTypes.string,
      })
    ),
    deleteDefaultImage: PropTypes.func,
  };

  static defaultProps = {
    onChange: null,
    setFieldValue: null,
    defaultImages: [],
    deleteDefaultImage: () => {},
  };

  constructor() {
    super();
    this.state = {};
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(file) {
    const { onChange, setFieldValue, name } = this.props;

    if (setFieldValue) {
      setFieldValue(
        name,
        file.map((fl) =>
          Object.assign(fl, {
            preview: URL.createObjectURL(fl),
          })
        )
      );
    } else {
      onChange(
        file.map((fl) =>
          Object.assign(fl, {
            preview: URL.createObjectURL(fl),
          })
        )
      );
    }
  }

  removeFile(index, e) {
    const { onChange, setFieldValue, name, value } = this.props;
    e.preventDefault();
    if (setFieldValue) {
      setFieldValue(
        name,
        value.filter((_, i) => i !== index)
      );
    } else {
      onChange(value.filter((_, i) => i !== index));
    }
  }

  render() {
    const { name, value, defaultImages, deleteDefaultImage } = this.props;
    const files = value;

    return (
      <div className="dropzone dropzone--multiple">
        <Dropzone
          className="dropzone__input"
          accept="image/jpeg, image/png, image/jpg"
          name={name}
          onDrop={(filesToUpload) => {
            this.onDrop(value ? value.concat(filesToUpload) : filesToUpload);
          }}
          maxSize={MAX_FILE_SIZE}
        >
          {({ getRootProps, getInputProps, rejectedFiles }) => (
            <>
              <div {...getRootProps()} className="dropzone__input">
                {(!files || files.length === 0) && (
                  <div className="dropzone__drop-here">
                    <span className="lnr lnr-upload" /> Drop images here to
                    upload
                  </div>
                )}
                <input {...getInputProps()} />
              </div>
              <div className="dropzone__error">
                {rejectedFiles.length > 0 &&
                  rejectedFiles.map((file) => (
                    <span key={file.name} className="form__form-group-error">
                      {file.size > MAX_FILE_SIZE &&
                        `${file.name} is too large. It must be less than or equal to 200 KB`}
                    </span>
                  ))}
              </div>
            </>
          )}
        </Dropzone>
        <div className="dropzone__imgs-wrapper">
          {files &&
            Array.isArray(files) &&
            files.map((file, i) => (
              <div
                className="dropzone__img multi-img"
                key={i}
                style={{ backgroundImage: `url(${file.preview})` }}
              >
                <p className="dropzone__img-name">{file.name}</p>
                <button
                  className="dropzone__img-delete"
                  type="button"
                  onClick={(e) => this.removeFile(i, e)}
                >
                  Remove
                </button>
              </div>
            ))}
          {defaultImages &&
            Array.isArray(defaultImages) &&
            defaultImages.map((image) => (
              <div
                className="dropzone__img multi-img"
                key={image.id}
                style={{ backgroundImage: `url(${image.url})` }}
              >
                <button
                  className="dropzone__img-delete"
                  type="button"
                  onClick={() => deleteDefaultImage(image.id)}
                >
                  Remove
                </button>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export const FormikDropZoneMultiField = ({
  name,
  value,
  setFieldValue,
  defaultImages,
  deleteDefaultImage,
}) => (
  <Field name={name}>
    {(props) => (
      <DropZoneMultipleField
        name={name}
        value={value}
        setFieldValue={setFieldValue}
        defaultImages={defaultImages}
        deleteDefaultImage={deleteDefaultImage}
        {...props}
      />
    )}
  </Field>
);

FormikDropZoneMultiField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
  ]).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  defaultImages: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
    })
  ),
  deleteDefaultImage: PropTypes.func,
};

FormikDropZoneMultiField.defaultProps = {
  defaultImages: [],
  deleteDefaultImage: () => {},
};

const renderDropZoneMultipleField = (props) => {
  const { input } = props;
  return <DropZoneMultipleField {...input} />;
};

renderDropZoneMultipleField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
};

export default renderDropZoneMultipleField;

import {
  GET_ABOUT_META_DATA,
  CLEAR_ABOUT_DATA,
  GET_ABOUT_BANNER_DATA,
  GET_ABOUT_DETAILS_DATA,
  GET_ABOUT_DETAILS_SECTION_DATA,
  GET_ABOUT_PERFORMANCE_DATA,
} from '../actions/aboutActions';

const initialState = {
  bannerSectionData: {},
  aboutDetailsData: {},
  selectedSectionData: {},
  aboutPerformanceData: {},
  aboutMetaData: {},
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case CLEAR_ABOUT_DATA:
      return {
        ...state,
        bannerSectionData: {},
        aboutDetailsData: {},
        selectedSectionData: {},
        aboutPerformanceData: {},
        aboutMetaData: {},
      };
    case GET_ABOUT_META_DATA:
      return {
        ...state,
        aboutMetaData: payload,
      };
    case GET_ABOUT_BANNER_DATA:
      return {
        ...state,
        bannerSectionData: payload,
      };
    case GET_ABOUT_DETAILS_DATA:
      return {
        ...state,
        aboutDetailsData: payload,
      };
    case GET_ABOUT_DETAILS_SECTION_DATA:
      return {
        ...state,
        selectedSectionData: payload,
      };
    case GET_ABOUT_PERFORMANCE_DATA:
      return {
        ...state,
        aboutPerformanceData: payload,
      };
    default:
      return state;
  }
}

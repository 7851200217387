import React, { Component } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import PropTypes from 'prop-types';

const ToolbarOptions = {
  options: [
    'inline',
    'blockType',
    'list',
    'textAlign',
    'link',
    'emoji',
    'image',
    'history',
    'remove',
  ],
  inline: {
    options: ['bold', 'italic', 'underline'],
  },
};

export default class TextEditorTwo extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
  };

  static defaultProps = {
    defaultValue: '',
  };

  constructor() {
    super();
    this.state = {
      editorState: EditorState.createEmpty(),
    };
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { defaultValue } = this.props;

    if (defaultValue && defaultValue !== prevProps.defaultValue) {
      const blocksFromHtml = htmlToDraft(defaultValue);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      this.setState({
        editorState: EditorState.createWithContent(contentState),
      });
    }
  }

  onEditorStateChange = (editorState) => {
    const { onChange, name } = this.props;

    this.setState({
      editorState,
    });
    if (onChange) {
      onChange(
        name,
        draftToHtml(convertToRaw(editorState.getCurrentContent()))
      );
    }
  };

  render() {
    const { editorState } = this.state;
    return (
      <div className="text-editor">
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
          toolbar={ToolbarOptions}
        />
      </div>
    );
  }
}

import React, { useEffect } from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormikField } from '../../../../shared/components/form/Field';
import { FormikDropZoneField } from '../../../../shared/components/form/DropZone';
import {
  getFooterSectionData,
  saveFooterSectionData,
  clearFooterSectionData,
} from '../../../../redux/actions/footerActions';
import { ROOT_URL } from '../../../../shared/helpers/constants';
import '../../../../scss/containers/footer.scss';

const FooterCard = () => {
  const dispatch = useDispatch();
  const { footerData } = useSelector((state) => state.footer);
  const { isLoading } = useSelector((state) => state.uiState);

  useEffect(() => {
    dispatch(getFooterSectionData());
    return () => {
      dispatch(clearFooterSectionData());
    };
  }, []);

  const initValues = {
    heading: footerData.heading || '',
    description: footerData.description || '',
    footerDescription: footerData.footerDescription || '',
    facebookLink: footerData.facebookLink || '',
    twitterLink: footerData.twitterLink || '',
    linkedInLink: footerData.linkedInLink || '',
    instagramLink: footerData.instagramLink || '',
    logo: '',
  };

  const defaultImages = {
    logo: footerData.logo && ROOT_URL + footerData.logo.url,
  };

  return (
    <Col md={12}>
      <Card className="footer-section-card">
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Website Footer</h5>
            <h5 className="subhead">Edit Content</h5>
          </div>
          <Formik
            enableReinitialize
            initialValues={initValues}
            validationSchema={Yup.object({
              heading: Yup.string().required('Required!'),
              description: Yup.string().required('Required!'),
              footerDescription: Yup.string().required('Required!'),
              facebookLink: Yup.string().required('Required!'),
              twitterLink: Yup.string().required('Required!'),
              linkedInLink: Yup.string().required('Required!'),
              instagramLink: Yup.string().required('Required!'),
            })}
            onSubmit={(values) => {
              dispatch(saveFooterSectionData(values));
            }}
          >
            {({ setFieldValue, values, touched, errors }) => (
              <Form className="form form--horizontal text-left">
                <div className="form__form-group">
                  <span className="form__form-group-label col-lg-3 text-nowrap required">
                    Heading
                  </span>
                  <div className="form__form-group-field col-lg-5">
                    <FormikField
                      name="heading"
                      value={values.heading}
                      touched={touched.heading}
                      error={errors.heading}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label col-lg-3 text-nowrap required">
                    Description
                  </span>
                  <div className="form__form-group-field col-lg-5">
                    <FormikField
                      textArea
                      name="description"
                      value={values.description}
                      touched={touched.description}
                      error={errors.description}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label col-lg-3 text-nowrap required">
                    Footer Description
                  </span>
                  <div className="form__form-group-field col-lg-5">
                    <FormikField
                      textArea
                      name="footerDescription"
                      value={values.footerDescription}
                      touched={touched.footerDescription}
                      error={errors.footerDescription}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label col-lg-3 text-nowrap required">
                    Footer Logo
                  </span>
                  <div className="form__form-group-field col-lg-5">
                    <FormikDropZoneField
                      name="logo"
                      value={values.logo}
                      setFieldValue={setFieldValue}
                      defaultImage={defaultImages.logo}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label col-lg-3 text-nowrap required">
                    Facebook Link
                  </span>
                  <div className="form__form-group-field col-lg-5">
                    <FormikField
                      name="facebookLink"
                      value={values.facebookLink}
                      touched={touched.facebookLink}
                      error={errors.facebookLink}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label col-lg-3 text-nowrap required">
                    Twitter Link
                  </span>
                  <div className="form__form-group-field col-lg-5">
                    <FormikField
                      name="twitterLink"
                      value={values.twitterLink}
                      touched={touched.twitterLink}
                      error={errors.twitterLink}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label col-lg-3 text-nowrap required">
                    LinkedIn Link
                  </span>
                  <div className="form__form-group-field col-lg-5">
                    <FormikField
                      name="linkedInLink"
                      value={values.linkedInLink}
                      touched={touched.linkedInLink}
                      error={errors.linkedInLink}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label col-lg-3 text-nowrap required">
                    Instagram Link
                  </span>
                  <div className="form__form-group-field col-lg-5">
                    <FormikField
                      name="instagramLink"
                      value={values.instagramLink}
                      touched={touched.instagramLink}
                      error={errors.instagramLink}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label col-lg-3">
                    <button
                      className="btn btn-primary btn-sm"
                      type="submit"
                      disabled={isLoading}
                    >
                      Save
                    </button>
                  </span>
                </div>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </Col>
  );
};

export default FooterCard;

import React from 'react';
import { useFormikContext } from 'formik';
import { FormikField } from '../../shared/components/form/Field';

function DetailPageMetaDataForm() {
  const { values, errors, touched } = useFormikContext();
  return (
    <div className="w-100 detail-page-meta">
      <div className="card__title">
        <h5 className="bold-text">MetaData Content</h5>
        <h5 className="subhead">Edit Content</h5>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label col-lg-3 text-nowrap required">
          Title
        </span>
        <div className="form__form-group-field col-lg-5">
          <FormikField
            name="metaTitle"
            value={values && values.metaTitle}
            touched={touched && touched.metaTitle}
            error={errors && errors.metaTitle}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label col-lg-3 text-nowrap required">
          Description
        </span>
        <div className="form__form-group-field col-lg-5">
          <FormikField
            name="metaDescription"
            value={values && values.metaDescription}
            touched={touched && touched.metaDescription}
            error={errors && errors.metaDescription}
            textArea
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label col-lg-3 text-nowrap required">
          Keywords
        </span>
        <div className="form__form-group-field col-lg-5">
          <FormikField
            name="metaKeywords"
            value={values && values.metaKeywords}
            touched={touched && touched.metaKeywords}
            error={errors && errors.metaKeywords}
          />
        </div>
      </div>
    </div>
  );
}

export default DetailPageMetaDataForm;

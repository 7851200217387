import React, { Component, Fragment } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
// import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import Router from './Router';
import store from './store';
import ScrollToTop from './ScrollToTop';
import Toast from '../../shared/components/Toast';
import { getUser } from '../../services/authServices';
import { setCurrentUser } from '../../redux/actions/authActions';
import '../../scss/app.scss';

if (getUser()) {
  store.dispatch(setCurrentUser(JSON.parse(getUser())));
}

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
    };
  }

  componentDidMount() {
    window.addEventListener('load', () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
    });
  }

  render() {
    const { loaded, loading } = this.state;
    return (
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop>
            <Fragment>
              {!loaded && (
                <div className={`load${loading ? '' : ' loaded'}`}>
                  <div className="load__icon-wrap">
                    <svg className="load__icon">
                      <path
                        fill="#4ce1b6"
                        d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                      />
                    </svg>
                  </div>
                </div>
              )}
              <div>
                <Toast />
                <Router />
              </div>
            </Fragment>
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;

import React, { useEffect } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { FormikField } from '../../../../../../shared/components/form/Field';
import { FormikDropZoneField } from '../../../../../../shared/components/form/DropZone';
import FormikTextEditor from '../../../../../../shared/components/form/FormikTextEditor';
import FormikDatePicker from '../../../../../../shared/components/form/FormikDateTimePicker';
import {
  getSingleBlog,
  addNewBlog,
  updateBlog,
  clearBlogData,
} from '../../../../../../redux/actions/blogsActions';
import DetailPageMetaDataForm from '../../../../../Common/DetailPageMetaDataForm';
import { ROOT_URL } from '../../../../../../shared/helpers/constants';

const BlogForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { selectedBlogData } = useSelector((state) => state.blogsPage);
  const { isLoading } = useSelector((state) => state.uiState);

  useEffect(() => {
    if (id) dispatch(getSingleBlog(id));
    return () => {
      dispatch(clearBlogData());
    };
  }, []);

  const initValues = {
    title: selectedBlogData.title || '',
    slug: selectedBlogData.slug || '',
    author: selectedBlogData.author || '',
    createdAt: selectedBlogData.createdAt || '',
    blogDetails: selectedBlogData.blogDetails || '',
    blogThumnail: '',
    blogImage: '',
    metaTitle: selectedBlogData.metaTitle || '',
    metaDescription: selectedBlogData.metaDescription || '',
    metaKeywords: selectedBlogData.metaKeywords || '',
  };

  const defaultImages = {
    blogThumnail:
      selectedBlogData.blogThumnail &&
      ROOT_URL + selectedBlogData.blogThumnail.url,
    blogImage:
      selectedBlogData.blogImage && ROOT_URL + selectedBlogData.blogImage.url,
  };

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Service Section Content</h5>
            <h5 className="subhead">Edit Content</h5>
          </div>
          <div>
            <Formik
              enableReinitialize
              initialValues={initValues}
              validationSchema={Yup.object({
                title: Yup.string().required('Required!'),
                slug: Yup.string()
                  .required('Required!')
                  .matches(
                    /^[A-Za-z0-9-]+$/,
                    'Only Alphabets and (-) are allowed'
                  ),
                author: Yup.string().required('Required!'),
                blogDetails: Yup.string().required('Required!'),
                metaTitle: Yup.string().required('Required!'),
                metaDescription: Yup.string().required('Required!'),
                metaKeywords: Yup.string().required('Required!'),
              })}
              onSubmit={(values) => {
                if (id) dispatch(updateBlog({ id, values }));
                else dispatch(addNewBlog(values));
              }}
            >
              {({ setFieldValue, values, touched, errors }) => (
                <Form className="form form--horizontal text-left">
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Title
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        name="title"
                        value={values.title}
                        touched={touched.title}
                        error={errors.title}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Slug
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <div className="form__form-group-icon">/blog/</div>
                      <FormikField
                        name="slug"
                        value={values && values.slug}
                        touched={touched && touched.slug}
                        error={errors && errors.slug}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Author
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        name="author"
                        value={values.author}
                        touched={touched.author}
                        error={errors.author}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-md-3 text-nowrap required">
                      Created At
                    </span>
                    <div className="form__form-group-field col-md-5">
                      <FormikDatePicker
                        name="createdAt"
                        value={values.createdAt}
                        onChange={setFieldValue}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Thumbnail
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikDropZoneField
                        name="blogThumnail"
                        value={values.blogThumnail}
                        setFieldValue={setFieldValue}
                        defaultImage={defaultImages.blogThumnail}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Main Image
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikDropZoneField
                        name="blogImage"
                        value={values.blogImage}
                        setFieldValue={setFieldValue}
                        defaultImage={defaultImages.blogImage}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-md-3 text-nowrap required">
                      Blog Details
                    </span>
                    <div className="form__form-group-field col-md-8">
                      <FormikTextEditor
                        name="blogDetails"
                        onChange={setFieldValue}
                        defaultValue={selectedBlogData.blogDetails}
                        error={errors.blogDetails}
                        touched={touched.blogDetails}
                      />
                    </div>
                  </div>
                  {/* For Detail Page Meta Data */}
                  <DetailPageMetaDataForm />
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3">
                      <button
                        className="btn btn-primary btn-sm"
                        type="submit"
                        disabled={isLoading}
                      >
                        {id ? 'Update Blog' : 'Add Blog'}
                      </button>
                    </span>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default BlogForm;

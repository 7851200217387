import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { Col, Container, Row, Button } from 'reactstrap';
import SectionCard from './components/SectionCard';
import ServiceForm from './components/ServiceForm';
import ServiceDetails from './components/ServiceDetails';
import SectionDetailsForm from './components/SectionDetailsForm';

const Services = ({ history }) => (
  <Container className="dashboard">
    <Row>
      <Col xs={10}>
        <h3 className="page-title">Services Section</h3>
      </Col>
      <Col xs={2} className="d-flex justify-content-end">
        <div>
          <Button
            onClick={history.goBack}
            color="primary"
            className="btn btn-xs"
          >
            Back
          </Button>
        </div>
      </Col>
    </Row>
    <Row>
      <Switch>
        <Route
          exact
          path="/pages/services/all-services/:serviceId/sections/:sectionId/edit"
          component={SectionDetailsForm}
        />
        <Route
          exact
          path="/pages/services/all-services/:id/sections/add"
          component={SectionDetailsForm}
        />
        <Route
          exact
          path="/pages/services/all-services/:id/sections/"
          component={ServiceDetails}
        />
        <Route
          exact
          path="/pages/services/all-services/:id/edit"
          component={ServiceForm}
        />
        <Route
          exact
          path="/pages/services/all-services/add"
          component={ServiceForm}
        />
        <Route path="/pages/services/all-services" component={SectionCard} />
      </Switch>
    </Row>
  </Container>
);

Services.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Services;

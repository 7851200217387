import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { useField } from 'formik';

function FormikModernDropZone({ onChange, name, label, defaultImage }) {
  const MAX_FILE_SIZE = 210000;
  const [_defaultImage, setDefaultImage] = useState('');
  const [field, meta, helpers] = useField(name);
  const { value: files } = field;
  const { touched, error } = meta;
  const { setValue } = helpers;

  useEffect(() => {
    if (defaultImage) {
      setDefaultImage(defaultImage);
    }
  }, [defaultImage]);

  const handleDrop = (file) => {
    const modifiedFiles = file.map((fl) =>
      Object.assign(fl, {
        preview: URL.createObjectURL(fl),
      })
    );

    setValue(modifiedFiles[0] || {});

    if (onChange) {
      onChange(modifiedFiles, name);
    }
  };

  const removeFile = (e) => {
    e.preventDefault();

    setValue(null);
  };

  return (
    <div className="form__form-group">
      {label && (
        <span className="form__form-group-label col-lg-3 text-nowrap required">
          {label}
        </span>
      )}

      <div
        className={`form__form-group-field flex-column ${
          label ? 'col-lg-5' : 'col-lg-12'
        }`}
      >
        <div className="mb-2 dropzone dropzone--single">
          <Dropzone
            accept="image/jpeg, image/png, image/jpg"
            name={name}
            multiple={false}
            onDrop={handleDrop}
            maxSize={MAX_FILE_SIZE}
          >
            {({ getRootProps, getInputProps, rejectedFiles }) => (
              <>
                <div {...getRootProps()} className="dropzone__input">
                  {(!files || files.length === 0) && (
                    <div className="dropzone__drop-here">
                      <span className="lnr lnr-upload" /> Drop image here to
                      upload
                    </div>
                  )}
                  <input {...getInputProps()} />
                </div>
                <div className="dropzone__error">
                  {rejectedFiles.length > 0 &&
                    rejectedFiles.map((file) => (
                      <span key={file.name} className="form__form-group-error">
                        {file.size > MAX_FILE_SIZE &&
                          `${file.name} is too large. It must be less than or equal to 200 KB`}
                      </span>
                    ))}
                </div>
              </>
            )}
          </Dropzone>

          {files && files.preview ? (
            <aside className="dropzone__img">
              <img src={files.preview} alt="drop-img" />
              <p className="dropzone__img-name">{files.name}</p>
              <button
                className="dropzone__img-delete"
                type="button"
                onClick={removeFile}
              >
                Remove
              </button>
            </aside>
          ) : (
            _defaultImage && (
              <aside className="dropzone__img">
                <img src={_defaultImage} alt="drop-img" />
                <button
                  className="dropzone__img-delete"
                  type="button"
                  onClick={() => setDefaultImage('')}
                >
                  Remove
                </button>
              </aside>
            )
          )}
        </div>

        {touched && error && (
          <span className="form__form-group-error">{error}</span>
        )}
      </div>
    </div>
  );
}
FormikModernDropZone.propTypes = {
  name: propTypes.string.isRequired,
  onChange: propTypes.func,
  label: propTypes.string,
  defaultImage: propTypes.string,
};

FormikModernDropZone.defaultProps = {
  label: '',
  onChange: null,
  defaultImage: '',
};

export default FormikModernDropZone;

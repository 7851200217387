import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import { logoutUser } from '../../../redux/actions/authActions';

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

class TopbarProfile extends PureComponent {
  static propTypes = {
    doLogoutUser: PropTypes.func,
    user: PropTypes.object,
  };

  static defaultProps = {
    doLogoutUser: () => {},
    user: {},
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({ collapse: !prevState.collapse }));
  };

  handleClick = () => {
    const { doLogoutUser } = this.props;
    doLogoutUser();
  };

  render() {
    const { collapse } = this.state;
    const { user } = this.props;

    return (
      <div className="topbar__profile">
        <button type="button" className="topbar__avatar" onClick={this.toggle}>
          <img className="topbar__avatar-img" src={Ava} alt="avatar" />
          <p className="topbar__avatar-name">{user && user.name}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && (
          <button
            type="button"
            className="topbar__back"
            onClick={this.toggle}
          />
        )}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <TopbarMenuLink title="Profile" icon="list" path="/pages/profile" />
            <div className="topbar__menu-divider" />
            <span
              role="button"
              tabIndex={0}
              className="topbar__link"
              onClick={this.handleClick}
            >
              <span className="topbar__link-icon lnr lnr-exit" />
              <p className="topbar__link-title">Log out</p>
            </span>
          </div>
        </Collapse>
      </div>
    );
  }
}

export default connect(({ auth }) => ({ user: auth.user }), {
  doLogoutUser: logoutUser,
})(TopbarProfile);

import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

const FormikTextArea = ({ className, ...props }) => {
  const [field] = useField(props);
  return (
    <>
      <textarea {...field} className={className} />
    </>
  );
};

FormikTextArea.propTypes = {
  className: PropTypes.string,
};
FormikTextArea.defaultProps = {
  className: null,
};

const FormikField = ({
  textArea,
  textRight,
  className,
  touched,
  error,
  disabled,
  placeholder,
  ...props
}) => {
  const [field] = useField(props);
  return (
    <div className={`form__form-group-input-wrap ${className}`}>
      {textArea && <textarea {...field} disabled={disabled} />}
      {!textArea && (
        <input
          {...field}
          {...props}
          className={textRight ? 'text-right' : ''}
          disabled={disabled}
          placeholder={placeholder}
        />
      )}
      {touched && error && (
        <span className="form__form-group-error">{error}</span>
      )}
    </div>
  );
};

FormikField.propTypes = {
  textArea: PropTypes.bool,
  textRight: PropTypes.bool,
  touched: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
};
FormikField.defaultProps = {
  textArea: false,
  textRight: false,
  touched: false,
  disabled: false,
  className: null,
  placeholder: null,
  error: null,
};

const renderFormikFieldWithLabel = ({
  label,
  id,
  name,
  className,
  ...props
}) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <div className={`form__form-group-input-wrap ${className}`}>
      <label htmlFor={id || name}>{label}</label>
      <input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <span className="form__form-group-error">{meta.error}</span>
      ) : null}
    </div>
  );
};

renderFormikFieldWithLabel.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
};
renderFormikFieldWithLabel.defaultProps = {
  id: null,
  name: null,
  label: null,
  className: null,
};

const renderField = ({
  input,
  label,
  type,
  className,
  meta: { touched, error },
  textArea,
  textRight,
}) => (
  <div className={`form__form-group-input-wrap ${className}`}>
    {textArea && <textarea {...input} placeholder={label} type={type} />}
    {!textArea && (
      <input
        {...input}
        placeholder={label}
        type={type}
        className={textRight ? 'text-right' : ''}
      />
    )}
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  label: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  textArea: PropTypes.bool,
  textRight: PropTypes.bool,
};

renderField.defaultProps = {
  meta: null,
  label: '',
  type: '',
  className: '',
  textArea: false,
  textRight: false,
};

export { renderField, FormikField, FormikTextArea, renderFormikFieldWithLabel };

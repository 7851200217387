import React, { useEffect } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ProductsSection from './components/ProductsSection';
import { getProductsAction } from '../../../redux/actions/productActions';

function Products() {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProductsAction());
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <Col xs={10}>
          <h3 className="page-title">Company Products</h3>
        </Col>
        <Col xs={2} className="d-flex justify-content-end">
          <div>
            <Button
              onClick={history.goBack}
              color="primary"
              className="btn btn-xs"
            >
              Back
            </Button>
          </div>
        </Col>
      </Row>

      <Row>
        <ProductsSection />
      </Row>
    </Container>
  );
}

export default Products;

import {
  GET_BLOG_META_DATA,
  GET_BLOG_BANNER_DATA,
  GET_BLOG_TRENDING_DATA,
  GET_BLOGS,
  GET_BLOG,
  GET_COMMENT,
  DELETE_COMMENT,
  APPROVE_COMMENT,
  GET_BLOG_COMMENTS,
  GET_BLOG_REPLIES,
  DELETE_BLOG,
  CLEAR_BLOG_DATA,
} from '../actions/blogsActions';

const initialState = {
  bannerSectionData: {},
  trendingSectionData: {},
  blogs: [],
  blogComments: [],
  blogReplies: [],
  selectedBlogData: {},
  selectedCommentData: {},
  blogMetaData: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CLEAR_BLOG_DATA:
      return {
        ...state,
        bannerSectionData: {},
        trendingSectionData: {},
        blogs: [],
        blogComments: [],
        blogReplies: [],
        selectedBlogData: {},
        selectedCommentData: {},
        blogMetaData: {},
      };
    case GET_BLOG_META_DATA:
      return {
        ...state,
        blogMetaData: action.payload,
      };
    case GET_BLOG_BANNER_DATA:
      return {
        ...state,
        bannerSectionData: action.payload,
      };
    case GET_BLOG_TRENDING_DATA:
      return {
        ...state,
        trendingSectionData: action.payload,
      };
    case GET_BLOGS:
      return {
        ...state,
        blogs: action.payload,
      };
    case GET_BLOG:
      return {
        ...state,
        selectedBlogData: action.payload,
      };
    case GET_COMMENT:
      return {
        ...state,
        selectedCommentData: action.payload,
      };
    case APPROVE_COMMENT:
      return {
        ...state,
        blogComments: state.blogComments.map((comment) => {
          if (comment._id === action.payload.id) {
            const updatedComment = {
              ...comment,
              status: 'approved',
            };
            return updatedComment;
          }
          return comment;
        }),
      };
    case GET_BLOG_COMMENTS:
      return {
        ...state,
        blogComments: action.payload,
      };
    case GET_BLOG_REPLIES:
      return {
        ...state,
        blogReplies: action.payload,
      };
    case DELETE_BLOG:
      return {
        ...state,
        blogs: state.blogs.filter((blog) => blog._id !== action.payload.id),
      };
    case DELETE_COMMENT:
      return {
        ...state,
        blogComments: state.blogComments.filter(
          (comment) => comment._id !== action.payload.id
        ),
        blogReplies: state.blogReplies.filter(
          (reply) => reply._id !== action.payload.id
        ),
      };
    default:
      return state;
  }
}

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { deleteWhyChooseFaq } from '../../../../../../redux/actions/homeActions';

function FaqsTable() {
  const dispatch = useDispatch();
  const { faqsData } = useSelector(
    (state) => state.homePage.whyChooseSectionData
  );

  const handleDelete = (id) => {
    dispatch(deleteWhyChooseFaq(id));
  };

  return (
    <div>
      <div className="text-right">
        <Link to="/pages/home/why-choose-us/add">
          <button type="button" className="btn btn-primary btn-sm">
            Add
          </button>
        </Link>
      </div>

      <TableContainer>
        <Table className="table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>

              <TableCell>Title</TableCell>

              <TableCell>Description</TableCell>

              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {faqsData.map((row, idx) => (
              <TableRow key={row._id}>
                <TableCell>{idx + 1}</TableCell>

                <TableCell className="w-25">{row.question}</TableCell>

                <TableCell className="w-50">{row.answer}</TableCell>

                <TableCell className="w-25" align="center">
                  <Link to={`/pages/home/why-choose-us/edit/${row._id}`}>
                    <Badge
                      role="button"
                      color="secondary"
                      type="button"
                      className="mr-1"
                    >
                      Edit
                    </Badge>
                  </Link>

                  <Badge
                    role="button"
                    color="danger"
                    type="button"
                    onClick={() => handleDelete(row._id)}
                  >
                    Delete
                  </Badge>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default FaqsTable;

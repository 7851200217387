import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

function ProtectedRoute({ component: Component, render, ...rest }) {
  const { isAuthenticated } = useSelector((state) => state.auth);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) return <Redirect to="/" />;
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
}

ProtectedRoute.propTypes = {
  component: PropTypes.func,
  render: PropTypes.func,
};

ProtectedRoute.defaultProps = {
  component: null,
  render: null,
};

export default ProtectedRoute;

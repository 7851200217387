import {
    GET_FOOTER_DATA,
    CLEAR_FOOTER_DATA
} from '../actions/footerActions';

const initialState = {
    footerData: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_FOOTER_DATA:
            return {
                ...state,
                footerData: action.payload,
            };
        case CLEAR_FOOTER_DATA:
            return {
                ...state,
                footerData: {},
            };
        default:
            return state;
    }
}

import * as yup from 'yup';

export const infoCardFormInitValues = {
  title: '',
  subTitle: '',
  icon: '',
};

export const infoCardFormValSchema = yup.object({
  title: yup.string().required('Required'),
  subTitle: yup.string().required('Required'),
});

import React from 'react';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Table, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

// COMPONENTS & ACTIONS
import ProductTableRow from './ProductTableRow';
import { updateProductRankAction } from '../../../../redux/actions/productActions';

function ProductsTable() {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.productsData);

  const handleDragEnd = (result) => {
    const payload = {
      source: {
        item: products[result.source.index],
        index: result.source.index,
      },
      destination: {
        item: products[result.destination.index],
        index: result.destination.index,
      },
    };
    dispatch(updateProductRankAction(payload));
  };

  return (
    <div>
      <div className="text-right">
        <Link to="/pages/products/add">
          <Button color="primary" size="sm">
            Add
          </Button>
        </Link>
      </div>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Link</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="products-record" type="ITEM">
            {(provided) => (
              <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                {products.length > 0 &&
                  products.map((product, idx) => (
                    <ProductTableRow
                      key={product._id}
                      product={product}
                      index={idx}
                    />
                  ))}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </div>
  );
}

export default ProductsTable;

import * as yup from 'yup';

export const addEditProductsInitVals = {
  name: '',
  url: '',
  icon: '',
};

export const addEditProductsValSchema = yup.object({
  name: yup.string().required('Required'),
  url: yup.string(),
  icon: yup.mixed(),
});

import http, { multipartConfig } from './httpService';

export const getBannerData = () => {
  return http.get('/home/banner');
};

export const saveBannerData = (values) => {
  const data = { ...values };

  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'string') {
      if (value) {
        formData.append(key, value);
      }
    }
  });

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item);
      });
    }
  });

  return http.post('/home/banner', formData, multipartConfig);
};

export const getWhatWeDoData = () => {
  return http.get('/home/what-we-do');
};

export const saveWhatWeDoData = (values) => {
  return http.post('/home/what-we-do', values);
};

export const getWhatWeDoCardData = (id) => {
  return http.get(`/home/what-we-do/cards/${id}`);
};

export const saveWhatWeDoCardData = ({ id, values }) => {
  const data = { ...values };

  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'string') {
      if (value) {
        formData.append(key, value);
      }
    }
  });

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item);
      });
    }
  });

  return http.put(`/home/what-we-do/cards/${id}`, formData, multipartConfig);
};

export const getWhoWeAreData = () => {
  return http.get('/home/who-we-are');
};

export const saveWhoWeAreData = (values) => {
  const data = { ...values };

  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'string') {
      if (value) {
        formData.append(key, value);
      }
    }
  });

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item);
      });
    }
  });

  return http.post('/home/who-we-are', formData, multipartConfig);
};

export const getPortfolioData = () => {
  return http.get('/home/portfolio');
};

export const savePortfolioData = (values) => {
  const data = { ...values };

  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item);
      });
    }
  });

  return http.post('/home/portfolio', formData, multipartConfig);
};

export const deletePortfolioImage = (id) => {
  return http.delete(`/home/portfolio/images/${id}`);
};

export const getCaseStudyData = () => {
  return http.get('/home/case-study');
};

export const saveCaseStudyData = (values) => {
  return http.post('/home/case-study', values);
};

export const getHappyClientsData = () => {
  return http.get('/home/happy-clients');
};

export const getClientData = (id) => {
  return http.get(`/home/happy-clients/${id}`);
};

export const addClient = (values) => {
  const data = { ...values };

  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'string') {
      if (value) {
        formData.append(key, value);
      }
    }
  });

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item);
      });
    }
  });

  return http.post('/home/happy-clients/add', formData, multipartConfig);
};

export const updateClient = ({ id, values }) => {
  const data = { ...values };

  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'string') {
      if (value) {
        formData.append(key, value);
      }
    }
  });

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item);
      });
    }
  });

  return http.put(`/home/happy-clients/${id}`, formData, multipartConfig);
};

export const deleteClient = (id) => {
  return http.delete(`/home/happy-clients/${id}`);
};

export const addProductServicesSectionHeading = (values) => {
  return http.post('/home/products-services/heading', values);
};

export const getSingleProductServicesQuery = (id) => {
  return http.get(`/home/product-services/${id}`);
};

export const getProductServicesSectionData = () => {
  return http.get('/home/products-services');
};

export const postProductService = (values) => {
  return http.post('/home/product-services', values);
};

export const updateProductService = (values, id) => {
  return http.put(`/home/product-services/${id}`, values);
};

export const deleteProductService = (id) => {
  return http.delete(`/home/product-services/${id}`);
};

export const getWhyChooseSectionDataQuery = () => {
  return http.get('/home/faqs/');
};

export const deleteWhyChooseFaqMutation = (id) => {
  return http.delete(`/home/faq/${id}`);
};

export const saveWhyChooseSectionDataMutation = (values) => {
  const data = { ...values };

  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (value) {
      formData.append(key, value);
    }
  });

  return http.post('/home/faqs/', formData);
};

export const addWhyChooseFaqMutation = (values) => {
  return http.post('/home/faq', values);
};

export const updateWhyChooseFaqMutation = (values, id) => {
  return http.put(`/home/faq/${id}`, values);
};

export const getSingleWhyChooseFaqQuery = (id) => {
  return http.get(`/home/faq/${id}`);
};

export const getInfoCardsQuery = () => {
  return http.get('/home/info-cards');
};

export const deleteInfoCardMutation = (id) => {
  return http.delete(`/home/info-cards/${id}`);
};

export const addInfoCardMutation = (values) => {
  const data = { ...values };

  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (value) {
      formData.append(key, value);
    }
  });

  return http.post('/home/info-cards', formData);
};

export const getSingleInfoCardQuery = (id) => {
  return http.get(`/home/info-card/${id}`);
};

export const updateInfoCardMutation = (values, id) => {
  const data = { ...values };

  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (value) {
      formData.append(key, value);
    }
  });

  return http.put(`/home/info-cards/${id}`, formData);
};

export const saveFaqSectionDataMutation = (values) => {
  return http.post('/home/get-started', values);
};

export const getFaqSectionDataQuery = () => {
  return http.get('/home/get-started');
};

export const deleteFaqMutation = (id) => {
  return http.delete(`/home/get-started/faq/${id}`);
};

export const addFaqMutation = (values) => {
  return http.post('/home/get-started/faq', values);
};

export const updateFaqMutation = (values, id) => {
  return http.put(`/home/get-started/faq/${id}`, values);
};

export const getFaqByIdQuery = (id) => {
  return http.get(`/home/get-started/faq/${id}`);
}
import http, { multipartConfig } from './httpService';

export const getClientSectionData = () => http.get('/clientCards');

export const getClientSectionDataById = (id) => http.get(`/clientCards/${id}`);

export const deleteClientCard = (id) => http.delete(`/clientCards/${id}`);

export const saveClientCard = (data) => {
  const formData = new FormData();

  formData.append('name', data.name);
  formData.append('description', data.description);
  formData.append('thumbnail', data.thumbnail && data.thumbnail[0]);

  return http.post('/clientCards', formData, multipartConfig);
};

export const updateClientCard = (data) => {
  const formData = new FormData();

  formData.append('name', data.name);
  formData.append('description', data.description);
  if (data.thumbnail) {
    formData.append('thumbnail', data.thumbnail[0]);
  }

  return http.put(`/clientCards/${data.id}`, formData, multipartConfig);
};

import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Button, Table } from 'reactstrap';
import { TableBody, TableHead, TableRow, TableCell } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Modal from '../../../../../../shared/components/Modal';
import {
  getAllCaseStudies,
  clearCaseStudyData,
  deleteCaseStudy,
  updateCaseStudyRank,
} from '../../../../../../redux/actions/caseStudyActions';
import CustomTableRow from './TableRow';

const SectionCard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { caseStudies } = useSelector((state) => state.caseStudy);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(false);

  useEffect(() => {
    dispatch(getAllCaseStudies());
    return () => {
      dispatch(clearCaseStudyData());
    };
  }, []);

  const toggleDeleteModal = () => setShowDeleteModal((prevState) => !prevState);

  const handleConfirmDelete = () => {
    dispatch(deleteCaseStudy(selectedId));
  };

  const handleDelete = (e, id) => {
    e.stopPropagation();
    setSelectedId(id);
    toggleDeleteModal();
  };

  const handleDragEnd = (result) => {
    const payload = {
      source: {
        item: caseStudies[result.source.index],
        index: result.source.index,
      },
      destination: {
        item: caseStudies[result.destination.index],
        index: result.destination.index,
      },
    };
    dispatch(updateCaseStudyRank(payload));
  };

  return (
    
    <Col md={12}>
      <Modal
        showModal={showDeleteModal}
        toggle={toggleDeleteModal}
        color="warning"
        title="Attention!"
        message="Are you sure, you want to delete this card?"
        onSuccess={handleConfirmDelete}
      />
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between">
            <div className="card__title">
              <h5 className="bold-text">CaseStudies Content</h5>
              <h5 className="subhead">Edit Content</h5>
            </div>

            <div>
              <Button
                color="primary"
                className="btn btn-sm"
                onClick={() =>
                  history.push('/pages/case-study/all-case-studies/add')
                }
              >
                + Add New
              </Button>
            </div>
          </div>

          <div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell> Case Study Image</TableCell>
                  <TableCell className="w-25">Company Name</TableCell>
                  <TableCell className="w-50">Company Description</TableCell>
                  <TableCell className="w-25 text-center">Actions</TableCell>
                </TableRow>
              </TableHead>

              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="case-study-record" type="ITEM">
                  {(provided) => (
                    <TableBody
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {caseStudies.length > 0 &&
                        caseStudies.map((item, index) => (
                          <CustomTableRow
                            key={item._id}
                            item={item}
                            index={index}
                            handleDelete={handleDelete}
                          />
                        ))}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SectionCard;

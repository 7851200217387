import React from 'react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

// COMPONENTS & ACTIONS
import { FormikField } from '../../../../../../shared/components/form/Field';
import { saveProductServiceSectionHeading } from '../../../../../../redux/actions/homeActions';

function HeadingForm() {
  const dispatch = useDispatch();
  const sectionData = useSelector((state) => state.homePage.productServicesSectionData);

  const headingInitValue = {
    heading: sectionData && sectionData.heading || '',
  };

  return (
    <Formik
      enableReinitialize
      initialValues={headingInitValue}
      validationSchema={Yup.object({
        heading: Yup.string().required('Required!'),
      })}
      onSubmit={async (values) => {
        dispatch(saveProductServiceSectionHeading(values));
      }}
    >
      {({ values, touched, errors }) => (
        <Form className="form form--horizontal">
          <div className="form__form-group">
            <span className="form__form-group-label col-lg-3 text-nowrap required">
              Section Heading
            </span>

            <div className="form__form-group-field col-lg-5">
              <FormikField
                name="heading"
                value={values.heading}
                touched={touched.heading}
                error={errors.heading}
              />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label col-lg-3">
              <button className="btn btn-primary btn-sm" type="submit">
                Save
              </button>
            </span>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default HeadingForm;

import * as yup from 'yup';

export const faqSectionFormInitialValues = {
  heading: '',
  sectionImage: '',
};

export const faqSectionFormValidationSchema = yup.object({
  heading: yup.string().required('Required'),
});

export const faqFormInitValues = {
  question: '',
  answer: '',
};

export const faqFormValSchema = yup.object({
  question: yup.string().required('Required'),
  answer: yup.string().required('Required'),
});

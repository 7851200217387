import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row, Button, Card, CardBody } from 'reactstrap';
import MetaDataForm from '../../../../Common/MetaDataForm';
import {
  getAboutMetaData,
  saveAboutMetaData,
  clearAboutData,
} from '../../../../../redux/actions/aboutActions';

const AboutMetaData = ({ history }) => {
  const dispatch = useDispatch();
  const { aboutMetaData } = useSelector((state) => state.aboutPage);

  useEffect(() => {
    dispatch(getAboutMetaData('about'));
    return () => {
      dispatch(clearAboutData());
    };
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <Col xs={10}>
          <h3 className="page-title">About Meta Data Section</h3>
        </Col>
        <Col xs={2} className="d-flex justify-content-end">
          <div>
            <Button
              onClick={history.goBack}
              color="primary"
              className="btn btn-xs"
            >
              Back
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">MetaData Content</h5>
                <h5 className="subhead">Edit Content</h5>
              </div>
              <div>
                <MetaDataForm
                  page="about"
                  data={aboutMetaData}
                  handleSubmit={saveAboutMetaData}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

AboutMetaData.propTypes = {
  history: PropTypes.object.isRequired,
};

export default AboutMetaData;

import React from 'react';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';
import { TableCell, TableRow } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import DragIcon from 'mdi-react/DragIcon';

// ACTIONS
import { deleteProductAction } from '../../../../redux/actions/productActions';

function ProductTableRow({ product, index }) {
  const dispatch = useDispatch();

  const handleDelete = (id) => {
    dispatch(deleteProductAction(id));
  };

  return (
    <Draggable draggableId={product._id} index={index}>
      {(provided) => (
        <TableRow hover ref={provided.innerRef} {...provided.draggableProps}>
          <TableCell>
            <span {...provided.dragHandleProps}>
              <DragIcon size={24} />
            </span>
          </TableCell>

          <TableCell className="w-25">{product.name}</TableCell>

          <TableCell className="w-50">{product.url}</TableCell>

          <TableCell className="w-25" align="center">
            <Link to={`/pages/products/edit/${product._id}`}>
              <Badge
                role="button"
                color="secondary"
                type="button"
                className="mr-1"
              >
                Edit
              </Badge>
            </Link>

            <Badge
              role="button"
              color="danger"
              type="button"
              onClick={() => handleDelete(product._id)}
            >
              Delete
            </Badge>
          </TableCell>
        </TableRow>
      )}
    </Draggable>
  );
}

ProductTableRow.propTypes = {
  product: propTypes.object.isRequired,
  index: propTypes.number.isRequired,
};

export default ProductTableRow;

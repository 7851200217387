import { displayToast, hideToast, unSetLoading } from '../../redux/actions/uiActions';

let dispatch;

export const getDispatch = (storeDispatch) => {
  dispatch = storeDispatch;
};

export default function (message, type) {
  if (message) {
    dispatch(displayToast(message, type));
    dispatch(unSetLoading());
    setTimeout(() => dispatch(hideToast()), 5500);
  }
}

export const returnDispatch = () => dispatch;

import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import ProductsTable from './ProductsTable';

function ProductsSection() {
  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Products</h5>
            <h5 className="subhead">Manage Content</h5>
          </div>

          <div>
            <ProductsTable />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}

export default ProductsSection;

import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS & UTILITIES
import FormikModernField from '../../../../../../shared/components/form/FormikModernField';
import SubmitBtn from '../../../../../../shared/components/form/SubmitBtn';
import {
  getStartedSectionFormInitValues,
  getStartedSectionFormValSchema,
} from '../utilities/formUtils';
import SectionTable from './SectionTable';
import { saveFaqSectionData } from '../../../../../../redux/actions/homeActions';

function SectionBody() {
  const dispatch = useDispatch();
  const [initValues, setInitValues] = useState(getStartedSectionFormInitValues);
  const { heading } = useSelector((state) => state.homePage.faqsSectionData);

  useEffect(() => {
    if (heading) {
      setInitValues({ heading });
    }
  }, [heading]);

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Get Started Content</h5>
            <h5 className="subhead">Manage Content</h5>
          </div>

          <div>
            <Formik
              enableReinitialize
              initialValues={initValues}
              validationSchema={getStartedSectionFormValSchema}
              onSubmit={(values) => {
                dispatch(saveFaqSectionData(values));
              }}
            >
              {() => (
                <Form className="form form--horizontal">
                  <FormikModernField name="heading" label="Heading" />

                  <SubmitBtn />
                </Form>
              )}
            </Formik>
          </div>

          <SectionTable />
        </CardBody>
      </Card>
    </Col>
  );
}

export default SectionBody;

import Axios from 'axios';
import toastify, { returnDispatch } from '../shared/helpers/toastNotification';
import { unSetLoading } from '../redux/actions/uiActions';
import { ROOT_URL } from '../shared/helpers/constants';

Axios.defaults.baseURL = `${ROOT_URL}/api`;

const dispatch = returnDispatch(); // returns dispatch method of redux store

Axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response && error.response.status >= 400 && error.response.status < 500;

  if (error.response && error.response.status === 401) {
    toastify(error.response.data, 'error');
    localStorage.removeItem('user');
    setTimeout(() => {
      window.location = '/login';
    }, 1000);
  }

  if (!expectedError) {
    toastify('An Unexpected Error Occured!', 'error');
    dispatch(unSetLoading());
  }

  return Promise.reject(error);
});

export const multipartConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

export default {
  get: Axios.get,
  post: Axios.post,
  put: Axios.put,
  delete: Axios.delete,
};

import React from 'react';
import propTypes from 'prop-types';
import { useField } from 'formik';

function FormikModernField({
  name,
  placeholder,
  disabled,
  className,
  textArea,
  label,
}) {
  const [field, meta] = useField(name);
  const { touched, error } = meta;

  return (
    <div className="form__form-group">
      {label && (
        <span className="form__form-group-label col-lg-3 text-nowrap required">
          {label}
        </span>
      )}

      <div
        className={`form__form-group-field ${label ? 'col-lg-5' : 'col-lg-12'}`}
      >
        <div className="form__form-group-input-wrap">
          {textArea && (
            <textarea
              name={name}
              {...field}
              placeholder={placeholder}
              disabled={disabled}
            />
          )}
          {!textArea && (
            <input
              name={name}
              {...field}
              className={`${className}`}
              disabled={disabled}
              placeholder={placeholder}
            />
          )}

          {touched && error && (
            <span className="form__form-group-error">{error}</span>
          )}
        </div>
      </div>
    </div>
  );
}

FormikModernField.propTypes = {
  name: propTypes.string.isRequired,
  type: propTypes.string,
  placeholder: propTypes.string,
  disabled: propTypes.bool,
  className: propTypes.string,
  textArea: propTypes.bool,
  label: propTypes.string,
};

FormikModernField.defaultProps = {
  label: '',
  type: 'text',
  placeholder: '',
  disabled: false,
  className: '',
  textArea: false,
};

export default FormikModernField;

import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { Col, Container, Row, Button } from 'reactstrap';
import SectionCard from './components/SectionCard';
import BlogComments from './components/BlogComments';
import BlogReplies from './components/BlogReplies';
import BlogForm from './components/BlogForm';
import CommentForm from './components/CommentForm';

const Blogs = ({ history }) => (
  <Container className="dashboard">
    <Row>
      <Col xs={10}>
        <h3 className="page-title">Blogs Section</h3>
      </Col>
      <Col xs={2} className="d-flex justify-content-end">
        <div>
          <Button
            onClick={history.goBack}
            color="primary"
            className="btn btn-xs"
          >
            Back
          </Button>
        </div>
      </Col>
    </Row>
    <Row>
      <Switch>
        <Route
          exact
          path="/pages/blog/all-blogs/:id/edit"
          component={BlogForm}
        />
        <Route
          exact
          path="/pages/blog/all-blogs/comments/:commentID/edit"
          component={CommentForm}
        />
        <Route
          exact
          path="/pages/blog/all-blogs/:blogID/comments"
          component={BlogComments}
        />
        <Route
          exact
          path="/pages/blog/all-blogs/:blogID/comments/:commentID/add"
          component={CommentForm}
        />
        <Route
          exact
          path="/pages/blog/all-blogs/:blogID/comments/:commentID/replies"
          component={BlogReplies}
        />
        <Route exact path="/pages/blog/all-blogs/add" component={BlogForm} />
        <Route exact path="/pages/blog/all-blogs" component={SectionCard} />
      </Switch>
    </Row>
  </Container>
);

Blogs.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Blogs;

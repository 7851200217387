import http, { multipartConfig } from './httpService';

export const getContactFormById = (id) => http.get(`/contact/forms/${id}`);

export const getBannerData = () => {
  return http.get('/contact/banner');
};

export const saveBannerData = (values) => {
  const data = { ...values };

  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'string') {
      if (value) {
        formData.append(key, value);
      }
    }
  });

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item);
      });
    }
  });

  return http.post('/contact/banner', formData, multipartConfig);
};

export const getContactCard = () => {
  return http.get('/contact/contact-card');
};

export const saveContactCard = (values) => {
  const data = { ...values };

  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'string') {
      if (value) {
        formData.append(key, value);
      }
    }
  });

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item);
      });
    }
  });

  return http.post('/contact/contact-card', formData, multipartConfig);
};

export const getContactFormData = () => http.get('/contact/contact-forms');

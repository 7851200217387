import http, { multipartConfig } from './httpService';

export const getHeaderData = () => {
    return http.get('/header');
}

export const saveHeaderData = (values) => {
    const data = { ...values };

    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((item) => {
                formData.append(key, item);
            });
        }
    });

    return http.post('/header', formData, multipartConfig);
};

import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS & UTILITIES
import FormikModernDropZone from '../../../../shared/components/form/FormikModernDropZone';
import FormikModernField from '../../../../shared/components/form/FormikModernField';
import SubmitBtn from '../../../../shared/components/form/SubmitBtn';
import {
  addEditProductsInitVals,
  addEditProductsValSchema,
} from '../utilities/formUtils';
import {
  addProductAction,
  editProductAction,
  getSingleProductAction,
} from '../../../../redux/actions/productActions';
import { getImageUrl } from '../../../../utilities/helpers';

function AddEditProductsForm() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { fetched } = useSelector((state) => state.productsData);
  const [initValues, setInitValues] = useState(addEditProductsInitVals);

  useEffect(() => {
    if (id) {
      dispatch(getSingleProductAction(id));
    }
  }, []);

  useEffect(() => {
    if (fetched && id) {
      setInitValues({
        name: fetched.name,
        url: fetched.url,
      });
    }
  }, [fetched]);

  const defaultImg =
    fetched && fetched.icon ? getImageUrl(fetched.icon.url) : '';

  return (
    <Container>
      <Row>
        <Col xs={10}>
          <h3 className="page-title">Products</h3>
        </Col>

        <Col xs={2} className="d-flex justify-content-end">
          <div>
            <Button
              onClick={history.goBack}
              color="primary"
              className="btn btn-xs"
            >
              Back
            </Button>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5>Manage Content</h5>
              </div>

              <Formik
                enableReinitialize
                initialValues={initValues}
                validationSchema={addEditProductsValSchema}
                onSubmit={(values) => {
                  if (id) {
                    dispatch(editProductAction(values, id));
                  } else {
                    dispatch(
                      addProductAction({
                        ...values,
                        url: values.url || undefined,
                      })
                    );
                  }
                }}
              >
                {() => (
                  <Form className="form form--horizontal">
                    <FormikModernField name="name" label="Name" />

                    <FormikModernField name="url" label="Link" textArea />

                    <FormikModernDropZone
                      name="icon"
                      label="Icon"
                      defaultImage={defaultImg}
                    />

                    <SubmitBtn />
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default AddEditProductsForm;

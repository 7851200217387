import React from 'react';
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge, Button } from 'reactstrap';

// UTILITIES
import { getImageUrl } from '../../../../../../utilities/helpers';
import { deleteInfoCardAction } from '../../../../../../redux/actions/homeActions';

function SectionTable() {
  const dispatch = useDispatch();

  const infoCardsData = useSelector(
    (state) => state.homePage.infoCardSectionData
  );

  const handleDelete = (id) => {
    dispatch(deleteInfoCardAction(id));
  };

  return (
    <div>
      <div className="text-right">
        <Link to="/pages/home/info-cards/add">
          <Button size="sm" color="primary">
            Add
          </Button>
        </Link>
      </div>

      <TableContainer>
        <Table className="table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Icon</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Sub-title</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {infoCardsData.length > 0 &&
              infoCardsData.map((item, idx) => (
                <TableRow key={item._id}>
                  <TableCell>{idx + 1}</TableCell>

                  <TableCell>
                    <img
                      src={getImageUrl(item.icon.url)}
                      alt="Card"
                      style={{ width: '70px', height: '70px' }}
                    />
                  </TableCell>

                  <TableCell className="w-25">{item.title}</TableCell>

                  <TableCell className="w-50">{item.subTitle}</TableCell>

                  <TableCell className="w-25" align="center">
                    <Link to={`/pages/home/info-cards/edit/${item._id}`}>
                      <Badge
                        role="button"
                        color="secondary"
                        type="button"
                        className="mr-1"
                      >
                        Edit
                      </Badge>
                    </Link>

                    <Badge
                      role="button"
                      color="danger"
                      type="button"
                      onClick={() => handleDelete(item._id)}
                    >
                      Delete
                    </Badge>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default SectionTable;

import React, { useEffect } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { FormikField } from '../../../../../../shared/components/form/Field';
import { FormikDropZoneField } from '../../../../../../shared/components/form/DropZone';
import {
  getHomeBannerData,
  saveHomeBannerData,
  clearHomeData,
} from '../../../../../../redux/actions/homeActions';
import { ROOT_URL } from '../../../../../../shared/helpers/constants';

const SectionCard = () => {
  const dispatch = useDispatch();
  const { bannerSectionData } = useSelector((state) => state.homePage);
  const { isLoading } = useSelector((state) => state.uiState);

  useEffect(() => {
    dispatch(getHomeBannerData());
    return () => {
      dispatch(clearHomeData());
    };
  }, []);

  const initValues = {
    topHeading: (bannerSectionData.heading && bannerSectionData.heading.topHeading) || '',
    mainHeading: (bannerSectionData.heading && bannerSectionData.heading.mainHeading) || '',
    subHeading: (bannerSectionData.heading && bannerSectionData.heading.subHeading) || '',
    description: (bannerSectionData.description && bannerSectionData.description) || '',
    sectionImageImage: '',
    backgroundImage: '',
  };

  const defaultImages = {
    sectionImage: bannerSectionData.sectionImage && ROOT_URL + bannerSectionData.sectionImage.url,
    backgroundImage: bannerSectionData.backgroundImage && ROOT_URL + bannerSectionData.backgroundImage.url,
  };

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Banner Section Content</h5>
            <h5 className="subhead">Edit Content</h5>
          </div>
          <div>
            <Formik
              enableReinitialize
              initialValues={initValues}
              validationSchema={Yup.object({
                topHeading: Yup.string().required('Required!'),
                mainHeading: Yup.string().required('Required!'),
                subHeading: Yup.string().required('Required!'),
                description: Yup.string().required('Required!'),
              })}
              onSubmit={(values) => {
                dispatch(saveHomeBannerData(values));
              }}
            >
              {({ setFieldValue,values,touched,errors }) => (
                <Form className="form form--horizontal text-left">
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Top Heading
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        name="topHeading"
                        value={values.topHeading}
                        touched={touched.topHeading}
                        error={errors.topHeading}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Main Heading
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        name="mainHeading"
                        value={values.mainHeading}
                        touched={touched.mainHeading}
                        error={errors.mainHeading}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Sub Heading
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        name="subHeading"
                        value={values.subHeading}
                        touched={touched.subHeading}
                        error={errors.subHeading}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Description
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        textArea
                        name="description"
                        value={values.description}
                        touched={touched.description}
                        error={errors.description}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Section Image
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikDropZoneField
                        name="sectionImage"
                        value={values.sectionImage}
                        setFieldValue={setFieldValue}
                        defaultImage={defaultImages.sectionImage}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Background Image
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikDropZoneField
                        name="backgroundImage"
                        value={values.backgroundImage}
                        setFieldValue={setFieldValue}
                        defaultImage={defaultImages.backgroundImage}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3">
                      <button
                        className="btn btn-primary btn-sm"
                        type="submit"
                        disabled={isLoading}
                      >
                        Save
                      </button>
                    </span>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SectionCard;

import toastify from '../../shared/helpers/toastNotification';
import createAsyncAction from '../../shared/helpers/createAsyncAction';
import {
    getFooterData,
    saveFooterData
} from '../../services/footerSectionServices';

export const GET_FOOTER_DATA = 'GET_FOOTER_DATA';
export const CLEAR_FOOTER_DATA = 'CLEAR_FOOTER_DATA';

export const clearFooterSectionData = () => ({ type: CLEAR_FOOTER_DATA });

export const getFooterSectionData = createAsyncAction(getFooterData, (res, dispatch) => {
    dispatch({ type: GET_FOOTER_DATA, payload: res.data });
});

export const saveFooterSectionData = createAsyncAction(saveFooterData, (res) => {
    toastify(res.data.message, 'success');
});
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row, Button, Card, CardBody } from 'reactstrap';
import MetaDataForm from '../../../../Common/MetaDataForm';
import {
  getHomeMetaData,
  saveHomeMetaData,
  clearHomeData,
} from '../../../../../redux/actions/homeActions';

const HomeMetaData = ({ history }) => {
  const dispatch = useDispatch();
  const { homeMetaData } = useSelector((state) => state.homePage);

  useEffect(() => {
    dispatch(getHomeMetaData('home'));
    return () => {
      dispatch(clearHomeData());
    };
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <Col xs={10}>
          <h3 className="page-title">Home Meta Data Section</h3>
        </Col>
        <Col xs={2} className="d-flex justify-content-end">
          <div>
            <Button
              onClick={history.goBack}
              color="primary"
              className="btn btn-xs"
            >
              Back
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">MetaData Content</h5>
                <h5 className="subhead">Edit Content</h5>
              </div>
              <div>
                <MetaDataForm
                  page="home"
                  data={homeMetaData}
                  handleSubmit={saveHomeMetaData}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

HomeMetaData.propTypes = {
  history: PropTypes.object.isRequired,
};

export default HomeMetaData;

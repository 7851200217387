import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';

// COMPONENTS
import CategoriesTableHead from './CategoriesTableHead';
import { deleteProductServiceAction } from '../../../../../../redux/actions/homeActions';

function CategoriesTable() {
  const dispatch = useDispatch();

  const { productsServices } = useSelector(
    (state) => state.homePage.productServicesSectionData
  );

  const handleDelete = (id) => {
    dispatch(deleteProductServiceAction(id));
  };

  return (
    <div>
      <div className="text-right">
        <Link to="/pages/home/product-services/add">
          <button type="button" className="btn btn-primary btn-sm">
            Add
          </button>
        </Link>
      </div>

      <TableContainer>
        <Table className="table">
          <CategoriesTableHead />

          <TableBody>
            {productsServices &&
              productsServices.length > 0 &&
              productsServices.map((row, idx) => (
                <TableRow key={row._id}>
                  <TableCell>{idx + 1}</TableCell>

                  <TableCell>{row.heading}</TableCell>

                  <TableCell className="w-50">
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: row.description }}
                    />
                  </TableCell>

                  <TableCell>{row.isService ? 'Service' : 'Product'}</TableCell>

                  <TableCell className="text-center">
                    <Link to={`/pages/home/product-services/edit/${row._id}`}>
                      <Badge
                        role="button"
                        color="secondary"
                        type="button"
                        className="mr-1"
                      >
                        Edit
                      </Badge>
                    </Link>

                    <Badge
                      role="button"
                      color="danger"
                      type="button"
                      onClick={() => handleDelete(row._id)}
                    >
                      Delete
                    </Badge>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default CategoriesTable;

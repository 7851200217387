import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { deleteFaq } from '../../../../../../redux/actions/homeActions';

function SectionTable() {
  const dispatch = useDispatch();
  const { faqs } = useSelector((state) => state.homePage.faqsSectionData);

  const handleDelete = (id) => {
    dispatch(deleteFaq(id));
  };

  return (
    <div>
      <div className="text-right">
        <Link to="/pages/home/faqs/add">
          <Button color="primary" size="sm">
            Add
          </Button>
        </Link>
      </div>

      <TableContainer>
        <Table className="table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {faqs.length > 0 &&
              faqs.map((item, idx) => (
                <TableRow key={item._id}>
                  <TableCell>{idx + 1}</TableCell>
                  <TableCell className="w-25">{item.title}</TableCell>
                  <TableCell className="w-50">{item.description}</TableCell>
                  <TableCell className="w-25" align="center">
                    <Link to={`/pages/home/faqs/edit/${item._id}`}>
                      <Badge
                        role="button"
                        color="secondary"
                        type="button"
                        className="mr-1"
                      >
                        Edit
                      </Badge>
                    </Link>

                    <Badge
                      role="button"
                      color="danger"
                      type="button"
                      onClick={() => handleDelete(item._id)}
                    >
                      Delete
                    </Badge>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default SectionTable;

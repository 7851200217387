import * as yup from 'yup';

export const getStartedSectionFormInitValues = {
  heading: '',
};

export const getStartedSectionFormValSchema = yup.object({
  heading: yup.string().required('Required'),
});

export const addEditFormInitVals = {
  title: '',
  description: '',
};

export const addEditFormValSchema = yup.object({
  title: yup.string().required('Required'),
  description: yup.string().required('Required'),
});

import React from 'react';
import propTypes from 'prop-types';

function SubmitBtn({ children, isSingle }) {
  return (
    <div className={`${isSingle ? '' : 'form__form-group'}`}>
      <span className={`form__form-group-label ${isSingle ? '' : 'col-lg-3'}`}>
        <button className="btn btn-primary btn-sm" type="submit">
          {children}
        </button>
      </span>
    </div>
  );
}

SubmitBtn.propTypes = {
  children: propTypes.node,
  isSingle: propTypes.bool,
};

SubmitBtn.defaultProps = {
  children: 'Save',
  isSingle: false,
};

export default SubmitBtn;

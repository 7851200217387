import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS & UTILITIES
import FormikModernField from '../../../../../../shared/components/form/FormikModernField';
import { faqFormInitValues, faqFormValSchema } from '../utilities/formUtils';
import {
  addNewWhyChooseFaq,
  getSingleWhyChooseFaq,
  updateWhyChooseFaq,
} from '../../../../../../redux/actions/homeActions';
import SubmitBtn from '../../../../../../shared/components/form/SubmitBtn';

function AddEditFaq() {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [initValues, setInitValues] = useState(faqFormInitValues);
  const { fetched } = useSelector((state) => state.homePage.whyChooseSectionData);

  useEffect(() => {
    if (id) {
      dispatch(getSingleWhyChooseFaq(id));
    }
  }, [id]);

  useEffect(() => {
    if (id && fetched) {
      setInitValues({
        ...initValues,
        question: fetched.question || '',
        answer: fetched.answer || '',
      });
    }
  }, [fetched]);

  return (
    <Container className="dashboard">
      <Row>
        <Col xs={10}>
          <h3 className="page-title">Why Choose Us Section</h3>
        </Col>
        <Col xs={2} className="d-flex justify-content-end">
          <div>
            <Button
              onClick={history.goBack}
              color="primary"
              className="btn btn-xs"
            >
              Back
            </Button>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <Formik
                enableReinitialize
                initialValues={initValues}
                validationSchema={faqFormValSchema}
                onSubmit={(values) => {
                  if (id) {
                    dispatch(updateWhyChooseFaq(values, id));
                  } else {
                    dispatch(addNewWhyChooseFaq(values));
                  }
                }}
              >
                {() => (
                  <Form className="form form--horizontal">
                    <FormikModernField name="question" label="Question*" />

                    <FormikModernField name="answer" label="Answer*" textArea />

                    <SubmitBtn />
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default AddEditFaq;

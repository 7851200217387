import React, { useEffect } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { FormikField } from '../../../../../../shared/components/form/Field';
import { FormikDropZoneField } from '../../../../../../shared/components/form/DropZone';
import {
  getSingleService,
  addNewService,
  updateService,
  clearServicePageData,
} from '../../../../../../redux/actions/serviceActions';
import DetailPageMetaDataForm from '../../../../../Common/DetailPageMetaDataForm';
import { ROOT_URL } from '../../../../../../shared/helpers/constants';

const ServiceForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { selectedService } = useSelector((state) => state.servicesPage);
  const { isLoading } = useSelector((state) => state.uiState);

  useEffect(() => {
    if (id) dispatch(getSingleService(id));
    return () => {
      dispatch(clearServicePageData());
    };
  }, []);

  const initValues = {
    heading: selectedService.heading || '',
    slug: selectedService.slug || '',
    serviceImage: '',
    serviceIcon: '',
    metaTitle: selectedService.metaTitle || '',
    metaDescription: selectedService.metaDescription || '',
    metaKeywords: selectedService.metaKeywords || '',
  };

  const defaultImages = {
    serviceImage:
      selectedService.serviceImage &&
      ROOT_URL + selectedService.serviceImage.url,
    serviceIcon:
      selectedService.serviceIcon && ROOT_URL + selectedService.serviceIcon.url,
  };

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Service Section Content</h5>
            <h5 className="subhead">Edit Content</h5>
          </div>
          <div>
            <Formik
              enableReinitialize
              initialValues={initValues}
              validationSchema={Yup.object({
                heading: Yup.string().required('Required!'),
                slug: Yup.string()
                  .required('Required!')
                  .matches(
                    /^[A-Za-z0-9-]+$/,
                    'Only Alphabets and (-) are allowed'
                  ),
              })}
              onSubmit={(values) => {
                if (id) dispatch(updateService({ id, values }));
                else dispatch(addNewService(values));
              }}
            >
              {({ setFieldValue, values, touched, errors }) => (
                <Form className="form form--horizontal text-left">
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Heading
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        name="heading"
                        value={values.heading}
                        touched={touched.heading}
                        error={errors.heading}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Slug
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <div className="form__form-group-icon">/service/</div>
                      <FormikField
                        name="slug"
                        value={values && values.slug}
                        touched={touched && touched.slug}
                        error={errors && errors.slug}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Service Icon
                    </span>
                    <div
                      className="form__form-group-field col-lg-5"
                      id="custom-dropzone"
                    >
                      <FormikDropZoneField
                        name="serviceIcon"
                        value={values.serviceIcon}
                        setFieldValue={setFieldValue}
                        defaultImage={defaultImages.serviceIcon}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Service Image
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikDropZoneField
                        name="serviceImage"
                        value={values.serviceImage}
                        setFieldValue={setFieldValue}
                        defaultImage={defaultImages.serviceImage}
                      />
                    </div>
                  </div>
                  {/* For Detail Page Meta Data */}
                  <DetailPageMetaDataForm />
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3">
                      <button
                        className="btn btn-primary btn-sm"
                        type="submit"
                        disabled={isLoading}
                      >
                        {id ? 'Update Service' : 'Add Service'}
                      </button>
                    </span>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ServiceForm;

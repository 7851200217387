import toastify from '../../shared/helpers/toastNotification';
import createAsyncAction from '../../shared/helpers/createAsyncAction';
import {
  getBannerData,
  saveBannerData,
  getServices,
  getService,
  addService,
  updateSingleService,
  deleteServiceData,
  getServiceDetailsData,
  getServiceDetailsSectionData,
  addServiceDetailsSectionData,
  updateServiceDetailsSectionData,
  deleteServiceDetailsSectionData,
  updateServiceRank,
} from '../../services/servicesPageServices';
import { getMetaData, saveMetaData } from '../../services/metaDataService';

export const CLEAR_SERVICES_PAGE_DATA = 'CLEAR_SERVICES_PAGE_DATA';

export const GET_SERVICES_BANNER_DATA = 'GET_SERVICES_BANNER_DATA';
export const GET_SERVICES = 'GET_SERVICES';
export const GET_SERVICE = 'GET_SERVICE';
export const GET_SERVICE_DETAILS = 'GET_SERVICE_DETAILS';
export const GET_SERVICE_DETAILS_SECTION = 'GET_SERVICE_DETAILS_SECTION';
export const GET_SERVICES_META_DATA = 'GET_SERVICES_META_DATA';

export const DELETE_SERVICE = 'DELETE_SERVICE';
export const DELETE_SERVICE_DETAILS_SECTION = 'DELETE_SERVICE_DETAILS_SECTION';

export const clearServicePageData = () => ({ type: CLEAR_SERVICES_PAGE_DATA });

export const getServicesMetaData = createAsyncAction(
  getMetaData,
  (res, dispatch) => {
    dispatch({ type: GET_SERVICES_META_DATA, payload: res.data });
  }
);

export const saveServicesMetaData = createAsyncAction(saveMetaData, (res) => {
  toastify(res.data.message, 'success');
});

export const getServicesBannerData = createAsyncAction(
  getBannerData,
  (res, dispatch) => {
    dispatch({ type: GET_SERVICES_BANNER_DATA, payload: res.data });
  }
);

export const saveBannerSectionData = createAsyncAction(
  saveBannerData,
  (res) => {
    toastify(res.data.message, 'success');
  }
);

export const getAllServices = createAsyncAction(
  getServices,
  (res, dispatch) => {
    dispatch({ type: GET_SERVICES, payload: res.data });
  }
);

export const getSingleService = createAsyncAction(
  getService,
  (res, dispatch) => {
    dispatch({ type: GET_SERVICE, payload: res.data });
  }
);

export const updateServiceOrder = createAsyncAction(
  updateServiceRank,
  (res, dispatch) => {
    dispatch({ type: GET_SERVICES, payload: res.data });
    toastify('Rank updated successfully', 'success');
  }
);

export const addNewService = createAsyncAction(addService, (res) => {
  toastify(res.data.message, 'success');
});

export const updateService = createAsyncAction(updateSingleService, (res) => {
  toastify(res.data.message, 'success');
});

export const deleteService = createAsyncAction(
  deleteServiceData,
  (res, dispatch) => {
    dispatch({ type: DELETE_SERVICE, payload: res.data });
    toastify('Service Deleted Successfully!', 'success');
  },
  false
);

export const getServiceDetails = createAsyncAction(
  getServiceDetailsData,
  (res, dispatch) => {
    dispatch({ type: GET_SERVICE_DETAILS, payload: res.data });
  }
);

export const getServiceDetailsSection = createAsyncAction(
  getServiceDetailsSectionData,
  (res, dispatch) => {
    dispatch({ type: GET_SERVICE_DETAILS_SECTION, payload: res.data });
  }
);

export const addNewServiceDetailsSection = createAsyncAction(
  addServiceDetailsSectionData,
  (res) => {
    toastify(res.data.message, 'success');
  }
);

export const updateServiceDetailsSection = createAsyncAction(
  updateServiceDetailsSectionData,
  (res) => {
    toastify(res.data.message, 'success');
  }
);

export const deleteServiceDetailsSection = createAsyncAction(
  deleteServiceDetailsSectionData,
  (res, dispatch) => {
    dispatch({ type: DELETE_SERVICE_DETAILS_SECTION, payload: res.data });
    toastify('Service Details Section Deleted Successfully!', 'success');
  },
  false
);

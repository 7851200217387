import http from './httpService';

const userKey = 'user';

export const login = (user) => http.post('/auth', user);

export const logout = () => http.delete('/auth');

export const setUser = (user) =>
  localStorage.setItem(userKey, JSON.stringify(user));

export const getUser = () => localStorage.getItem(userKey);

export const removeUser = () => localStorage.removeItem(userKey);

export const changeUserPassword = (data) =>
  http.post('/users/change-password', data);

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import Visits from './components/Visits';
import TotalPageViews from './components/TotalPageViews';
import NewUsers from './components/NewUsers';
import BounceRate from './components/BounceRate';
import ABTestingAnalytics from './components/ABTestingAnalytics';
import SalesStatistic from './components/SalesStatistic';
import VisitorsSessions from './components/VisitorsSessions';
import BounceRateArea from './components/BounceRateArea';
import AudienceByCountry from './components/AudienceByCountry';
import BudgetStatistic from './components/BudgetStatistic';
import { RTLProps } from '../../shared/prop-types/ReducerProps';

const DefaultDashboard = ({ rtl }) => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Dashboard</h3>
      </Col>
    </Row>
    <Row>
      <Visits />
      <TotalPageViews />
      <NewUsers />
      <BounceRate />
    </Row>
    <Row>
      <ABTestingAnalytics dir={rtl.direction} />
      <BounceRateArea dir={rtl.direction} />
      <VisitorsSessions dir={rtl.direction} />
      <SalesStatistic />
      <BudgetStatistic />
      <AudienceByCountry />
    </Row>
  </Container>
);

DefaultDashboard.propTypes = {
  rtl: RTLProps.isRequired,
};

export default connect((state) => ({
  rtl: state.rtl,
}))(DefaultDashboard);

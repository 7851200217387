import React, { useEffect } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { FormikField } from '../../../../../../shared/components/form/Field';
import { FormikDropZoneField } from '../../../../../../shared/components/form/DropZone';
import {
  getContactCardData,
  saveContactCardData,
  clearContactData
} from '../../../../../../redux/actions/contactActions';
import { ROOT_URL } from '../../../../../../shared/helpers/constants';

const SectionCard = () => {
  const dispatch = useDispatch();
  const { contactCardData } = useSelector((state) => state.contactPage);
  const { isLoading } = useSelector((state) => state.uiState);

  useEffect(() => {
    dispatch(getContactCardData());
    return () => {
      dispatch(clearContactData());
    }
  }, []);

  const initValues = {
    heading: contactCardData.heading || '',
    address: contactCardData.address || '',
    phone: contactCardData.phone || '',
    email: contactCardData.email || '',
    backgroundImage: '',
  };

  const defaultImages = {
    backgroundImage: contactCardData.backgroundImage && ROOT_URL + contactCardData.backgroundImage.url,
  };

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Banner Section Content</h5>
            <h5 className="subhead">Edit Content</h5>
          </div>
          <div>
            <Formik
              enableReinitialize
              initialValues={initValues}
              validationSchema={Yup.object({
                heading: Yup.string().required('Required!'),
                address: Yup.string().required('Required!'),
                phone: Yup.number('Phone Number must be an integrer value').required('Required!'),
                email: Yup.string().email("Invalid Email").required('Required!'),
              })}
              onSubmit={(values) => {
                dispatch(saveContactCardData(values));
              }}
            >
              {({ setFieldValue, values, touched, errors }) => (
                <Form className="form form--horizontal text-left">
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Heading
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        name="heading"
                        value={values.heading}
                        touched={touched.heading}
                        error={errors.heading}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Address
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        name="address"
                        value={values.address}
                        touched={touched.address}
                        error={errors.address}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Phone
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        name="phone"
                        value={values.phone}
                        touched={touched.phone}
                        error={errors.phone}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Email
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        name="email"
                        value={values.email}
                        touched={touched.email}
                        error={errors.email}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Background Image
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikDropZoneField
                        name="backgroundImage"
                        value={values.backgroundImage}
                        setFieldValue={setFieldValue}
                        defaultImage={defaultImages.backgroundImage}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3">
                      <button
                        className="btn btn-primary btn-sm"
                        type="submit"
                        disabled={isLoading}
                      >
                        Save
                      </button>
                    </span>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SectionCard;

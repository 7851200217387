import { TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';

function CategoriesTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell>#</TableCell>
        <TableCell>Heading</TableCell>
        <TableCell>Content</TableCell>
        <TableCell>Type</TableCell>
        <TableCell align="center">Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default CategoriesTableHead;

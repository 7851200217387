/* eslint-disable */
import React, { PureComponent } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormikField } from '../../../shared/components/form/Field';
import { loginUser } from '../../../redux/actions/authActions';

const initValues = {
  email: '',
  password: '',
};

class LogInForm extends PureComponent {
  static propTypes = {
    doLogin: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  constructor() {
    super();
    this.state = {
      showPassword: false,
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const { doLogin, isAuthenticated, isLoading } = this.props;
    const { showPassword } = this.state;

    if (isAuthenticated) return <Redirect to="/pages/dashboard" />;

    return (
      <Formik
        initialValues={initValues}
        validationSchema={Yup.object({
          email: Yup.string()
            .email('Invalid Email Address')
            .required('Email is Required!'),
          password: Yup.string().required('Password is Required!'),
        })}
        onSubmit={(values) => {
          doLogin(values);
        }}
      >
        {({ values, touched, errors }) => (
          <Form className="form">
            <div className="form__form-group">
              <span className="form__form-group-label">Email</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <AccountOutlineIcon color="#fff" />
                </div>
                <FormikField
                  name="email"
                  placeholder="Email Address"
                  value={values.email}
                  touched={touched.email}
                  error={errors.email}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Password</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <KeyVariantIcon />
                </div>
                <FormikField
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Password"
                  value={values.password}
                  touched={touched.password}
                  error={errors.password}
                />
                <button
                  className={`form__form-group-button${showPassword ? ' active' : ''}`}
                  onClick={(e) => this.showPassword(e)}
                  type="button"
                >
                  <EyeIcon />
                </button>
              </div>
            </div>
            <Button
              color="primary"
              type="submit"
              className="btn btn-primary account__btn account__btn--small"
              to="/pages"
              disabled={isLoading}
            >
              {isLoading ? 'Sigining In...' : 'Sign In'}
            </Button>
          </Form>
        )}
      </Formik>
    );
  }
}

export default connect(
  ({ auth, uiState }) => ({
    isAuthenticated: auth.isAuthenticated,
    isLoading: uiState.isLoading,
  }),
  {
    doLogin: loginUser,
  }
)(LogInForm);

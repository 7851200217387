import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Table, Badge } from 'reactstrap';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { FormikField } from '../../../../../../shared/components/form/Field';
import {
  getAboutDetailsData,
  saveAboutDetailsData,
  clearAboutData
} from '../../../../../../redux/actions/aboutActions';
import { ROOT_URL } from '../../../../../../shared/helpers/constants';

const SectionCard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { aboutDetailsData } = useSelector((state) => state.aboutPage);
  const { isLoading } = useSelector((state) => state.uiState);

  useEffect(() => {
    dispatch(getAboutDetailsData());
    return () => {
      dispatch(clearAboutData());
    }
  }, []);

  const initValues = {
    heading: (aboutDetailsData.basicData && aboutDetailsData.basicData.heading) || '',
    description: (aboutDetailsData.basicData && aboutDetailsData.basicData.description) || '',
  };

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">About Details Content</h5>
            <h5 className="subhead">Edit Content</h5>
          </div>
          <div>
            <Formik
              enableReinitialize
              initialValues={initValues}
              validationSchema={Yup.object({
                heading: Yup.string().required('Required!'),
                description: Yup.string().required('Required!'),
              })}
              onSubmit={(values) => {
                dispatch(saveAboutDetailsData(values));
              }}
            >
              {({ values, touched, errors}) => (
                <Form className="form form--horizontal text-left">
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Heading
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        name="heading"
                        value={values.heading}
                        touched={touched.heading}
                        error={errors.heading}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Description
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        textArea
                        name="description"
                        value={values.description}
                        touched={touched.description}
                        error={errors.description}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <h5 className="bold-text">Details Sections</h5>
                  </div>
                  <div className="form__form-group">
                    <Table responsive hover>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Image</th>
                          <th className="w-25">Heading</th>
                          <th className="w-50">Description</th>
                          <th className="text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {aboutDetailsData
                          && aboutDetailsData.sectionData
                            && aboutDetailsData.sectionData.map((item,index) => (
                              <tr key={item._id}>
                                <td>{index+1}</td>
                                <td>
                                  <img
                                    src={ROOT_URL + item.sectionImage.url}
                                    alt="icon"
                                    style={{ height: 70, width: 70 }}
                                  />
                                </td>
                                <td className="w-25">{item.heading}</td>
                                <td className="w-50">{item.description}</td>
                                <td className="w-25 text-center">
                                  <Badge
                                    role="button"
                                    color="secondary"
                                    type="button"
                                    className="mr-1"
                                    onClick={() => {
                                      history.push(`/pages/about/about-details/${item._id}/edit`)
                                    }}
                                  >
                                    Edit
                                  </Badge>
                                </td>
                              </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3">
                      <button
                        className="btn btn-primary btn-sm"
                        type="submit"
                        disabled={isLoading}
                      >
                        Save
                      </button>
                    </span>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SectionCard;

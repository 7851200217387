import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row, Button } from 'reactstrap';
import SectionCard from './components/SectionCard';

const ContactBanner = ({ history }) => (
  <Container className="dashboard">
    <Row>
      <Col xs={10}>
        <h3 className="page-title">Contact Banner Section</h3>
      </Col>
      <Col xs={2} className="d-flex justify-content-end">
        <div>
          <Button onClick={history.goBack} color="primary" className="btn btn-xs">
            Back
          </Button>
        </div>
      </Col>
    </Row>
    <Row>
      <SectionCard />
    </Row>
  </Container>
);

ContactBanner.propTypes = {
  history: PropTypes.object.isRequired,
};

export default ContactBanner;

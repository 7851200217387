import toastify from '../../shared/helpers/toastNotification';
import createAsyncAction from '../../shared/helpers/createAsyncAction';
import {
  getBannerData,
  saveBannerData,
  getTrendingData,
  saveTrendingData,
  getBlogs,
  getBlog,
  getComment,
  addNewReply,
  updateSingleComment,
  approveSingleComment,
  deleteSingleComment,
  getSingleBlogComments,
  getSingleBlogReplies,
  addBlog,
  updateBlogData,
  deleteBlogData,
} from '../../services/blogsPageServices';
import { getMetaData, saveMetaData } from '../../services/metaDataService';

export const CLEAR_BLOG_DATA = 'CLEAR_BLOG_DATA';

// Blog Constants
export const GET_BLOG_BANNER_DATA = 'GET_BLOG_BANNER_DATA';
export const GET_BLOG_TRENDING_DATA = 'GET_BLOG_TRENDING_DATA';
export const GET_BLOG_COMMENTS = 'GET_BLOG_COMMENTS';
export const GET_BLOG_REPLIES = 'GET_BLOG_REPLIES';
export const GET_BLOGS = 'GET_BLOGS';
export const GET_BLOG = 'GET_BLOG';
export const GET_COMMENT = 'GET_COMMENT';
export const APPROVE_COMMENT = 'APPROVE_COMMENT';
export const GET_BLOG_META_DATA = 'GET_BLOG_META_DATA';

export const DELETE_BLOG = 'DELETE_BLOG';
export const DELETE_COMMENT = 'DELETE_COMMENT';

export const clearBlogData = () => ({ type: CLEAR_BLOG_DATA });

export const getBlogMetaData = createAsyncAction(
  getMetaData,
  (res, dispatch) => {
    dispatch({ type: GET_BLOG_META_DATA, payload: res.data });
  }
);

export const saveBlogMetaData = createAsyncAction(saveMetaData, (res) => {
  toastify(res.data.message, 'success');
});

export const getBlogsBannerData = createAsyncAction(
  getBannerData,
  (res, dispatch) => {
    dispatch({ type: GET_BLOG_BANNER_DATA, payload: res.data });
  }
);

export const saveBannerSectionData = createAsyncAction(
  saveBannerData,
  (res) => {
    toastify(res.data.message, 'success');
  }
);

export const getBlogsTrendingData = createAsyncAction(
  getTrendingData,
  (res, dispatch) => {
    dispatch({ type: GET_BLOG_TRENDING_DATA, payload: res.data });
  }
);

export const saveTrendingSectionData = createAsyncAction(
  saveTrendingData,
  (res) => {
    toastify(res.data.message, 'success');
  }
);

export const getAllBlogs = createAsyncAction(getBlogs, (res, dispatch) => {
  dispatch({ type: GET_BLOGS, payload: res.data });
});

export const getSingleBlog = createAsyncAction(getBlog, (res, dispatch) => {
  dispatch({ type: GET_BLOG, payload: res.data });
});

export const getSingleComment = createAsyncAction(
  getComment,
  (res, dispatch) => {
    dispatch({ type: GET_COMMENT, payload: res.data });
  }
);

export const postNewReply = createAsyncAction(addNewReply, (res) => {
  toastify(res.data.message, 'success');
});

export const updateComment = createAsyncAction(updateSingleComment, (res) => {
  toastify(res.data.message, 'success');
});

export const approveComment = createAsyncAction(
  approveSingleComment,
  (res, dispatch) => {
    dispatch({ type: APPROVE_COMMENT, payload: res.data });
    toastify('Comment Approved Successfully!', 'success');
  },
  false
);

export const deleteComment = createAsyncAction(
  deleteSingleComment,
  (res, dispatch) => {
    dispatch({ type: DELETE_COMMENT, payload: res.data });
    toastify('Comment Removed Successfully!', 'success');
  },
  false
);

export const getBlogComments = createAsyncAction(
  getSingleBlogComments,
  (res, dispatch) => {
    dispatch({ type: GET_BLOG_COMMENTS, payload: res.data });
  }
);

export const getBlogReplies = createAsyncAction(
  getSingleBlogReplies,
  (res, dispatch) => {
    dispatch({ type: GET_BLOG_REPLIES, payload: res.data });
  }
);

export const addNewBlog = createAsyncAction(addBlog, (res) => {
  toastify(res.data.message, 'success');
});

export const updateBlog = createAsyncAction(updateBlogData, (res) => {
  toastify(res.data.message, 'success');
});

export const deleteBlog = createAsyncAction(
  deleteBlogData,
  (res, dispatch) => {
    dispatch({ type: DELETE_BLOG, payload: res.data });
    toastify('Blog Deleted Successfully!', 'success');
  },
  false
);

import {
  SET_LOADING,
  UNSET_LOADING,
  DISPLAY_TOAST,
  HIDE_TOAST,
  CLEAR_FORM_DATA,
} from '../actions/uiActions';

const initialState = {
  isLoading: false,
  toast: {},
  clearForm: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case UNSET_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case DISPLAY_TOAST:
      return {
        ...state,
        toast: action.payload,
      };
    case HIDE_TOAST:
      return {
        ...state,
        toast: {},
      };
    case CLEAR_FORM_DATA:
      return {
        ...state,
        clearForm: !state.clearForm,
      };
    default:
      return state;
  }
}

export const SET_LOADING = 'SET_LOADING';
export const UNSET_LOADING = 'UNSET_LOADING';
export const DISPLAY_TOAST = 'DISPLAY_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';
export const CLEAR_FORM_DATA = 'CLEAR_FORM_DATA';

export const setLoading = () => ({ type: SET_LOADING });

export const unSetLoading = () => ({ type: UNSET_LOADING });

export const displayToast = (message, type) => ({
  type: DISPLAY_TOAST,
  payload: {
    message,
    type,
    openToast: true,
  },
});

export const hideToast = () => ({ type: HIDE_TOAST });

export const clearFormData = () => ({ type: CLEAR_FORM_DATA });

import toastify from '../../shared/helpers/toastNotification';
import createAsyncAction from '../../shared/helpers/createAsyncAction';
import {
  getBannerData,
  saveBannerData,
  getAboutDetails,
  saveAboutDetails,
  getAboutDetailsSection,
  saveAboutDetailsSection,
  getAboutPerformance,
  savePerformanceData,
} from '../../services/aboutPageServices';
import { getMetaData, saveMetaData } from '../../services/metaDataService';

export const GET_ABOUT_BANNER_DATA = 'GET_ABOUT_BANNER_DATA';
export const CLEAR_ABOUT_DATA = 'CLEAR_ABOUT_DATA';
export const GET_ABOUT_DETAILS_DATA = 'GET_ABOUT_DETAILS_DATA';
export const GET_ABOUT_DETAILS_SECTION_DATA = 'GET_ABOUT_DETAILS_SECTION_DATA';
export const GET_ABOUT_PERFORMANCE_DATA = 'GET_ABOUT_PERFORMANCE_DATA';
export const GET_ABOUT_META_DATA = 'GET_ABOUT_META_DATA';

export const clearAboutData = () => ({ type: CLEAR_ABOUT_DATA });

export const getAboutMetaData = createAsyncAction(
  getMetaData,
  (res, dispatch) => {
    dispatch({ type: GET_ABOUT_META_DATA, payload: res.data });
  }
);

export const saveAboutMetaData = createAsyncAction(saveMetaData, (res) => {
  toastify(res.data.message, 'success');
});

export const getAboutBannerData = createAsyncAction(
  getBannerData,
  (res, dispatch) => {
    dispatch({ type: GET_ABOUT_BANNER_DATA, payload: res.data });
  }
);

export const saveBannerSectionData = createAsyncAction(
  saveBannerData,
  (res) => {
    toastify(res.data.message, 'success');
  }
);

export const getAboutDetailsData = createAsyncAction(
  getAboutDetails,
  (res, dispatch) => {
    dispatch({ type: GET_ABOUT_DETAILS_DATA, payload: res.data });
  }
);

export const saveAboutDetailsData = createAsyncAction(
  saveAboutDetails,
  (res) => {
    toastify(res.data.message, 'success');
  }
);

export const getAboutDetailsSectionData = createAsyncAction(
  getAboutDetailsSection,
  (res, dispatch) => {
    dispatch({ type: GET_ABOUT_DETAILS_SECTION_DATA, payload: res.data });
  }
);

export const saveAboutDetailsSectionData = createAsyncAction(
  saveAboutDetailsSection,
  (res) => {
    toastify(res.data.message, 'success');
  }
);

export const getAboutPerformanceData = createAsyncAction(
  getAboutPerformance,
  (res, dispatch) => {
    dispatch({ type: GET_ABOUT_PERFORMANCE_DATA, payload: res.data });
  }
);

export const savePerformanceSectionData = createAsyncAction(
  savePerformanceData,
  (res) => {
    toastify(res.data.message, 'success');
  }
);

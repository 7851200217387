import {
  GET_SERVICES_META_DATA,
  GET_SERVICES_BANNER_DATA,
  GET_SERVICES,
  GET_SERVICE,
  DELETE_SERVICE,
  GET_SERVICE_DETAILS,
  GET_SERVICE_DETAILS_SECTION,
  DELETE_SERVICE_DETAILS_SECTION,
  CLEAR_SERVICES_PAGE_DATA,
} from '../actions/serviceActions';

const initialState = {
  bannerSectionData: {},
  services: [],
  selectedService: {},
  selectedServiceDetails: [],
  selectedServiceDetailsSection: {},
  servicesMetaData: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CLEAR_SERVICES_PAGE_DATA:
      return {
        ...state,
        bannerSectionData: {},
        services: [],
        selectedService: {},
        selectedServiceDetails: [],
        selectedServiceDetailsSection: {},
        servicesMetaData: {},
      };
    case GET_SERVICES_META_DATA:
      return {
        ...state,
        servicesMetaData: action.payload,
      };
    case GET_SERVICES_BANNER_DATA:
      return {
        ...state,
        bannerSectionData: action.payload,
      };
    case GET_SERVICES:
      return {
        ...state,
        services: action.payload,
      };
    case GET_SERVICE:
      return {
        ...state,
        selectedService: action.payload,
      };
    case DELETE_SERVICE:
      return {
        ...state,
        services: state.services.filter(
          (service) => service._id !== action.payload.id
        ),
      };
    case GET_SERVICE_DETAILS:
      return {
        ...state,
        selectedServiceDetails: action.payload,
      };
    case GET_SERVICE_DETAILS_SECTION:
      return {
        ...state,
        selectedServiceDetailsSection: action.payload,
      };
    case DELETE_SERVICE_DETAILS_SECTION:
      return {
        ...state,
        selectedServiceDetails: state.selectedServiceDetails.filter(
          (section) => section._id !== action.payload.id
        ),
      };
    default:
      return state;
  }
}

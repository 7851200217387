import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import SectionTable from './SectionTable';

function SectionCard() {
  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Info Cards Content</h5>
            <h5 className="subhead">Manage Content</h5>
          </div>

          <SectionTable />
        </CardBody>
      </Card>
    </Col>
  );
}

export default SectionCard;

import * as yup from 'yup';

export const addEditFormInitVals = {
  heading: '',
  description: '',
  isService: false,
};
export const addEditFormValSchema = yup.object({
  heading: yup.string().required('Required'),
  description: yup.string().required('Required'),
  isService: yup.boolean().required('Please select one'),
});

import {
    GET_HEADER_DATA,
    CLEAR_HEADER_DATA
} from '../actions/headerActions';

const initialState = {
    headerData: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_HEADER_DATA:
            return {
                ...state,
                headerData: action.payload,
            };
        case CLEAR_HEADER_DATA:
            return {
                ...state,
                headerData: {},
            };
        default:
            return state;
    }
}

import toastify from '../../shared/helpers/toastNotification';
import createAsyncAction from '../../shared/helpers/createAsyncAction';
import {
  deletePortfolioImage,
  getPortfolioImages,
  getPortfolioPageData,
  savePortfolioImages,
  savePortfolioPageData,
} from '../../services/portfolioPageServices';
import { clearFormData } from './uiActions';

export const GET_PORTFOLIO_DATA = 'GET_PORTFOLIO_DATA';
export const CLEAR_PORTFOLIO_DATA = 'CLEAR_PORTFOLIO_DATA';
export const GET_PORTFOLIO_IMAGES = 'GET_PORTFOLIO_IMAGES';
export const ADD_PORTFOLIO_IMAGES = 'ADD_PORTFOLIO_IMAGES';
export const CLEAR_PORTFOLIO_IMAGES = 'CLEAR_PORTFOLIO_IMAGES';
export const DELETE_PORTFOLIO_IMAGE = 'DELETE_PORTFOLIO_IMAGE';

export const clearPortfolioData = () => ({ type: CLEAR_PORTFOLIO_DATA });

export const clearPortfolioImages = () => ({ type: CLEAR_PORTFOLIO_IMAGES });

export const getPortfolioData = createAsyncAction(
  getPortfolioPageData,
  (res, dispatch) => {
    dispatch({ type: GET_PORTFOLIO_DATA, payload: res.data });
  }
);

export const savePortfolioContent = createAsyncAction(savePortfolioPageData, (res) => {
  toastify(res.data.message, 'success');
});

export const getImages = createAsyncAction(getPortfolioImages, (res, dispatch) => {
  dispatch({ type: GET_PORTFOLIO_IMAGES, payload: res.data });
});

export const addImages = createAsyncAction(savePortfolioImages, (res, dispatch) => {
  dispatch({ type: ADD_PORTFOLIO_IMAGES, payload: res.data });
  dispatch(clearFormData());
  toastify('Images added successfully!', 'success');
});

export const removeImage = createAsyncAction(
  deletePortfolioImage,
  (res, dispatch) => {
    dispatch({ type: DELETE_PORTFOLIO_IMAGE, payload: res.data });
    toastify('Portfolio Image Deleted Successfully!');
  },
  false
);

import http, { multipartConfig } from './httpService';

export const getBannerData = () => {
  return http.get('/about/banner');
};

export const saveBannerData = (values) => {
  const data = { ...values };

  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'string') {
      if (value) {
        formData.append(key, value);
      }
    }
  });

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item);
      });
    }
  });

  return http.post('/about/banner', formData, multipartConfig);
};

export const getAboutDetails = () => {
  return http.get('/about/about-details');
};

export const saveAboutDetails = (values) => {
  return http.post('/about/about-details', values);
};

export const getAboutDetailsSection = (id) => {
  return http.get(`/about/about-details/sections/${id}`);
};

export const saveAboutDetailsSection = ({ id, values }) => {
  const data = { ...values };

  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'string') {
      if (value) {
        formData.append(key, value);
      }
    }
  });

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item);
      });
    }
  });

  return http.put(
    `/about/about-details/sections/${id}`,
    formData,
    multipartConfig
  );
};

export const getAboutPerformance = () => {
  return http.get('/about/about-performance');
};

export const savePerformanceData = (values) => {
  const data = { ...values };

  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'string' || typeof value === 'number') {
      if (value) {
        formData.append(key, value);
      }
    }
  });

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item);
      });
    }
  });

  return http.post('/about/about-performance', formData, multipartConfig);
};

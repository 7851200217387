import toastify from '../../shared/helpers/toastNotification';
import createAsyncAction from '../../shared/helpers/createAsyncAction';
import {
  getContactFormData,
  getContactFormById,
  getBannerData,
  saveBannerData,
  getContactCard,
  saveContactCard,
} from '../../services/contactPageServices';
import { getMetaData, saveMetaData } from '../../services/metaDataService';

export const CLEAR_CONTACT_PAGE_DATA = 'CLEAR_CONTACT_PAGE_DATA';
export const GET_CONTACT_FORMS_DATA = 'GET_CONTACT_FORMS_DATA';
export const CLEAR_CONTACT_FORMS_DATA = 'CLEAR_CONTACT_FORMS_DATA';
export const GET_CONTACT_FORM = 'GET_CONTACT_FORM';
export const CLEAR_CONTACT_FORM = 'CLEAR_CONTACT_FORM';
export const GET_CONTACT_BANNER_DATA = 'GET_CONTACT_BANNER_DATA';
export const GET_CONTACT_CARD_DATA = 'GET_CONTACT_CARD_DATA';
export const GET_CONTACT_META_DATA = 'GET_CONTACT_META_DATA';

export const getContactMetaData = createAsyncAction(
  getMetaData,
  (res, dispatch) => {
    dispatch({ type: GET_CONTACT_META_DATA, payload: res.data });
  }
);

export const saveContactMetaData = createAsyncAction(saveMetaData, (res) => {
  toastify(res.data.message, 'success');
});

export const getContactForm = createAsyncAction(
  getContactFormById,
  (res, dispatch) => {
    dispatch({ type: GET_CONTACT_FORM, payload: res.data });
  }
);

export const clearContactData = () => ({ type: CLEAR_CONTACT_PAGE_DATA });

export const clearContactForms = () => ({ type: CLEAR_CONTACT_FORMS_DATA });

export const clearContactForm = () => ({ type: CLEAR_CONTACT_FORM });

export const getContactBannerData = createAsyncAction(
  getBannerData,
  (res, dispatch) => {
    dispatch({ type: GET_CONTACT_BANNER_DATA, payload: res.data });
  }
);

export const saveBannerSectionData = createAsyncAction(
  saveBannerData,
  (res) => {
    toastify(res.data.message, 'success');
  }
);

export const getContactCardData = createAsyncAction(
  getContactCard,
  (res, dispatch) => {
    dispatch({ type: GET_CONTACT_CARD_DATA, payload: res.data });
  }
);

export const saveContactCardData = createAsyncAction(saveContactCard, (res) => {
  toastify(res.data.message, 'success');
});

export const getContactForms = createAsyncAction(
  getContactFormData,
  (res, dispatch) => {
    dispatch({ type: GET_CONTACT_FORMS_DATA, payload: res.data });
  }
);

import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Card, CardBody, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

// COMPONENTS & UTILITIES
import { FormikField } from '../../../../../../shared/components/form/Field';
import FormikTextEditor from '../../../../../../shared/components/form/FormikTextEditor';
import RadioButtons from '../../../../../../shared/components/form/FormikRadioButtons';
import {
  addEditFormInitVals,
  addEditFormValSchema,
} from '../utilities/formUtils';
import SubmitBtn from '../../../../../../shared/components/form/SubmitBtn';
import {
  addProductServiceAction,
  getSingleProductService,
  updateProductServiceAction,
} from '../../../../../../redux/actions/homeActions';

function AddEditFormProductService() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const [initialValues, setInitialValues] = useState(addEditFormInitVals);
  const { fetched } = useSelector(
    (state) => state.homePage.productServicesSectionData
  );

  useEffect(() => {
    if (id) {
      dispatch(getSingleProductService(id));
    }
  }, []);

  useEffect(() => {
    if (id && fetched) {
      setInitialValues(prevState => ({
        ...prevState,
        heading: fetched.heading,
        isService: fetched.isService ? 'true' : 'false',
      }));
    }
  }, [fetched]);

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Product Service content</h5>
          </div>

          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={addEditFormValSchema}
            onSubmit={(values) => {
              if (id) {
                dispatch(updateProductServiceAction(values, id));
              } else {
                dispatch(addProductServiceAction(values));
              }
            }}
          >
            {({ values, touched, errors, setFieldValue }) => (
              <Form className="form form--horizontal">
                <div className="form__form-group">
                  <span className="form__form-group-label col-lg-3 text-nowrap required">
                    Title
                  </span>

                  <div className="form__form-group-field col-lg-5">
                    <FormikField
                      name="heading"
                      value={values.heading}
                      touched={touched.heading}
                      error={errors.heading}
                    />
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label col-lg-3 text-nowrap required">
                    Description
                  </span>

                  <div className="form__form-group-field col">
                    <FormikTextEditor
                      name="description"
                      defaultValue={fetched && fetched.description || ''}
                      touched={touched.description}
                      error={errors.description}
                      onChange={setFieldValue}
                    />
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label col-lg-3 text-nowrap required">
                    Select type
                  </span>

                  <div className="form__form-group-field col-lg-5">
                    <RadioButtons
                      name="isService"
                      options={[
                        { value: 'true', label: 'Service' },
                        { value: 'false', label: 'Product' },
                      ]}
                    />
                  </div>
                </div>

                <div className="row mx-0 mt-3">
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={() => history.push('/pages/home/product-services')}
                  >
                    Back
                  </button>

                  <SubmitBtn isSingle />
                </div>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </Col>
  );
}

export default AddEditFormProductService;

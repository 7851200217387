import {
  addProductMutation,
  deleteProductMutation,
  editProductMutation,
  fetchProduct,
  getProductsQuery,
  updateProductRankMutation,
} from '../../services/productsServices';
import toastify from '../../shared/helpers/toastNotification';
import createAsyncAction from '../../shared/helpers/createAsyncAction';

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const FETCH_PRODUCT = 'FETCH_PRODUCT';

export const getProductsAction = createAsyncAction(
  getProductsQuery,
  (res, dispatch) => {
    dispatch({
      type: GET_PRODUCTS,
      payload: res.data,
    });
  }
);

export const addProductAction = createAsyncAction(
  addProductMutation,
  (res, dispatch) => {
    dispatch({
      type: ADD_PRODUCT,
      payload: res.data,
    });
    toastify('Product Added Successfully!', 'success');
  }
);

export const editProductAction = createAsyncAction(editProductMutation, () => {
  toastify('Product Edited Successfully!', 'success');
});

export const deleteProductAction = createAsyncAction(
  deleteProductMutation,
  (res, dispatch) => {
    dispatch({ type: DELETE_PRODUCT, payload: res.data });
    toastify('Product Deleted Successfully!', 'success');
  }
);

export const getSingleProductAction = createAsyncAction(
  fetchProduct,
  (res, dispatch) => {
    dispatch({ type: FETCH_PRODUCT, payload: res.data });
  }
);

// UPDATE PRODUCT RANK
export const updateProductRankAction = createAsyncAction(
  updateProductRankMutation,
  (res, dispatch) => {
    dispatch({ type: GET_PRODUCTS, payload: res.data });
    toastify('Rank Updated Successfully!', 'success');
  }
);

import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { Col, Container, Row, Button } from 'reactstrap';
import SectionCard from './components/SectionCard';
import AboutDetailsForm from './components/AboutDetailsForm';

const AboutDetails = ({ history }) => (
  <Container className="dashboard">
    <Row>
      <Col xs={10}>
        <h3 className="page-title">About Details Section</h3>
      </Col>
      <Col xs={2} className="d-flex justify-content-end">
        <div>
          <Button onClick={history.goBack} color="primary" className="btn btn-xs">
            Back
          </Button>
        </div>
      </Col>
    </Row>
    <Row>
      <Switch>
        <Route
          exact
          path="/pages/about/about-details/:id/edit"
          component={AboutDetailsForm}
        />
        <Route
          exact
          path="/pages/about/about-details"
          component={SectionCard}
        />
      </Switch>
    </Row>
  </Container>
);

AboutDetails.propTypes = {
  history: PropTypes.object.isRequired,
};

export default AboutDetails;

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row, Button } from 'reactstrap';
import { Switch, Route } from 'react-router-dom';
import SectionCard from './components/SectionCard';

const HomeWhatWeDo = ({ history }) => {

  return (
    <Container className="dashboard">
      <Row>
        <Col xs={10}>
          <h3 className="page-title">Home What We Do Section</h3>
        </Col>
        <Col xs={2} className="d-flex justify-content-end">
          <div>
            <Button onClick={history.goBack} color="primary" className="btn btn-xs">
              Back
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Switch>
          <Route
            path="/pages/home/what-we-do"
            component={SectionCard}
          />
        </Switch>
      </Row>
    </Container>
  );
}

HomeWhatWeDo.propTypes = {
  history: PropTypes.object.isRequired,
};

export default HomeWhatWeDo;

import React, { useEffect } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { FormikDropZoneMultiField } from '../../../../../../shared/components/form/DropZoneMultiple';
import {
  clearHomeData,
  getHomePortfolioData,
  deleteHomePortfolioSectionImage,
  savePortfolioSectionData
} from '../../../../../../redux/actions/homeActions';
import { ROOT_URL } from '../../../../../../shared/helpers/constants';

const SectionCard = () => {
  const dispatch = useDispatch();
  const { portfolioSectionData } = useSelector((state) => state.homePage);
  const { isLoading } = useSelector((state) => state.uiState);

  useEffect(() => {
    dispatch(getHomePortfolioData());
    return () => {
      dispatch(clearHomeData());
    }
  }, []);

  const defaultImages = {
    portfolioImages: portfolioSectionData.images && portfolioSectionData.images.map((item) => ({
      id: item._id,
      url: ROOT_URL + item.url,
    })),
  };

  const handleDeletePortfolioImage = (id) => {
    return dispatch(deleteHomePortfolioSectionImage(id));
  };

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Portfolio Section Content</h5>
            <h5 className="subhead">Edit Content</h5>
          </div>
          <div>
            <Formik
              enableReinitialize
              initialValues={{portfolioImages:""}}
              onSubmit={(values) => {
                dispatch(savePortfolioSectionData(values));
              }}
            >
              {({ setFieldValue, values }) => (
                <Form className="form form--horizontal text-left">
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Portfolio Images
                    </span>
                    <div className="form__form-group-field col-lg-8">
                      <FormikDropZoneMultiField
                        name="portfolioImages"
                        value={values.portfolioImages}
                        setFieldValue={setFieldValue}
                        defaultImages={defaultImages.portfolioImages}
                        deleteDefaultImage={handleDeletePortfolioImage}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3">
                      <button
                        className="btn btn-primary btn-sm"
                        type="submit"
                        disabled={isLoading}
                      >
                        Save
                      </button>
                    </span>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SectionCard;

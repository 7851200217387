import React, { useEffect } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { FormikField } from '../../../../../../shared/components/form/Field';
import { FormikDropZoneField } from '../../../../../../shared/components/form/DropZone';
import {
  getAboutPerformanceData,
  savePerformanceSectionData,
  clearAboutData,
} from '../../../../../../redux/actions/aboutActions';
import { ROOT_URL } from '../../../../../../shared/helpers/constants';

const SectionCard = () => {
  const dispatch = useDispatch();
  const { aboutPerformanceData } = useSelector((state) => state.aboutPage);
  const { isLoading } = useSelector((state) => state.uiState);

  useEffect(() => {
    dispatch(getAboutPerformanceData());
    return () => {
      dispatch(clearAboutData());
    };
  }, []);

  const initValues = {
    heading: aboutPerformanceData.heading || '',
    description: aboutPerformanceData.description || '',
    happyClients: aboutPerformanceData.happyClients || '',
    completedProjects: aboutPerformanceData.completedProjects || '',
    dedicatedMembers: aboutPerformanceData.dedicatedMembers || '',
    liveProjects: aboutPerformanceData.liveProjects || '',
    sectionImage: '',
  };

  const defaultImages = {
    sectionImage:
      aboutPerformanceData.sectionImage &&
      ROOT_URL + aboutPerformanceData.sectionImage.url,
  };

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Banner Section Content</h5>
            <h5 className="subhead">Edit Content</h5>
          </div>
          <div>
            <Formik
              enableReinitialize
              initialValues={initValues}
              validationSchema={Yup.object({
                heading: Yup.string().required('Required!'),
                description: Yup.string()
                  .max(
                    330,
                    'Description cannot exceed the maximum length of 330 characters'
                  )
                  .required('Required!'),
                happyClients: Yup.number().required('Required!'),
                completedProjects: Yup.number().required('Required!'),
                dedicatedMembers: Yup.number().required('Required!'),
                liveProjects: Yup.number().required('Required!'),
              })}
              onSubmit={(values) => {
                dispatch(savePerformanceSectionData(values));
              }}
            >
              {({ setFieldValue, values, touched, errors }) => (
                <Form className="form form--horizontal text-left">
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Heading
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        name="heading"
                        value={values.heading}
                        touched={touched.heading}
                        error={errors.heading}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Description
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        textArea
                        name="description"
                        value={values.description}
                        touched={touched.description}
                        error={errors.description}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Happy Clients
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        name="happyClients"
                        value={values.happyClients}
                        touched={touched.happyClients}
                        error={errors.happyClients}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Completed Projects
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        name="completedProjects"
                        value={values.completedProjects}
                        touched={touched.completedProjects}
                        error={errors.completedProjects}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Dedicated Members
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        name="dedicatedMembers"
                        value={values.dedicatedMembers}
                        touched={touched.dedicatedMembers}
                        error={errors.dedicatedMembers}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Live Projects
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikField
                        name="liveProjects"
                        value={values.liveProjects}
                        touched={touched.liveProjects}
                        error={errors.liveProjects}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3 text-nowrap required">
                      Section Image
                    </span>
                    <div className="form__form-group-field col-lg-5">
                      <FormikDropZoneField
                        name="sectionImage"
                        value={values.sectionImage}
                        setFieldValue={setFieldValue}
                        defaultImage={defaultImages.sectionImage}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label col-lg-3">
                      <button
                        className="btn btn-primary btn-sm"
                        type="submit"
                        disabled={isLoading}
                      >
                        Save
                      </button>
                    </span>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SectionCard;

import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import ContentTable from './ContentTable';

const FormsContent = () => {
  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between">
            <div className="card__title">
              <h5 className="bold-text">Forms Data</h5>
              <h5 className="subhead">Forms Table</h5>
            </div>
          </div>
          <ContentTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default FormsContent;

import {
  ADD_PRODUCT,
  DELETE_PRODUCT,
  FETCH_PRODUCT,
  GET_PRODUCTS,
} from '../actions/productActions';

const initialState = {
  products: [],
  fetched: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };

    case ADD_PRODUCT:
      return {
        ...state,
        products: [...state.products, action.payload],
      };

    case DELETE_PRODUCT:
      return {
        ...state,
        products: state.products.filter(
          (product) => product._id !== action.payload.id
        ),
      };

    case FETCH_PRODUCT:
      return {
        ...state,
        fetched: action.payload,
      };

    default:
      return state;
  }
}
